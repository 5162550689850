
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const Settings = (props) => {
    let navigate = useNavigate();

  return (

    <React.Fragment>

        <div>
            <div className="MySettingFade modal fade show" id="settingModal" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="mySettingModal modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header py-2">
                            <p className="modal-title fs-5" id="exampleModalLabel">
                                <span className='mr-3'><i className="fa fa-cogs" aria-hidden="true"></i></span>
                                Setting
                            </p>
                            <div>
                                <span>
                                    <button type="button" className="btn btn-light mr-3">
                                    <i className="fa fa-flag mr-2 text-success" aria-hidden="true"></i>Setup guide</button>
                                </span>
                                <a href='/' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { navigate(-1) }}>
                                </a>
                            </div>
                        </div>

                        <div className="settingBodyModal modal-body">
                        
                        <div className='container py-4'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action bg-light" aria-current="true">
                                           <h5 className='fw-normal'>mystore-name</h5>
                                            <a href="#" target='_blank' className='text-secondary text-sm'>mystore-7-3019.myshopify.com</a>
                                        </div>
                                        <NavLink to="/setting/general" className="list-group-item list-group-item-action" aria-current="true">
                                        <i className="nav-icon fas fa-store-alt mr-1" /> Store details
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/plan" className="list-group-item list-group-item-action"><i className="fa fa-cubes mr-2" aria-hidden="true"></i>Plan
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/billing" className="list-group-item list-group-item-action"><i className="fa fa-money mr-2 fs-5" aria-hidden="true"></i>Billing
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/account" className="list-group-item list-group-item-action"><i className="fa fa-user-circle mr-2 fs-5" aria-hidden="true"></i>
                                        Users and permissions
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/payment" className="list-group-item list-group-item-action"><i className="fa fa-credit-card mr-2" aria-hidden="true"></i>Payments
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/checkout" className="list-group-item list-group-item-action"><i className="fa fa-shopping-cart mr-2 fs-5" aria-hidden="true"></i>Checkouts
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/shipping" className="list-group-item list-group-item-action"><i className="fa fa-truck mr-2 fs-5" aria-hidden="true"></i>Shipping and delivery
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to='/setting/taxes' className="list-group-item list-group-item-action"><i className="fa fa-percent mr-2 fs-5" aria-hidden="true"></i>Taxes and duties
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to='/setting/locations' className="list-group-item list-group-item-action"><i className="fa fa-map-marker mr-2 fs-4" aria-hidden="true"></i>Locations
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to='/setting/gift_card' className="list-group-item list-group-item-action"><i className="fa fa-gift mr-2 fs-5" aria-hidden="true"></i>Gift cards
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/markets" className="list-group-item list-group-item-action"><i className="fa fa-handshake-o mr-2" aria-hidden="true"></i>Markets
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <a href="#" className="list-group-item list-group-item-action"><i className="fa fa-list-ul mr-2" aria-hidden="true"></i>Apps and sales channels
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </a>

                                        <NavLink to='/setting/domains' className="list-group-item list-group-item-action"><i className="fa fa-globe mr-2 fs-5" aria-hidden="true"></i>Domains
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/customer_events" className="list-group-item list-group-item-action"><i className="fa fa-birthday-cake mr-2" aria-hidden="true"></i>Customer events
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to='/setting/branding' className="list-group-item list-group-item-action"><i className="fa fa-diamond mr-2" aria-hidden="true"></i>Brand
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to='/setting/notifications' className="list-group-item list-group-item-action"><i className="fa fa-bell mr-2" aria-hidden="true"></i>Notifications
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <NavLink to="/setting/metafield" className="list-group-item list-group-item-action"><i className="fa fa-briefcase mr-2" aria-hidden="true"></i>Metafields
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <a href="#" className="list-group-item list-group-item-action"><i className="fa fa-files-o mr-2" aria-hidden="true"></i>Files
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </a>

                                        <NavLink to='/setting/language' className="list-group-item list-group-item-action"><i className="fa fa-language mr-2" aria-hidden="true"></i>Languages
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </NavLink>

                                        <a href="#" className="list-group-item list-group-item-action"><i className="fa fa-envelope mr-2" aria-hidden="true"></i>Policies
                                        <i className="fa fa-angle-right float-end fs-5"></i>
                                        </a>

                                    </div>
                                </div>
                                <div className="col-md-9">
                                    {props.rightcomponent}
                                </div>
                            </div>
                        </div>
                           
                              


                        </div>
                       
                    </div>
                </div>
            </div>
        </div>

    </React.Fragment>
  )
}

export default Settings