import React from 'react'
import { Link } from 'react-router-dom';

const Invendory = () => {
  return (
    <React.Fragment>
    <div className='content-wrapper topSpace'>
        <div className="container-fluid">
                <div className="container px-4">
                <div className="row fw-normal fs-5 pb-2">
                  Invendory
                </div>
                <div className="row rounded " style={{ background: 'white' }}>
                    <div className="text-center p-md-5">
                        <img alt="customer-logo" className="img-fluid" src="/images/customer.svg" />
                        <p className="fs-5">Keep track of your inventory</p>
                        <p className="">When you enable inventory tracking on your products, you can view and adjust their inventory counts here.</p>

                        <Link to="/products">
                            <button type="button" className="btn btnColorCustome btnColorOnHover m-2">Go to Products</button>
                        </Link>
                    </div>
                </div>
                <p className='text-center py-3'>Learn more about <a href='#'>managing inventory</a></p>
 
            </div>
        </div>
    </div>

</React.Fragment>

    
  )
}

export default Invendory