import React from 'react'

const SettingCheckout = ()=>{
    return (
        <React.Fragment>
            <div className='pl-md-3 mt-3 mt-md-0'>        
                <div>
                    <h5>Checkout</h5>
                </div> 
                <div className='border-top my-4'></div>

                {/* Customer accounts */}
                <div className='card p-3'>
                    <h6>Customer accounts</h6>
                    <div className='border-top mt-2'></div>
                    <div className='pt-3'>

                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Don't use accounts
                            </label>
                            <p className='text-secondary'>Customers will only be able to check out as guests.</p>
                        </div>

                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Accounts are optional
                            </label>
                            <p className='text-secondary'>Customers can create accounts or checkout as guests.</p>
                        </div>

                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Accounts are required
                            </label>
                            <p className='text-secondary'>Customers must create an account when they check out.</p>
                        </div>

                    </div>
                </div>

                {/* Customer contact method */}
                <div className='card p-3'>
                    <h6>Customer contact method <a href="#" className='text-sm pl-1'><i className="fa fa-info-circle px-1"></i>Learn more</a></h6>
                    <div className='border-top mt-2'></div>
                    <div className='pt-3'>
                        <p>Select what contact method customers use to check out.</p>
                        <p>Customers will receive order and shipping notifications configured in <a href="#">notification settings</a>.</p>

                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="notificationOpt" id="PhoneEmailRadio" 
                            data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" />
                            <label className="form-check-label" htmlFor="PhoneEmailRadio">
                             Phone number or email
                            </label>
                        </div>

                        <div className="form-check ml-1 pb-3">
                            <input className="form-check-input shadow-none" type="radio" name="notificationOpt" id="emailRadio" />
                            <label className="form-check-label" htmlFor="emailRadio">
                            Email
                            </label>
                        </div>

                        <div className="collapse" id="collapseExample">
                            <div className="alert alert-primary d-flex align-items-center" role="alert">
                                <div className='mr-3'>
                                    <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                                </div>
                                <div>
                                    To send SMS updates, you need to install an. <a href="#" className='text-dark'>SMS App</a>.
                                </div>   
                            </div>
                        </div>

                    </div>
                    <div className='border-bottom'></div>
                    <div className='pt-3'>
                        <p>Select how customers can choose to get shipping updates.</p>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" name="flexCheckDefault" id="flexCheckDefault1"/>
                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                            Show a link to download the <a href="#">Shop App</a>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Customer information */}
                <div className='card p-3'>
                    <h6>Customer information</h6>
                    <div className='border-top mt-2'></div>

                    <div className='py-3'>
                        <span>Full name</span>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="fullName" id="lastNameRadio"/>
                            <label className="form-check-label" htmlFor="lastNameRadio">
                            Only require last name
                            </label>
                        </div>

                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="fullName" id="F-L-NameRadio" />
                            <label className="form-check-label" htmlFor="F-L-NameRadio">
                            Require first and last name
                            </label>
                        </div>
                    </div>
                    <div className='border-bottom'></div>

                    <div className='py-3'>
                        <span>Company name</span>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="cName" id="DoNotRadio"/>
                            <label className="form-check-label" htmlFor="DoNotRadio">
                            Don't include
                            </label>
                        </div>

                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="cName" id="optionalRadio" />
                            <label className="form-check-label" htmlFor="optionalRadio">
                            Optional
                            </label>
                        </div>

                        <div className="form-check ml-1 pb-2">
                            <input className="form-check-input shadow-none" type="radio" name="cName" id="RequiredRadio" />
                            <label className="form-check-label" htmlFor="RequiredRadio">
                            Required
                            </label>
                        </div>
                    </div>
                    <div className='border-bottom'></div>

                    <div className='py-3'>
                        <span>Address line 2 (apartment, unit, etc.)</span>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="address" id="DoNotAddressRadio"/>
                            <label className="form-check-label" htmlFor="DoNotAddressRadio">
                            Don't include
                            </label>
                        </div>

                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="address" id="optionalAddressRadio" />
                            <label className="form-check-label" htmlFor="optionalAddressRadio">
                            Optional
                            </label>
                        </div>

                        <div className="form-check ml-1 pb-2">
                            <input className="form-check-input shadow-none" type="radio" name="address" id="RequiredAddressRadio" />
                            <label className="form-check-label" htmlFor="RequiredAddressRadio">
                            Required
                            </label>
                        </div>
                    </div>
                    <div className='border-bottom'></div>

                    <div className='py-3'>
                        <span>Shipping address phone number</span>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="shipping" id="DoNotShippingRadio"/>
                            <label className="form-check-label" htmlFor="DoNotShippingRadio">
                            Don't include
                            </label>
                        </div>

                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="shipping" id="optionalShippingRadio" />
                            <label className="form-check-label" htmlFor="optionalShippingRadio">
                            Optional
                            </label>
                        </div>

                        <div className="form-check ml-1 pb-2">
                            <input className="form-check-input shadow-none" type="radio" name="shipping" id="RequiredShippingRadio" />
                            <label className="form-check-label" htmlFor="RequiredShippingRadio">
                            Required
                            </label>
                        </div>
                    </div>
                </div>

                {/* Tipping */}
                <div className='card p-3'>
                    <h6>Tipping <a href="#"><i className="fa fa-info-circle px-1"></i>Learn more</a></h6>
                    <div className='border-bottom mt-2'></div>
                    <div className='pt-3'>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" name="tipping" id="tipping_opt" data-bs-toggle="collapse" data-bs-target="#collapseTipping" aria-expanded="false" aria-controls="collapseTipping"/>
                            <label className="form-check-label" htmlFor="tipping_opt">
                            Show tipping options at checkout
                            </label>
                            <p className='text-secondary text-sm'>Customers can choose between 3 presets or enter a custom amount.</p>
                        </div>
                    </div>

                    <div className='px-md-4 collapse' id="collapseTipping">
                        <div className='card p-3 bg-light'>
                            <div className='row'>
                                <div className='col-md-4 py-1'>
                                    <label htmlFor="" className='fw-normal'>Preset 1 (in %)</label>
                                    <input type="text"  className='form-control'/>
                                </div>
                                <div className='col-md-4 py-1'>
                                    <label htmlFor="" className='fw-normal'>Preset 2 (in %)</label>
                                    <input type="text"  className='form-control'/>
                                </div>
                                <div className='col-md-4 py-1'>
                                    <label htmlFor="" className='fw-normal'>Preset 3 (in %)</label>
                                    <input type="text"  className='form-control'/>
                                </div>
                            </div>

                            <div className="form-check ml-1 py-2">
                                <input className="form-check-input shadow-none" type="checkbox" name="tip" id="hidePreset"/>
                                <label className="form-check-label" htmlFor="hidePreset">
                                 Hide presets until customers choose to add a tip
                                </label>
                            </div>
                        </div>
                    </div>
                    

                </div>

                {/* Order processing Learn more */}
                <div className='card p-3'>
                    <h6 className='py-1'>Order processing <a href="#"><i className="fa fa-info-circle px-1"></i>Learn more</a></h6>

                    <div className='border-bottom pt-2'></div>
                    <div className='pt-3'>
                        <p>While the customer is checking out</p>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" name="sAddress" id="bAddress"/>
                            <label className="form-check-label" htmlFor="bAddress">
                            Use the shipping address as the billing address by default
                            </label>
                            <p className='text-secondary text-sm'>The billing address can still be edited.</p>
                        </div>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" name="A_Address" id="AutoAddress"/>
                            <label className="form-check-label" htmlFor="AutoAddress">
                            Use address autocompletion
                            </label>
                            <p className='text-secondary text-sm'>Offer suggestions when customers enter their address.</p>
                        </div>
                    </div>

                    <div className='border-bottom pt-2'></div>
                    <div className='pt-3'>
                        <p>After an order has been paid</p>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="afterOrder" id="autoFillOrder"/>
                            <label className="form-check-label" htmlFor="autoFillOrder">
                            Automatically fulfill the order's line items
                            </label>
                        </div>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="afterOrder" id="AutoGiftCard"/>
                            <label className="form-check-label" htmlFor="AutoGiftCard">
                            Automatically fulfill only the <b>gift cards</b> of the order
                            </label>
                        </div>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="afterOrder" id="DonNotFillOrder"/>
                            <label className="form-check-label" htmlFor="DonNotFillOrder">
                            Don't fulfill any of the order's line items automatically
                            </label>
                        </div>
                    </div>

                    <div className='border-bottom pt-2'></div>
                    <div className='pt-3'>
                        <p>After an order has been fulfilled and paid, or when all items have been refunded</p>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" name="archiveOrder" id="AutoarchiveOrder"/>
                            <label className="form-check-label" htmlFor="AutoarchiveOrder">
                            Automatically archive the order
                            </label>
                            <p className='text-sm text-secondary'>The order will be removed from your list of open orders.</p>
                        </div>
                    </div>
                </div>

                {/* Marketing options */}
                <div className='card p-3'>
                    <div className='d-flex justify-content-between'>
                         <h6>Marketing options <a href="#"><i className="fa fa-info-circle px-1"></i>Learn more</a></h6>
                         <a href="#" className='text-sm'>Customize sign-up labels</a>
                    </div>
                    <p className='text-secondary pt-2'>Let customers sign up for email or SMS marketing.</p>

                    <div className='border-bottom'></div>
                    <div className='pt-3'>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox"   name="EmailCheck" id="emailMarketing" data-bs-toggle="collapse" data-bs-target="#collapseEmail" aria-expanded="false" aria-controls="collapseEmail"/>
                            <label className="form-check-label" htmlFor="emailMarketing">
                             Email
                            </label>

                            <div className="collapse" id="collapseEmail">
                                <div className="form-check ml-1">
                                    <input className="form-check-input shadow-none" type="checkbox" name="PreSelectedEmail" id="EmailPreselected"/>
                                    <label className="form-check-label" htmlFor="EmailPreselected">
                                    Preselected
                                    </label>
                                    <p className='text-sm text-secondary'>Customers can deselect if they don't want email marketing.</p>
                                </div>
                            </div>
                        </div>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" name="m-sms" id="marketingSMS"/>
                             <label className="form-check-label" htmlFor="marketingSMS">
                            SMS
                            </label>
                        </div>

                    </div>
                </div>

                {/* Order status page */}
                <div className='card p-3'>
                    <h6>Order status page <a href="#"><i className="fa fa-info-circle px-1"></i>Learn more</a></h6>
                    <p className='text-secondary'>Add tracking scripts and other customizations.</p>
                    <div className='border-bottom'></div>
                    <div className="pt-3">
                        <label htmlFor="addScript" className="form-label fw-normal">Additional scripts</label>
                        <textarea className="form-control" id="addScript" rows={4} style={{resize:"none"}} />
                    </div>
                </div>

                {/* Checkout language */}
                <div className='card p-3'>
                   <h6>Checkout language</h6>
                   <div className='border-bottom pt-2'></div>
                   <p className='pt-3'>Your store's checkout page is displayed in <b>English</b></p>
                    <div>
                         <a href='#' className='btn btn-outline-light border text-dark'>Manage checkout language</a>
                    </div>
                </div>
                
            </div>

        </React.Fragment>
    )
}
 export default SettingCheckout