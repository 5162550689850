import React from 'react'

const Collections = () => {
    return (

        <React.Fragment>

            <div className='content-wrapper topSpace'>
              <div className="container-fluid" style={{paddingBottom:"8rem"}}>
                <div className='container'>

                         {/* -- top collection heading -- */}
                    <div className='d-flex justify-content-between pt-2'>
                      <h4>Collection</h4>
                        <button type="button" className="btn btn-sm btnColorCustome btnColorOnHover fw-bold">
                          Create collection
                        </button>
                    </div>

                      <div className='pt-3'>
                        <div className='card'>
                            <div className='p-3'>
                              <a href='#' className='px-2 text-dark'>All</a>
                            </div>
                            <div className='border-bottom'></div>
                            <div className='p-3'>

                              <div className='row'>

                                  {/* --- filter collection search --- */}
                                    <div className='col-md-6'>
                                      <div className='row d-flex justify-content-between py-2'>
                                           <div className='col-8'>
                                              <div className=''>
                                            <div className="input-group w-100">
                                              <span className="input-group-append">
                                                <div className="input-group-text bg-white rounded-start">
                                                  <i className="fa fa-search text-secondary" aria-hidden="true"/>
                                                </div>
                                              </span>
                                              <input className="form-control border-left-0 rounded-end border" type="search" id="pricing" placeholder='Filter collections'/>
                                            </div>      
                                              </div>  
                                           </div>

                                           <div className='col-4'>
                                              <div className="dropdown myDrop">
                                              <button className="btn border dropdown-toggle" type="button"     id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                Availability
                                              </button>
                                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                
                                                <li className=''>
                                                  <div className='dropdown-item'>
                                                    <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                      Available on Online Store
                                                    </label>
                                                  </div>
                                                </li>

                                                <li>
                                                  <div className='dropdown-item'>
                                                    <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                      Unavailable on Online Store
                                                    </label>
                                                  </div>
                                                </li>

                                                <li>
                                                  <div className='dropdown-item'>
                                                    <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                      Available on Point of Sale
                                                    </label>
                                                  </div>
                                                </li>

                                                <li>
                                                  <div className='dropdown-item'>
                                                    <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                      Unavailable on Point of Sale
                                                    </label>
                                                  </div>
                                                </li>

                                                <li className='pl-3 text-secondary'>Clear</li>

                                              </ul>
                                              </div>
                                           </div>

                                      </div>
                                    </div>

                                              {/* types botton */}
                                    <div className='col-md-6'>
                                      <div className='row sm-pt-3'>
                                          <div className='col d-flex justify-content-between py-2'>
                                            
                                          <div className="dropdown myDrop">
                                        <button className="btn border dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                          Type
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                          <li className=''>
                                            <div className='dropdown-item'>
                                              <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                              <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                Automated
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className='dropdown-item'>
                                              <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                              <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                Manual
                                              </label>
                                            </div>
                                          </li>

                                          <li className='pl-3 text-secondary'>Clear</li>
            
                                        </ul>
                                            </div> 

                                            <div className="dropdown">
                                          <button className="btn btn-light border d-flex align-items-center" type="button" aria-expanded="false" disabled>
                                          <i className="fa fa-star-o pr-2" aria-hidden="true"></i>
                                          <span>Saved</span>
                                          </button>
                                            </div>

                                            <div className="dropdown myDrop">
                                        <button className="btn border dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa fa-sort pr-2" aria-hidden="true"></i><span>Sort</span>
                                        </button>

                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                          <li className='pl-3 text-secondary'>Sort by</li>

                                          <li className=''>
                                            <div className='dropdown-item'>
                                              <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                              <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                Collection title A-Z
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className='dropdown-item'>
                                              <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                              <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                Collection title Z-A
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className='dropdown-item'>
                                              <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                              <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                Updated (oldest first)
                                              </label>
                                            </div>
                                          </li>

                                          <li>
                                            <div className='dropdown-item'>
                                              <input className="" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                              <label className="form-check-label pl-2" htmlFor="flexRadioDefault1">
                                                Updated (newest first)
                                              </label>
                                            </div>
                                          </li>

                                        </ul>
                                            </div>

                                          </div>
                                      </div>
                                    
                                    </div>  
                                  
                                </div>
                              </div>

                            <div className='border-top'></div>

                                      {/* other titles */}
                            <div className='d-flex justify-content-between pt-3 px-3'>

                              <div className="form-check">
                                <input className="form-check-input shadow-none" type="checkbox" defaultValue id=""/>
                              </div>

                              <div>
                                <p className='text-sm text-secondary'>Title</p>
                              </div>
                              <div>
                                  <p className='text-sm text-secondary'>Products</p>
                              </div>
                              <div >
                                  <p className='text-sm text-secondary'>Product conditions</p>
                              </div>
                            </div>

                                {/* -- on check botton  option -- */}
                            <div className='onCheckBtn p-3'>
                              <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                <button type="button" className="myBtn btn btn-outline-light">1 Selected</button>
                                <button type="button" className="myBtn btn btn-outline-light">Edit collection</button>
                                <div className="btn-group" role="group">
                                  <button id="btnGroupDrop1" type="button" className="myBtn btn btn-outline-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    More action
                                  </button>
                                  <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                    <li><a className="dropdown-item" href="#">Make collection available</a></li>
                                    <li><a className="dropdown-item" href="#">Make collection unavailable</a></li>
                                    <li><a className="dropdown-item" href="#">Delete collection</a></li>
                                  </ul>
                                </div>
                              </div>

                            </div>

                            <div className='border-bottom py-2'></div>
                            
                              <div className='belowCheckBtn py-2 px-3 rounded-bottom'>

                                  <a href="/CollectionHomePage">
                                    <div className='d-flex justify-content-start align-items-center'>
                                      <div className="form-check">
                                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="" />
                                      </div>
                                      <div className='mx-3 bellowBtnHomePageIcon'>
                                        <i className='fa fa-home fs-4 text-secondary'></i>
                                      </div>
                                      <div>
                                        <span className='text-dark'>Home page</span><br />
                                        <span className='text-sm text-secondary'>collection option
                                        </span>
                                      </div>

                                      <div className='ml-5'>
                                        <p className='px-5 text-dark'>0</p>
                                      </div>
                                    </div>
                                  </a>

                              </div>
                   
                        </div>
                      </div>
                      <p className='text-center py-3'>Learn more about <a href='#'>collections</a></p>

                </div>
              </div>
            </div>
      
        </React.Fragment>

        )
}

export default Collections;