
import React from 'react'

const ShippingDeliveryModal = () => {
  return (
     
      <React.Fragment>

          {/* General Profile -> Manage shop location modal */}
          <div className="modal fade" id="shopLocationModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">Manage Shop location</h5>
                          <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                      </div>
                      <div className="modal-body">
                          <label>REMOVE RATES</label>
                          <div className="form-check ml-1 py-1">
                              <input className="form-check-input shadow-none" type="radio" id="removeRateRadio" />
                              <label className="form-check-label" htmlFor="removeRateRadio">
                                  Remove rates for Shop location
                              </label>
                          </div>
                      </div>

                      <div className="modal-footer">
                          <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                          <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button>
                      </div>
                  </div>
              </div>
          </div>

          {/* General Profile -> Create shipping zone modal */}
          <div className="modal fade" id="shippingZoneModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-scrollabel">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">Create zone</h5>
                          <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                      </div>
                      <div className="modal-body p-0">
                          <div className='px-3 pt-3'>
                              <label className="fw-normal">Zone name</label>
                              <input type="text" className="form-control" />
                              <p className='text-sm text-secondary pt-1'>Customers won't see this.</p>
                          </div>
                          {/* search input */}
                          <div className='border-top p-3'>
                              <div className="input-group">
                                  <span className="input-group-append">
                                      <div className="input-group-text bg-white rounded-start">
                                          <i className="fa fa-search text-secondary" />
                                      </div>
                                  </span>
                                  <input className="form-control border-left-0 rounded-end border" type="search" placeholder='Search countries and regions' />
                              </div>
                          </div>

                          <div className='createZoneCountrySelect border-top'>
                              {/* country list */}
                              <div className="list-group list-group-flush">

                                  <label className="list-group-item list-group-item-action mb-0 fw-normal">
                                      <input className="form-check-input me-1 shadow-none ml-0" type="checkbox" />
                                      <span className='ml-4'>Rest of world</span>
                                  </label>
                                  <label className="list-group-item list-group-item-action mb-0 fw-normal">
                                      <input className="form-check-input me-1 shadow-none ml-0" type="checkbox" />
                                      <span className='ml-4'>Rest of world</span>
                                  </label>
                                  <label className="list-group-item list-group-item-action mb-0 fw-normal">
                                      <input className="form-check-input me-1 shadow-none ml-0" type="checkbox" />
                                      <span className='ml-4'>Rest of world</span>
                                  </label>
                                  <label className="list-group-item list-group-item-action mb-0 fw-normal">
                                      <input className="form-check-input me-1 shadow-none ml-0" type="checkbox" />
                                      <span className='ml-4'>Rest of world</span>
                                  </label>
                                  <label className="list-group-item list-group-item-action mb-0 fw-normal">
                                      <input className="form-check-input me-1 shadow-none ml-0" type="checkbox" />
                                      <span className='ml-4'>Rest of world</span>
                                  </label>
                                  <label className="list-group-item list-group-item-action mb-0 fw-normal">
                                      <input className="form-check-input me-1 shadow-none ml-0" type="checkbox" />
                                      <span className='ml-4'>Rest of world</span>
                                  </label>
                                  <label className="list-group-item list-group-item-action mb-0 fw-normal">
                                      <input className="form-check-input me-1 shadow-none ml-0" type="checkbox" />
                                      <span className='ml-4'>Rest of world</span>
                                  </label>

                              </div>
                          </div>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                          <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button>
                      </div>

                  </div>
              </div>
          </div>

          
          {/* General Profile -> Add rate modal */}
          <div className="modal fade" id="AddRateModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollabel">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">Add rate</h5>
                          <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                      </div>

                      <div className="modal-body p-0">
                          <div className='p-3'>
                              <div className="form-check" type="button" data-bs-toggle="collapse" data-bs-target="#setRateCollapse" aria-expanded="false" aria-controls="setRateCollapse">
                                  <input className="form-check-input shadow-none" type="radio" name="AddRateRadio" id="setOwnRate" />
                                  <label className="form-check-label" htmlFor="setOwnRate">
                                      Set up your own rates
                                  </label>
                              </div>
                              <div className="form-check" data-bs-toggle="collapse" data-bs-target="#calRateCollapse" aria-expanded="false" aria-controls="calRateCollapse">
                                  <input className="form-check-input shadow-none" type="radio" name="AddRateRadio" id="calRate" />
                                  <label className="form-check-label" htmlFor="calRate">
                                      Use carrier or app to calculate rates
                                  </label>
                              </div>
                          </div>

                          <div className="p-3 collapse border-top" id="setRateCollapse">
                              <label className="form-label fw-normal">Rate name</label>
                              <div className="input-group">
                                  <span className="input-group-append">
                                      <div className="input-group-text bg-white rounded-start ">
                                          <i className="fa fa-search text-secondary" aria-hidden="true" />
                                      </div>
                                  </span>
                                  <input className="form-control border-left-0 rounded-end border" type="search" />

                                  <button className="btn-sm btn-outline-light text-dark border rounded ml-2 px-3" type="button">
                                      <span className='d-flex flex-column'>
                                          <i className="fa fa-caret-up"></i>
                                          <i className="fa fa-caret-down"></i>
                                      </span>
                                  </button>
                              </div>
                              <p className='pt-1 text-secondary text-sm'>Customers will see this at checkout.</p>

                              <label htmlFor="cPrice" className="form-label fw-normal">Price</label>
                              <div className="input-group">
                                  <span className="input-group-append">
                                      <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr text-secondary" /></div>
                                  </span>

                                  <input className="form-control border-right-0 border-left-0 border" type="text" id="cPrice" placeholder='0.00' />

                                  <span className="input-group-append">
                                      <div className="input-group-text bg-white">
                                          <span className='spanBg rounded-pill px-2'>free</span>
                                      </div>
                                  </span>
                              </div>

                              {/* add-remove conditions */}
                              <div className='py-2 mt-2' data-bs-toggle="collapse" data-bs-target="#collapseCondition" aria-expanded="false" aria-controls="collapseCondition">
                                  <a href="#">Add conditions</a>
                              </div>

                              <div className='collapse' id="collapseCondition">
                                  <div className="form-check ml-1">
                                      <input className="form-check-input shadow-none" type="radio" name="AddRemRadio" id="itemWeight" />
                                      <label className="form-check-label" htmlFor="itemWeight">
                                          Based on item weight
                                      </label>
                                  </div>
                                  <div className="form-check ml-1">
                                      <input className="form-check-input shadow-none" type="radio" name="AddRemRadio" id="orderPrice" />
                                      <label className="form-check-label" htmlFor="orderPrice">
                                          Based on order price
                                      </label>
                                  </div>

                                  {/* max-min Weight/price input */}
                                  <div className='row'>
                                      <div className='col-md-6 pt-3'>
                                          <label className="form-label fw-normal">Minimum weight</label>
                                          <div className="input-group">
                                              {/* <span className="input-group-append">
                                                    <div className="input-group-text bg-white rounded-start">
                                                        <i className="fa fa-inr text-secondary" />
                                                    </div>
                                                </span> */}

                                              <input className="form-control border-right-0 border" type="text" placeholder='0.00' />

                                              <span className="input-group-append">
                                                  <div className="input-group-text bg-white">
                                                      <span className='text-secondary'>kg</span>
                                                  </div>
                                              </span>
                                          </div>
                                      </div>
                                      <div className='col-md-6 pt-3'>
                                          <label className="form-label fw-normal">Maximum weight</label>
                                          <div className="input-group">
                                              {/* <span className="input-group-append">
                                                    <div className="input-group-text bg-white rounded-start">
                                                        <i className="fa fa-inr text-secondary" />
                                                    </div>
                                                </span> */}

                                              <input className="form-control border-right-0 border" type="text" placeholder='No limit' />

                                              <span className="input-group-append">
                                                  <div className="input-group-text bg-white">
                                                      <span className='text-secondary'>kg</span>
                                                  </div>
                                              </span>
                                          </div>
                                      </div>

                                  </div>

                              </div>
                          </div>

                          <div className='text-center text-secondary p-4 collapse border-top' id="calRateCollapse">
                              There are no carriers or apps availabel for this zone.
                          </div>

                      </div>

                      <div className="modal-footer">
                          <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                          <button type="button" className="btn btnColorCustome btnColorOnHover">Done</button>
                      </div>

                  </div>
              </div>
          </div>

          {/* Create shipping profile -> Add product modal */}
        <div className="modal fade" id="EditProfileItemModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollabel">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit profile items</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                    </div>

                    <div className="editProItemModalBody modal-body p-0">
                         {/* search product input */}
                        <div className='p-3'>
                            <div className="input-group">
                                <span className="input-group-append">
                                    <div className="input-group-text bg-white rounded-start">
                                        <i className="fa fa-search text-secondary" />
                                    </div>
                                </span>
                                <input className="form-control border-left-0 rounded-end border" type="search" placeholder='Search products' />
                            </div>
                            <div className="form-check pt-3 ml-1">
                                <input className="form-check-input shadow-none" type="checkbox" defaultValue id="displayItem" />
                                <label className="form-check-label" htmlFor="displayItem">
                                    Select displayed items
                                </label>
                            </div>
                        </div>
                        <div className='border-top p-3'>
                            <p className='text-secondary text-sm'>Your store doesn't have any products yet.</p>
                        </div>
     
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Done</button>
                    </div>
                </div>
            </div>
        </div>

        {/* Local delivery for Shop location -> Change Currency*/}
        <div className="modal fade" id="ChangeCurrModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Change Currency</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                    </div>

                    <div className="modal-body">
                        <p>Set the currency for your local delivery price at checkout.</p>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Use store default
                            </label>
                            <p className='text-secondary text-sm'>Local delivery price will be displayed in Indian rupees (INR)</p>
                        </div>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Choose from a list
                            </label>
                            <select className="form-select shadow-none mt-1" aria-label="Default select example">
                                <option>Indian rupees (INR)</option>
                                <option>Indian rupees (INR)</option>
                                <option>Indian rupees (INR)</option>
                                <option>Indian rupees (INR)</option>
                                <option>Indian rupees (INR)</option>
                            </select>
                        </div>


                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button>
                    </div>
                </div>
            </div>
        </div>

            {/* Shipping & Delivery --> Add package modal */}
        <div className="modal fade" id="AddPackageModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add package</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                    </div>

                    <div className="modal-body">
                        <label htmlFor="" className='fw-normal'>Package name</label>
                        <input type="text" className='form-control'/>
                        <div className='my-3'>Package type</div>
                        <div className=''>
                            <div className="form-check ml-1">
                                <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="BoxRadio" />
                                <label className="form-check-label" htmlFor="BoxRadio">
                                    Box
                                </label>
                            </div>
                            <div className="form-check ml-1">
                                <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="envelopeRadio" />
                                <label className="form-check-label" htmlFor="envelopeRadio">
                                    Envelope
                                </label>
                            </div>
                            <div className="form-check ml-1">
                                <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="softPackRadio" />
                                <label className="form-check-label" htmlFor="softPackRadio">
                                    Soft package or satchel
                                </label>
                            </div>
                        </div>

                        {/* lenght/width/height row */}
                        <div className='row'>
                            <div className='col-md-4 col-6 mt-2'>
                                <div className='mb-1'>Length</div>
                                <div className="input-group">
                                    <input type="text" className="form-control border" />
                                    <span className="input-group-text d-flex flex-column p-0">
                                        <i className="fa fa-caret-up iconUpDownOnOnHover px-2 mb-1"></i>
                                        <i className="fa fa-caret-down iconUpDownOnOnHover px-2"></i>
                                    </span>
                                </div>
                            </div>
                            <div className='col-md-4 col-6 mt-2'>
                                <div className='mb-1'>Width</div>
                                <div className="input-group">
                                    <input type="text" className="form-control border" />
                                    <span className="input-group-text d-flex flex-column p-0">
                                        <i className="fa fa-caret-up iconUpDownOnOnHover px-2 mb-1"></i>
                                        <i className="fa fa-caret-down iconUpDownOnOnHover px-2"></i>
                                    </span>
                                </div>
                            </div>
                            <div className='col-md-4 col-6 mt-2'>
                                <div className='mb-1'>Height</div>
                                <div className="input-group">
                                    <input type="text" className="form-control border" />
                                    <span className="input-group-text d-flex flex-column p-0">
                                        <i className="fa fa-caret-up iconUpDownOnOnHover px-2 mb-1"></i>
                                        <i className="fa fa-caret-down iconUpDownOnOnHover px-2"></i>
                                    </span>
                                </div>
                            </div>
                            <div className='col-md-4 col-6 mt-2'>
                                <div className='mb-1'>Size</div>
                                <select className="form-select border shadow-none">
                                    <option >cm</option>
                                    <option >in</option>
                                </select>
                            </div>
                            <div className='col-md-4 col-6 mt-2'>
                                <div className='mb-1 text-nowrap'>Weight when empty (optional)</div>
                                <div className="input-group">
                                    <input type="text" className="form-control border" />
                                    <span className="input-group-text d-flex flex-column p-0">
                                        <i className="fa fa-caret-up iconUpDownOnOnHover px-2 mb-1"></i>
                                        <i className="fa fa-caret-down iconUpDownOnOnHover px-2"></i>
                                    </span>
                                </div>
                            </div>
                          
                            <div className='col-md-4 col-6 mt-2 pt-1'>
                                <select className="form-select border shadow-none mt-4">
                                    <option >g</option>
                                    <option >kg</option>
                                    <option >oz</option>
                                    <option >lb</option>
                                </select>
                            </div>
                        </div>
                        <div className='border-top mt-4'></div>
                        <div className="form-check ml-1 pt-3">
                            <input className="form-check-input shadow-none" type="checkbox" id="packCheck" />
                            <label className="form-check-label text-sm text-secondary" htmlFor="packCheck">
                                Use this package to calculate rates at checkout
                            </label>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Add package</button>
                    </div>
                </div>
            </div>
        </div>

          {/* Custom order fulfillment --> modal*/}
        <div className="modal fade" id="AddfulfilmentModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add custom fulfillment service</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className='modal-body'>
                         <div className='row'>
                            <div className='col-md-6'>
                                <label htmlFor="" className='form-label fw-normal'>Name</label>
                                <input type="text"  className='form-control'/>
                            </div>
                            <div className='col-md-6'>
                                <label htmlFor="" className='form-label fw-normal'>Email</label>
                                <input type="email"  className='form-control'/>
                            </div>
                         </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button>
                    </div>
                </div>
            </div>
        </div>

      </React.Fragment>
  )
}

export default ShippingDeliveryModal
