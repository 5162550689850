
import React from "react"
import { Link} from "react-router-dom"
import ShippingDeliveryModal from "./SettingCompModal/ShippingDeliveryModal";


function SettingShipping (){
    return (
        <React.Fragment>
            <ShippingDeliveryModal />
            <div className='pl-md-3 mt-3 mt-md-0'>  
                <div>
                    <h5>Shipping and delivery</h5>
                </div> 
              
                <div className='border-top my-4'></div> 
                        {/* Shipping */}
                    <h6 className="pb-3">Shipping</h6>
                <div className="card p-3">
                    <h6>Shipping <a href="#"><i className="fa fa-info-circle px-1"></i>Learn more</a></h6>
                    <p className="text-secondary text-sm">Choose where you ship and how much you charge for shipping at checkout.</p>
                    <div className='border-top'></div>

                           {/* General shipping rates */}
                    <h6 className="py-2 mt-2">General shipping rates</h6>
                    <div className="card p-3 bg-light">
                        <div className="row">
                            <div className="col-7">
                                <div className="row">
                                    <div className="col-md-6 mb-2 mb-md-0">
                                        <div>General</div>
                                        <span className="text-secondary">0 products</span>
                                    </div>
                                    <div className="col-md-6 mb-2 mb-md-0">
                                        <span>Rates for</span>
                                         <div className="text-secondary">
                                            <span><i className="fa fa-globe"></i> Domestic</span><br />
                                            <span><i className="fa fa-globe"></i> Rest of World</span>
                                         </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5 text-right">
                                <Link to="/setting/shipping/general_profile">Manage</Link>
                            </div>
                        </div>
                    </div>
                           {/* Custom shipping rates */}
                    <h6 className="py-2">Custom shipping rates</h6>
                    <div className="cShippingRate text-center rounded p-3 text-sm">
                        <div className="text-secondary">Add custom rates or destination restrictions for groups of products.</div>
                        <Link to='/setting/shipping/create_profile'>Create new profile</Link>
                    </div>
                </div>

                    {/* Processing time */}
                <div className="card p-3">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <h6>Processing time</h6>
                            <div>
                            <span className="spanBg rounded-pill pb-1 px-2 ml-2 text-sm">Inactive</span>
                            </div>
                        </div>
                        <div>
                            <Link to='/setting/shipping/processing_time'>Manage</Link>
                        </div>
                    </div>
                    <p className="pt-2 text-sm">Show delivery dates to your customers by setting a processing time, if you process orders in 2 business days or less. This will be added to the transit time of your shipping rates. Learn more about <a href="#">processing time</a>.</p>
                    <div className="d-flex">
                        <div className="pr-2 mt-1">
                            <i className="fa fa-info-circle text-secondary fs-5"></i>
                        </div>
                        <div>
                         <p className="text-secondary">You currently don't have any shipping rates with transit time. Once you've set up transit times, you can then use processing time to show delivery dates to your customers. <span><a href="#">Learn more</a>.</span></p> 
                        </div>
                    </div>
                </div>

                        {/* Local Delivery */}
                <h6 className="py-2">Local Delivery</h6>

                <div className="card">
                    <div className="p-3">
                        <h6>Local delivery</h6>
                        <p className="text-sm">Deliver orders directly to local customers. Learn more about <a href="#">local delivery</a>.
                        </p>
                        <h6>Manage delivery by location</h6>
                    </div>
                    <div className='border-top'></div>
                        <Link to='/setting/shipping/local_delivery' className="localShopLocation px-3 py-2 d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div>
                                    <i className="fa fa-map-marker fs-4 border rounded bg-white px-3 py-2"></i>
                                </div>
                                <div className="px-2"> 
                                    <span className="text-dark">Shop location</span><br />
                                    <span className="text-secondary">india</span>
                                </div>
                            </div>
                            <div>
                            <span className="spanBg text-dark text-sm rounded-pill px-2 py-1 text-nowrap">Doesn't offer delivery</span>
                            </div>
                        </Link>
                </div>

                       {/* Local Pickup */}
                <h6 className="py-2">Local Pickup</h6>
                <div className="card">
                    <div className="px-3 pt-3">
                        <h6>Local Pickup <a href="#"><i className="fa fa-info-circle px-1"></i>Learn more</a></h6>
                        <p className="text-sm">Let customers pick up their orders at your locations.
                        </p>
                    </div>
                    <div className='border-top'></div>
                        <Link to='/setting/shipping/local_pickup' className="localShopLocation px-3 py-2 d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div>
                                    <i className="fa fa-map-marker fs-4 border rounded bg-white px-3 py-2"></i>
                                </div>
                                <div className="px-2"> 
                                    <span className="text-dark">Shop location</span><br />
                                    <span className="text-secondary">india</span>
                                </div>
                            </div>
                            <div>
                            <span className="spanBg text-dark text-sm rounded-pill px-2 py-1 text-nowrap">Doesn't offer pickup</span>
                            </div>
                        </Link>
                </div>
                
                      {/* Packages */}
                <div className="py-2">
                    <h6>Packages</h6>
                    <p className="text-sm">The <a href="#">package sizes</a> you use to ship your products.</p>
                </div>

                <div className="card p-3">
                    <div className="d-flex justify-content-between">
                       <h6>Saved packages</h6>
                       <a href="#" data-bs-toggle="modal" data-bs-target="#AddPackageModal">Add package</a>
                    </div>
                    <div className="text-center rounded p-4 mt-3 bg-light">
                        <p className="lh-1">Add a package to show accurate shipping rates at checkout</p>
                        <p className="text-secondary lh-1">Until you add a package, Shopify will use a sample box to calculate shipping rates at checkout. <span><a href="#">Learn more</a></span></p>
                        <div>
                            <a href="#" className="btn btnColorCustome btnColorOnHover" data-bs-toggle="modal" data-bs-target="#AddPackageModal">Add package</a>
                        </div>
                    </div>
                </div>

                      {/* Packing slips */}
                <div className="py-2">
                    <h6>Packing slips</h6>
                    <p className="text-secondary text-sm">A printed list of items in a shipment that often goes inside a package.</p>
                </div>

                <div className="card p-3">
                    <div className="d-flex justify-content-between">
                        <h6>Packing slip template</h6>
                        <Link to='/setting/shipping/packing_slip_template' >Edit</Link>
                    </div>
                    <p>Customize the packing slips you can print when you're fulfilling orders.</p>
                </div>

                      {/* Carrier accounts */}
                <div className="py-2">
                    <h6>Carrier accounts</h6>
                </div>
                <div className="card p-3">
                    <h6>Enable third-party calculated rates at checkout</h6>
                    <div className="d-flex">
                        <p>Your current plan doesn't offer this feature. Upgrade your plan to enable calculated shipping rates using accounts from FedEx, UPS® and apps.</p>
                        <div className="d-none d-md-block">
                            <img src="/images/carrier.svg" alt="carrier-logo" style={{width:"6rem", height:"4rem"}} />
                        </div>
                    </div>
                    <div>
                        <a href='/plans' className="btn btnColorCustome btnColorOnHover">Upgrade your plan</a>
                        <a href="#" className="pl-3">Learn more</a>
                    </div>
                </div>

                      {/* Custom order fulfillment */}
                <div className="py-2">
                    <h6>Custom order fulfillment</h6>
                </div>
                <div className="card p-3">
                    <h6>Custom order fulfillment</h6>
                        <p>Add an email for a custom fulfillment service that fulfills orders for you.</p>
                    <div>
                        <button type="button" className="btn btnColorCustome btnColorOnHover" data-bs-toggle="modal" data-bs-target="#AddfulfilmentModal">Add fulfillment service</button><a href="#" className="pl-3">Learn more</a>
                    </div>
                </div>

                      {/* about shipping -> learn */}
                <div className="mt-4 text-center">
                    <p>Learn more about <a href="#">shipping</a>.</p>
                </div>
            </div>

           
        </React.Fragment>
    );
}

export default SettingShipping