
import React from 'react'
import { Link } from 'react-router-dom';

const SettingAccount= ()=>{
    return(
        <React.Fragment>
            <div className='pl-md-3 mt-3 mt-md-0'>
                <h5>Users and permissions</h5>
                <div className='border-top my-4'></div>

                <div>
                    <h6>Permissions</h6>
                    <p className='text-sm text-secondary'>Manage what users can see or do in your store.</p>
                </div>

                    {/* store owner */}
                <div className='card'>
                    <div className='p-4 d-flex justify-content-between'>
                        <h6>Store owner</h6>
                        <a href="#" className='text-sm' data-bs-toggle="modal" data-bs-target="#TransferOwnModal">Transfer ownership</a>
                    </div>
                    <div className='d-flex align-items-center px-4'>
                        <div>
                            <span className='rounded-circle bg-light p-3 mr-3'>UK</span>
                        </div>
                        <div className='text-break'>
                            <a href='#'>Upendra Kumar</a><br/>
                            <span className='text-secondary text-sm'>Last login was Tuesday, December 6, 2022 10:44 AM GMT+5:30</span>
                        </div>
                    </div>

                    <div className='border-top mt-4'></div>
                    <div className='px-4 pt-3 rounded-bottom bg-light'>
                        <p className='text-sm'>Store owners have some permissions that can't be assigned to staff. Learn more about <a href="#">store owner permissions</a>.</p>
                    </div>
                </div>

                    {/* Transfer ownership modal */}
                <div className="modal fade" id="TransferOwnModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Transfer store ownership</h5>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-primary d-flex align-items-center"  role="alert">
                                <div className='mr-3'>
                                    <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                                </div>
                                <div className='text-sm'>
                                    When the transfer is complete, you will lose your unrestricted access to the store.
                                </div>
                            </div>
                            <div>
                                <p className='text-secondary'>The new store owner will be able to:</p>
                                <ul className='storeOwnerList text-secondary text-sm'>
                                    <li>Remove you from the store or change your permissions</li>
                                    <li>View or edit credit card and banking information</li>
                                    <li>Pause or close the store</li>
                                </ul>
                                <p className='text-sm'>NEW STORE OWNER</p>
                            </div>

                            <div>
                                <label htmlFor="" className='fw-normal'>Email</label>
                                <input type="email" className='form-control' />
                            </div>
                            <div className='row'>
                                <div className='col-md-6 pt-3'>
                                    <label htmlFor="" className='fw-normal'>First name</label>
                                    <input type="text" className='form-control' />
                                </div>
                                <div className='col-md-6 pt-3'>
                                    <label htmlFor="" className='fw-normal'>Last name</label>
                                    <input type="text" className='form-control' />
                                </div>
                            </div>
                            <div className='pt-3'>
                                <label htmlFor="" className='fw-normal'>Enter your password to confirm:</label>
                                <input type="password" className='form-control' />
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btnColorCustome btnColorOnHover">Transfer store ownership</button>
                        </div>
                        </div>
                    </div>
                </div>

                    {/* add new staff */}
                <div className='card p-3'>
                    <h6>Staff (0 of 2)</h6>
                    <p className='text-sm text-secondary pt-2'>Customize what your staff members can edit and access. You can add up to 2 staff members on this plan. <a href="#">Compare plans</a>.</p>
                    <div>
                        <Link to="/setting/account/new" className='btn btnColorCustome btnColorOnHover'>Add staff</Link>
                    </div>
                </div>

                    {/* --- Collaborators --- */}
                <div className='card p-3'>
                    <h6>Collaborators</h6>
                    <p className='text-sm text-secondary pt-2'>These give designers, developers, and marketers access to your Shopify admin. They don't count toward your staff limit. Learn more about <a href="#">collaborators</a>.</p>
                    <div className='border-top'></div>

                        
                    <div className='pt-3'>
                        <div className="form-check">
                            <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            <label className="form-check-label text-sm" htmlFor="flexRadioDefault1">
                                Anyone can send a collaborator request
                            </label>
                        </div>
                        <div className="form-check" >
                            <input className="form-check-input shadow-none" data-bs-toggle="collapse" data-bs-target="#collaboratorCollapse" aria-expanded="true" aria-controls="collaboratorCollapse" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                            <label className="form-check-label text-sm" htmlFor="flexRadioDefault2">      
                                Only people with a collaborator request code can send a collaborator request
                            </label>
                        </div>
                    </div>

                   {/* Collaborator Request Code --> collapse */}
                    <div className="collapse" id="collaboratorCollapse">
                        <div className='border-top mt-3'></div>
                        <div className="pt-3">
                            <div className='d-flex justify-content-between'>
                            <label htmlFor=""className="form-label fw-normal">Collaborator Request Code</label>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#generateCodeModal">Generate new code</a>
                            </div>
                            <div className="input-group">
                                <input className="form-control rounded"  type="text" id=""  />
                                <button className="btn btn-outline-light border rounded ml-2" type="button"><i className="fa fa-clipboard" aria-hidden="true"></i></button>
                            </div>
                            <p className='text-sm text-secondary pt-1'>Share this code to allow someone to send you a collaborator request. You'll still need to review and approve their request.</p>
                        </div> 
                    </div>
                </div>

                    {/* Generate new code - modal */}
                <div className="modal fade" id="generateCodeModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Generate new collaborator request code</h5>
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <p className='text-secondary text-sm'>
                                    After generating a new code, the previous code will no longer be valid. New collaborator requests must use the new code.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btnColorCustome btnColorOnHover">Generate code</button>
                            </div>
                        </div>
                    </div>
                </div>

                    {/* --- Login services --- */}
                <div className='pt-3'>
                    <h6>Login services</h6>
                    <p className='text-sm text-secondary'>Allow staff to use external services to log in to Ecom builder.
                    </p>
                </div>

                    {/* --- Login services -> in collapse*/}
                <div className='card p-3'>
                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-3'>
                            <p>Name</p>
                        </div>
                        <div className='col-5'>
                            <p>Status</p>
                        </div>
                    </div>
                    <div className='border-top'></div>

                    <div className='row pt-3 pb-2'>
                        <div className='col-2'>
                           <img src="/images/google.png" alt="googleLogo" style={{width:"2.5rem", height:"1rem"}}/>
                        </div>
                        <div className='col-3'>
                            <p className='text-secondary text-sm'>Google Apps</p>
                        </div>
                        <div className='col-5'>
                            <p className='text-secondary text-sm'>Disabled: Staff can't use Google Apps to log in</p>
                        </div>
                        <div className='col-2 text-right'>
                            <a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Edit</a>
                        </div>
                
                    </div>
                    
                    <div className="collapse" id="collapseExample">
                        <div className="card-body border bg-light">
                            <div className="form-check ml-1">
                                <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Enable Google Apps for login
                                </label>
                            </div>
                            <div className='border-top my-3'></div>

                            <label htmlFor="" className='fw-normal'>What is your Google Apps domain ?</label>
                            <input type="text" className='form-control' placeholder='example.com' />
                            <div className='border-top my-3'></div>
                            <div className='text-right'>
                                <button className='btn btnColorCustome btnColorOnHover' disabled>save</button>
                            </div>
                        </div>
                    </div>
                    <div className='border-top'></div>

                </div>

            </div>
        </React.Fragment>
    );
}
export default SettingAccount