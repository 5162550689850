
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Preferences = () => {
    let navigate = useNavigate()

  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            {/* for going back btn */}
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Preferences</span>
            </div>
            <div className='border-top my-4'></div>

               {/* Geolocation app not installed */}
            <div className='card p-3'>
               <h5>Geolocation app not installed</h5>
               <p className='text-sm text-secondary'>Install the Ecom-builder Geolocation app to prompt visitors to select their country for a localized shopping experience when domain/subfolder redirection isn't available. <a href="#">Go to app</a></p>
                <div className='border-top'>
                    <div className="form-check ml-1 pt-3">
                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Domain/subfolder redirection
                        </label>
                        <p className='text-sm text-secondary'>If customers land on the wrong domain, subdomain, or subfolder, they'll be automatically redirected to one that supports their country/region.</p>
                        <p className='text-sm text-secondary'>European Union (EU) customers won't be redirected within the EU due to legal restrictions. <a href="#">Learn more</a></p>
                    </div>
                </div>

            </div>

            <div className='card p-3'>
                <div className="form-check ml-1">
                    <input className="form-check-input shadow-none" type="checkbox" id="incExcCountry" />
                    <label className="form-check-label" htmlFor="incExcCountry">
                    Include or exclude tax based on your customer's country
                    </label>
                    <p className='text-sm text-secondary'>Your customer's local tax rate will be used for calculations. <a href="#">Learn more</a></p>
                </div>

                <div className='border-top pt-3'>
                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" type="checkbox" id="hideComAtPrice" />
                        <label className="form-check-label" htmlFor="hideComAtPrice">
                            Hide compare-at prices for European Economic Area (EEA) customers
                        </label>
                        <p className='text-sm text-secondary'>The EEA requires additional pricing disclosures when advertising discounted prices. Compare-at prices set by CSV will still be shown. <a href="#">Learn more</a></p>
                    </div>
                </div>

                <div className='border-top pt-3'>
                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" type="checkbox" id="priceRounding" />
                        <label className="form-check-label" htmlFor="priceRounding">
                            Price rounding
                        </label>
                        <p className='text-sm text-secondary'>All prices will round up after conversion. <a href="#">Learn more</a></p>
                    </div>
                </div>
            </div>
            
                 {/* Market pro */}
            <div className='card p-3'>
                 <div className='d-flex justify-content-between'>
                    <h5>Market pro</h5>
                    <div className='text-right'>
                        <button className='text-nowrap btn btn-sm btnColorCustome btnColorOnHover'>Go to application</button>
                    </div>
                 </div>
                <div className='mt-3 border-top pt-2'>
                    <span className='fw-bold'>Go global without the guesswork</span><br />
                    <span className='text-secondary text-sm'>Remove complexity and help grow international sales—all from a single store admin.</span>
                </div>

                <div className='mt-4'>
                    <div className='card p-3 bg-light'>
                        <div className='d-flex justify-content-between align-items-start'>
                           <div className='pr-2'>
                                <h6>Save big on international shipping</h6>
                                <span className='text-secondary'>Tap into cost-effective express rates worldwide.</span>
                            </div>
                            <img src="/images/international-shipping.svg" alt="shipping-logo" style={{width:'3rem'}} />
                        </div>
                    </div>

                    <div className='card p-3 bg-light'>
                        <div className='d-flex justify-content-between align-items-start'>
                           <div className='pr-2'>
                                <h6>Offer local payment options to global customers</h6>
                                <span className='text-secondary'>Markets Pro supports more payment options and handles local currencies for you.</span>
                            </div>
                            <img src="/images/international-shipping.svg" alt="shipping-logo" style={{width:'3rem'}} />
                        </div>
                    </div>

                    <div className='card p-3 bg-light'>
                        <div className='d-flex justify-content-between align-items-start'>
                           <div className='pr-2'>
                                <h6>Turn international traffic into sales</h6>
                                <span className='text-secondary'>Increase international conversion with Markets Pro.</span>
                            </div>
                            <img src="/images/international-shipping.svg" alt="shipping-logo" style={{width:'3rem'}} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </React.Fragment>
  )
}

export default Preferences