
import React from 'react'
import { Link } from 'react-router-dom'
import MarketModal from './SettingCompModal/MarketModal'


const SettingMarket = () => {
  return (
    <React.Fragment>
        <MarketModal/>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <div className='d-flex justify-content-between'>
                <h5>Markets</h5>
                <Link to='/setting/markets/add_new_market' className='btn-sm btnColorCustome btnColorOnHover'>Add market</Link>
            </div>
            <div className='border-top my-3'></div>

                {/* Become a true global business - alert */}
            <div className="alert alert-info alert-dismissible fade show" role="alert">
                <button type="button" className="btn-close shadow-none" data-bs-dismiss="alert" aria-label="Close"/>
                <h6>Become a true global business</h6>
                <ul className='marketAlertUL' >
                    <li>Choose the countries and regions you want to sell to</li>
                    <li>Customize features like language, currency, and domains</li>
                    <li>Use recommendations to grow your business worldwide</li>
                </ul>
                <button className='btn btn-sm btn-outline-light bg-light mr-2' data-bs-toggle="modal" data-bs-target="#exampleModal">add a recommended market</button>
                <div className='mt-2'>
                    <a href="#" className='text-sm'>Learn about Markets</a>
                </div>
            </div>

            <div className='card p-3'>
                <select className="form-select shadow-none" aria-label="Default select example">
                    <option>Today</option>
                    <option>Last 7 days</option>
                    <option>Last 30 days</option>
                </select>
            </div>

            <div className='row'>
                <div className='col-md-8'>
                        {/* Primary market */}
                    <div className='card p-3'>
                        <h6>Primary market</h6>
                        <div className='d-flex justify-content-between mt-3'>
                            <div className=''>
                                <img src="/images/tricolor.svg" alt="india-logo" className='rounded' style={{width:"3rem"}}/>
                                <span className='ml-2 text-bold'>India</span>
                            </div>
                            <Link to='/setting/markets/primary_market_IN'>Manage</Link>
                        </div>

                        <div className='border-top my-3'></div>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <span>0.0%</span>
                                <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                                <span className='text-secondary text-sm'>0%</span>
                                <p className='text-sm text-secondary'>Share of total sales</p>
                            </div>
                            <div className='col-sm-4'>
                                <span>₹ 0.0%</span>
                                <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                                <span className='text-secondary text-sm'>0%</span>
                                <p className='text-sm text-secondary'>Sales</p>
                            </div>
                            <div className='col-sm-4'>
                                <span>0.0%</span>
                                <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                                <span className='text-secondary text-sm'>0%</span>
                                <p className='text-sm text-secondary'>Conversion rate</p>
                            </div>
                        </div>
                    </div>

                        {/* Other markets */}   
                    <div className='card p-3'>
                        <div className='d-flex justify-content-between'>
                            <h6>Other markets</h6>
                               <Link to='/setting/markets/preferences' className='px-2'>Preferences</Link>
                            <a href="#">Manage</a>
                        </div>
                        <div className='border-top my-2'></div>
                        <div className='d-flex'>
                            <div className='mr-2 mt-2'>
                                <img  className='rounded' src="/images/dummy-img.png"  alt="dummyImage" style={{width:"3rem"}}/>
                            </div>
                            <div className=''>
                                <span className='text-bold mr-2'>International</span>
                                <span className='text-sm spanBg rounded-pill px-2'>Inactive</span>
                                <p className='text-secondary text-sm'>Start selling in 28 recommended countries/regions.</p>
                            </div>
                        </div>
                        
                        <div className='border-top pt-3'></div>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <span>0.0%</span>
                                <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                                <span className='text-secondary text-sm'>0%</span>
                                <p className='text-sm text-secondary'>Share of total sales</p>
                            </div>
                            <div className='col-sm-4'>
                                <span>₹ 0.0%</span>
                                <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                                <span className='text-secondary text-sm'>0%</span>
                                <p className='text-sm text-secondary'>Sales</p>
                            </div>
                            <div className='col-sm-4'>
                                <span>0.0%</span>
                                <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                                <span className='text-secondary text-sm'>0%</span>
                                <p className='text-sm text-secondary'>Conversion rate</p>
                            </div>
                        </div>
                    </div>

                        {/* Countries/regions  */}  
                    <div className='card p-3'>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <div className='mt-2'>
                                    <img  className='rounded' src="/images/dummy-img.png"  alt="dummyImage" style={{width:"3rem"}}/>
                                </div>
                                <div className='px-2'>
                                    <span className='text-bold'>Countries/regions you don't sell to</span>
                                    <p className='text-secondary text-sm'>Customers in these places can't check out.</p>
                                </div>
                            </div>
                            <Link to='/setting/markets/not_selling_country'>View</Link>
                        </div>
                    </div>

                </div>
                       
                    {/* Recommendations */}
                <div className='col-md-4'>
                    <div className='card p-3'>
                        <h6>Recommendations</h6>
                        <div className='border-top mt-3'></div>
                        <div className='mt-3'>
                            <h6 className='text-sm'>Start collecting duties and import taxes</h6>
                            <p className='text-sm text-secondary'>Help customers avoid unexpected fees by collecting duties and import taxes at checkout.</p>
                            <Link to="/setting/taxes">Go to Duties and import taxes</Link>
                            <div className='border-top my-3'></div>
                            <h6 className='text-sm'>Add a market for United States</h6>
                            <p className='text-sm text-secondary'>United States makes up 52% of international sales volume to stores in India.</p>
                            <a href="#">Add United States</a>
                        </div>
                    </div>
                </div>

            </div>
                <p className='mt-4 text-center'>Learn more about <a href="#">markets</a></p>
        </div>
    </React.Fragment>
  )
}

export default SettingMarket