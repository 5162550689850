
import React from 'react'
import { useNavigate } from 'react-router-dom'

function ManagePrimaryMarket() {
    let navigate = useNavigate()
  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            {/* for going back btn */}
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }}/>
                <span className='ml-2 fw-normal fs-5'>India</span>
            </div>
            <div className='border-top my-3'></div>
            <div className='row'>
              <div className='col-md-8'>

                    {/* Market settings */}
                <div className='card p-3'>
                    <h5>Market settings</h5>
                    <p className='text-sm text-secondary'>Manage how customers experience your store for this market only.</p>
                    <div className='border-top d-flex justify-content-between py-3'>
                      <div className='text-sm'>
                          <h6 className='lh-1'>Domains and languages</h6>
                          <span className='text-sm text-secondary'>mystore-16-5968.ecom-builder.com • English</span>
                      </div>
                        <a href='#'>Menage</a>
                    </div>
                    <div className='border-top d-flex justify-content-between py-3'>
                      <div className='text-sm'>
                          <h6 className='lh-1'>Products and pricing</h6>
                          <span className='text-sm text-secondary'>Indian Rupee (INR ₹)</span>
                      </div>
                        <a href='#'>View</a>
                    </div>
                    <div className='border-top d-flex justify-content-between py-3'>
                      <div className='text-sm'>
                          <h6 className='lh-1'>Duties and import taxes</h6>
                          <span className='text-sm text-secondary'>Not collecting duties and import taxes</span>
                      </div>
                        <a href='#'>Manage</a>
                    </div>
                    <div className='border-top d-flex justify-content-between pt-3'>
                      <div className='text-sm'>
                          <h6 className='lh-1'>Shipping</h6>
                          <span className='text-secondary'>1 rate • Shipping to India</span>
                      </div>
                        <a href='#'>Manage</a>
                    </div>
                </div>

                    {/* Store settings */}
                <div className='card p-3'>
                    <h5>Store settings</h5>
                    <p className='text-sm text-secondary'>Manage international settings that affect your entire store.</p>
                    <div className='border-top d-flex justify-content-between py-3'>
                      <div className='text-sm'>
                          <h6 className='lh-1'>Payments</h6>
                          <span className='text-sm text-secondary'>Manage the payment methods you accept worldwide.</span>
                      </div>
                        <a href='#' className='text-nowrap'>Go to Payments</a>
                    </div>
                    <div className='border-top d-flex justify-content-between pt-3'>
                      <div className='text-sm'>
                          <h6 className='lh-1'>Taxes</h6>
                          <span className='text-sm text-secondary'>Collecting taxes</span>
                      </div>
                        <a href='#'>Go to Taxes</a>
                    </div>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='card p-3'>
                  <h5>Performance</h5>
                  <div className='mt-2'>
                    <select className="form-select shadow-none border">
                      <option>Today</option>
                      <option>Last 7 days</option>
                      <option>Last 30 days</option>
                    </select>
                  </div>
                  
                    <div className='border-top mt-3 pt-2'>
                      <span className='text-sm text-secondary'>Share of total sales</span><br />
                      <span>0.0%</span>
                      <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                      <span className='text-secondary text-sm'>0%</span>
                      <div className="progress mt-2">
                        <div className="progress-bar progress-bar-animated  progress-bar-striped bg-success" role="progressbar" style={{width: '10%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>

                    <div className='border-top mt-3 pt-2'>
                      <span className='text-sm text-secondary'>Sales</span><br />
                      <span>₹0.00%</span>
                      <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                      <span className='text-secondary text-sm'>0%</span>
                      <div className="progress mt-2">
                        <div className="progress-bar progress-bar-animated  progress-bar-striped bg-success" role="progressbar" style={{width: '10%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>

                    <div className='border-top mt-3 pt-2'>
                      <span className='text-sm text-secondary'>Conversion rate</span><br />
                      <span>0.0%</span>
                      <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                      <span className='text-secondary text-sm'>0%</span>
                      <div className="progress mt-2">
                        <div className="progress-bar progress-bar-animated  progress-bar-striped bg-success" role="progressbar" style={{width: '10%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>

                    <div className='border-top mt-3 pt-2'>
                      <span className='text-sm text-secondary'>Average order value</span><br />
                      <span>₹0.00%</span>
                      <span className='mx-1 text-secondary text-sm'><i className="fa fa-caret-down"></i></span>
                      <span className='text-secondary text-sm'>0%</span>
                      <div className="progress mt-2">
                        <div className="progress-bar progress-bar-animated  progress-bar-striped bg-success" role="progressbar" style={{width: '10%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>

                </div>
              </div>
              
            </div>
        </div>
    </React.Fragment>
  )
}

export default ManagePrimaryMarket