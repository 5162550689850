import React from 'react'
import {Routes, Route } from 'react-router-dom'
import DashbordHeader from './common/header/DashbordHeader'
import DashbordSideBarMenu from './common/sideBarMenu/DashbordSideBarMenu'
import DashbordPage from './pages/DashbordPage'
import Customer from './pages/Customer'
import { AddCustomer } from './components/AddCustomer'
import Products from './pages/Products'
import AddProduct from './components/AddProduct'
import Inventory from './pages/subProductCom/Inventory'
import Transfers from './pages/subProductCom/Transfers'
import Collections from './pages/subProductCom/Collections'
import GiftCard from './pages/subProductCom/GiftCard'
import OrderPage from './pages/OrderPage'
import Plan from './pages/Plan'
import PlanInfo from './pages/PlanInfo'
import Analytics from './pages/Analytics'
import Floatingbanner from './components/Floatingbanner/Floatingbanner'
import Disscount from './pages/Disscount'
import Typeofdisc from './components/Typeofdisc'
import Reports from './pages/reports/Reports'
import Reportsby from './pages/reports/Reportsby'
import Marketing from './pages/marketing/Marketing'
import Themes from './pages/onlinestore/Themes'
import CreateTransfer from './components/CreateTransfer'
import Userlogin from './pages/userForm/Userlogin'
import Storelogin from './pages/Storelogin/Storelogin'
import CollectionHomePage from './pages/subProductCom/CollectionHomePage'
import AddGiftCard from './components/AddGiftCard'
import IssueGiftCard from './components/IssueGiftCard'
import PointOfSale from './pages/pontOfSale/PointOfSale'
import Settings from './pages/settings/Settings'
import SettingStoreDetails from './pages/settings/SettingStoreDetails'
import SettingPlan from './pages/settings/SettingPlan'
import SettingBillings from './pages/settings/SettingBillings'
import SettingAccount from './pages/settings/SettingAccount'
import Subscription from './pages/settings/Billing_others/Subscription'
import Upcoming from './pages/settings/Billing_others/Upcoming'
import StatementOfCharges from './pages/settings/Billing_others/StatementOfCharges'
import AddStaff from './pages/settings/Account_others/AddStaff'
import SettingPayments from './pages/settings/SettingPayments'
import AddPaymentMethods from './pages/settings/Payment_others/AddPaymentMethods'
import PayPalCheckout from './pages/settings/Payment_others/PayPalCheckout'
import ThirdPartyProvider from './pages/settings/Payment_others/ThirdPartyProvider'
import PaymentMethodOption from './pages/settings/Payment_others/PaymentMethods/PaymentMethodOpt'
import Checkout from './pages/settings/SettingCheckout'
import Shipping from './pages/settings/SetttingShipping'
import GeneralProfile from './pages/settings/Shipping_others/GeneralProfile'
import CreateShippingProfile from './pages/settings/Shipping_others/CreateShippingProfile'
import ProcessingTime  from './pages/settings/Shipping_others/ProcessingTime'
import LocalDelivery from './pages/settings/Shipping_others/LocalDelivery'
import LocalPickup from './pages/settings/Shipping_others/LocalPickup'
import PackingSlipTemplate from './pages/settings/Shipping_others/PackingSlipTemplate'
import SettingTaxes from './pages/settings/SettingTaxes'
import CountryTaxes from './pages/settings/Taxes_others/CountryTaxes'
import RestOfWorldTaxes from './pages/settings/Taxes_others/RestOfWorldTaxes'
import Locations from './pages/settings/SettingLocations'
import ShopLocation from './pages/settings/Locations_others/ShopLocation'
import AddLocation from './pages/settings/Locations_others/AddLocation'
import SettingGiftCard from './pages/settings/SettingGiftCard'
import Market from './pages/settings/SettingMarket'
import AddMarket from './pages/settings/Market_others/AddMarket'
import ManagePrimary from './pages/settings/Market_others/ManagePrimaryMarket'
import Preferences from './pages/settings/Market_others/Preferences'
import NotSellingCountry from './pages/settings/Market_others/NotSellingCountry'
import SettingDomains from './pages/settings/SettingDomains'
import BuyNewDomain from './pages/settings/Domain_others/BuyNewDomain'
import ConnectExistingDomain from './pages/settings/Domain_others/ConnectExistingDomain'
import TransferDomain from './pages/settings/Domain_others/TransferDomain'
import SettingCustomerEvent from './pages/settings/SettingCustomerEvent'
import SettingBrand from './pages/settings/SettingBrand'
import SettingNotification from './pages/settings/SettingNotification'
import EmailSetting from './pages/settings/EmailSettings/EmailSetting'
import SettingMetafield from './pages/settings/SettingMetafield'
import MetafieldProduct from './pages/settings/Metafield_others/MetafieldProduct'
import AddProductDefinition from './pages/settings/Metafield_others/AddMetafield/AddProductDefinition'
import MetafieldVariant from './pages/settings/Metafield_others/MetafieldVariant'
import AddVariantDefinition from './pages/settings/Metafield_others/AddMetafield/AddVariantDefinition'
import MetafieldCollection from './pages/settings/Metafield_others/MetafieldCollection'
import AddCollectionDefinition from './pages/settings/Metafield_others/AddMetafield/AddCollectionDefinition'
import MetafieldCustomer from './pages/settings/Metafield_others/MetafieldCustomer'
import AddCustomerDefinition from './pages/settings/Metafield_others/AddMetafield/AddCustomerDefinition'
import MetafieldOrder from './pages/settings/Metafield_others/MetafieldOrder'
import AddOrderDefinition from './pages/settings/Metafield_others/AddMetafield/AddOrderDefinition'
import MetafieldPages from './pages/settings/Metafield_others/MetafieldPages'
import AddPagesDefinition from './pages/settings/Metafield_others/AddMetafield/AddPagesDefinition'
import MetafieldBlogs from './pages/settings/Metafield_others/MetafieldBlogs'
import AddBlogsDefinition from './pages/settings/Metafield_others/AddMetafield/AddBlogsDefinition'
import MetafieldBlogPost from './pages/settings/Metafield_others/MetafieldBlogPost'
import AddBlogPostDefinition from './pages/settings/Metafield_others/AddMetafield/AddBlogPostDefinition'
import SettingLanguage from './pages/settings/SettingLanguage'


const App = () => {

  const storeIs= localStorage.getItem('storeIs');
  const token = localStorage.getItem('accessToken');
  console.log(storeIs);
  if (!token) {
    return <Userlogin />
  }
  else if( storeIs === "false"){
    return(
      <Storelogin/>
    )

  } else {

    return (

      <React.Fragment>
        
        <div className='wrapper'>
                <DashbordHeader/>
                <DashbordSideBarMenu />
              
            <Routes> 
              <Route path='/' element={<DashbordPage />} />
              <Route path='/customer' element={<Customer />} />
              <Route path='/customer/add-customer' element={<AddCustomer />} />
              <Route path='/products' element={<Products />} />
              <Route path='/products/add-products' element={<AddProduct />} />
              <Route path='/products/Inventory' element={<Inventory />} />
              <Route path='/products/Transfers' element={<Transfers />} />
              <Route path='/products/CreateTransfer' element={<CreateTransfer />} />
              <Route path='/products/Collections' element={<Collections />} />
              <Route path='/products/GiftCard' element={<GiftCard />} />
              <Route path='/products/AddGiftCard' element={<AddGiftCard />} />
              <Route path='/products/IssueGiftCard' element={<IssueGiftCard />} />
              <Route path='/orderpage' element={<OrderPage />} />
              <Route path='/plans' element={<Plan />} />
              <Route path='/plan-info' element={<PlanInfo />} />
              <Route path='/Analytics' element={<Analytics />} />
              <Route path='/disscounts' element={<Disscount />} />
              <Route path='/typeof/:id' element={<Typeofdisc />} />
              <Route path='/reports' element={<Reports />} />
              <Route path='/reportsby/:id' element={<Reportsby />} />
              <Route path='/marketing' element={<Marketing />} />
              <Route path='/themes' element={<Themes />} />
              <Route path='/CollectionHomePage' element={<CollectionHomePage />} />
              <Route path='/pointofsale' element={<PointOfSale />} />
              <Route path='/setting' element={ <Settings rightcomponent={<SettingStoreDetails/>}/>} />
              <Route path='/setting/general' element={ <Settings rightcomponent={<SettingStoreDetails/>}/>} />
              <Route path='/setting/plan' element={<Settings rightcomponent={<SettingPlan/>}/> } /> 
              <Route path='/setting/billing' element={<Settings rightcomponent={<SettingBillings/>}/> } /> 
              <Route path='/setting/billing/subscription' element={<Settings rightcomponent={<Subscription/>}/> } /> 
              <Route path='/setting/billing/upcoming' element={<Settings rightcomponent={<Upcoming/>}/> } /> 
              <Route path='/setting/billing/statement_of_charges' element={<Settings rightcomponent={<StatementOfCharges/>}/> } /> 
              <Route path='/setting/account' element={<Settings rightcomponent={<SettingAccount/>}/> } /> 
              <Route path='/setting/account/new' element={<Settings rightcomponent={<AddStaff/>}/> } /> 
              <Route path='/setting/payment' element={<Settings rightcomponent={<SettingPayments/>}/> } /> 
              <Route path='/setting/payment/add_payment_methods' element={<Settings rightcomponent={<AddPaymentMethods/>}/> } /> 
              <Route path='/setting/payment/paypal_express_checkout' element={<Settings rightcomponent={<PayPalCheckout/>}/> } /> 
              <Route path='/setting/payment/Third_party_provider' element={<Settings rightcomponent={<ThirdPartyProvider/>}/> } /> 
              <Route path='/setting/payment/Third_party_provider/payment_method' element={<Settings rightcomponent={<PaymentMethodOption/>}/> } /> 
              <Route path='/setting/checkout' element={<Settings rightcomponent={<Checkout/>}/> } /> 
              <Route path='/setting/shipping' element={<Settings rightcomponent={<Shipping/>}/> } /> 
              <Route path='/setting/shipping/general_profile' element={<Settings rightcomponent={<GeneralProfile/>}/> } /> 
              <Route path='/setting/shipping/create_profile' element={<Settings rightcomponent={<CreateShippingProfile/>}/> } /> 
              <Route path='/setting/shipping/processing_time' element={<Settings rightcomponent={<ProcessingTime/>}/> } /> 
              <Route path='/setting/shipping/local_delivery' element={<Settings rightcomponent={<LocalDelivery/>}/> } /> 
              <Route path='/setting/shipping/local_pickup' element={<Settings rightcomponent={<LocalPickup/>}/> } /> 
              <Route path='/setting/shipping/packing_slip_template' element={<Settings rightcomponent={<PackingSlipTemplate/>}/> } /> 
              <Route path='/setting/taxes' element={<Settings rightcomponent={<SettingTaxes/>}/> } /> 
              <Route path='/setting/taxes/IN' element={<Settings rightcomponent={<CountryTaxes/>}/> } /> 
              <Route path='/setting/taxes/rest_of_world' element={<Settings rightcomponent={<RestOfWorldTaxes/>}/> } /> 
              <Route path='/setting/locations' element={<Settings rightcomponent={<Locations/>}/> } /> 
              <Route path='/setting/locations/shop-location' element={<Settings rightcomponent={<ShopLocation/>}/> } /> 
              <Route path='/setting/locations/add-new-location' element={<Settings rightcomponent={<AddLocation/>}/> } /> 
              <Route path='/setting/gift_card' element={<Settings rightcomponent={<SettingGiftCard/>}/> } /> 
              <Route path='/setting/markets' element={<Settings rightcomponent={<Market/>}/> } /> 
              <Route path='/setting/markets/add_new_market' element={<Settings rightcomponent={<AddMarket/>}/> } /> 
              <Route path='/setting/markets/primary_market_IN' element={<Settings rightcomponent={<ManagePrimary/>}/> } /> 
              <Route path='/setting/markets/preferences' element={<Settings rightcomponent={<Preferences/>}/> } /> 
              <Route path='/setting/markets/not_selling_country' element={<Settings rightcomponent={<NotSellingCountry/>}/> } /> 
              <Route path='/setting/domains' element={<Settings rightcomponent={<SettingDomains/>}/> } /> 
              <Route path='/setting/domains/buy_domain' element={<Settings rightcomponent={<BuyNewDomain/>}/> } /> 
              <Route path='/setting/domains/connect_domain' element={<Settings rightcomponent={<ConnectExistingDomain/>}/> } /> 
              <Route path='/setting/domains/transfer_domain' element={<Settings rightcomponent={<TransferDomain/>}/> } /> 
              <Route path='/setting/customer_events' element={<Settings rightcomponent={<SettingCustomerEvent/>}/> } /> 
              <Route path='/setting/branding' element={<Settings rightcomponent={<SettingBrand/>}/> } /> 
              <Route path='/setting/notifications' element={<Settings rightcomponent={<SettingNotification/>}/> } /> 
              <Route path='/setting/email_setting' element={<Settings rightcomponent={<EmailSetting/>}/> } /> 
              <Route path='/setting/metafield' element={<Settings rightcomponent={<SettingMetafield/>}/> } /> 
              <Route path='/setting/metafield/product' element={<Settings rightcomponent={<MetafieldProduct/>}/> } /> 
              <Route path='/setting/metafield/product/create_definition' element={<Settings rightcomponent={<AddProductDefinition/>}/> } /> 
              <Route path='/setting/metafield/variant' element={<Settings rightcomponent={<MetafieldVariant/>}/> } /> 
              <Route path='/setting/metafield/variant/create_definition' element={<Settings rightcomponent={<AddVariantDefinition/>}/> } /> 
              <Route path='/setting/metafield/collection' element={<Settings rightcomponent={<MetafieldCollection/>}/> } /> 
              <Route path='/setting/metafield/collection/create_definition' element={<Settings rightcomponent={<AddCollectionDefinition/>}/> } /> 
              <Route path='/setting/metafield/customer' element={<Settings rightcomponent={<MetafieldCustomer/>}/> } /> 
              <Route path='/setting/metafield/customer/create_definition' element={<Settings rightcomponent={<AddCustomerDefinition/>}/> } /> 
              <Route path='/setting/metafield/order' element={<Settings rightcomponent={<MetafieldOrder/>}/> } /> 
              <Route path='/setting/metafield/order/create_definition' element={<Settings rightcomponent={<AddOrderDefinition/>}/> } /> 
              <Route path='/setting/metafield/page' element={<Settings rightcomponent={<MetafieldPages/>}/> } /> 
              <Route path='/setting/metafield/page/create_definition' element={<Settings rightcomponent={<AddPagesDefinition/>}/> } /> 
              <Route path='/setting/metafield/blog' element={<Settings rightcomponent={<MetafieldBlogs/>}/> } /> 
              <Route path='/setting/metafield/blog/create_definition' element={<Settings rightcomponent={<AddBlogsDefinition/>}/> } /> 
              <Route path='/setting/metafield/blog_post' element={<Settings rightcomponent={<MetafieldBlogPost/>}/> } /> 
              <Route path='/setting/metafield/blog_post/create_definition' element={<Settings rightcomponent={<AddBlogPostDefinition/>}/> } /> 
              <Route path='/setting/language' element={<Settings rightcomponent={<SettingLanguage/>}/> } /> 

            </Routes>

            <Floatingbanner />
        
          {/* <DashbordFooter /> */}
        </div>
      </React.Fragment>
    )
  }

}

export default App