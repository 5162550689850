import React, { Component } from 'react';
import { convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';


const content = { "entityMap": {}, "blocks": [{ "key": "637gr", "text": "Initialized from content state.", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] };

class EditorConvertToJSON extends Component {
    constructor(props) {
        super(props);
        const contentState = convertFromRaw(content);
        this.state = {
            contentState,
        }
    }

    onContentStateChange = (contentState) => {
        this.setState({
            contentState,
        });
    };

    render() {
        const { contentState } = this.state;
        return (
            <div>
                <Editor style={{ width: "1000px" }}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    placeholder="Enter your text here:"
                    toolbarClassName="toolbarClassName"
                    onContentStateChange={this.onContentStateChange}

                // toolbar={{
                //     inline: { inDropdown: true },
                //     list: { inDropdown: true },
                //     textAlign: { inDropdown: true },
                //     link: { inDropdown: true },
                //     history: { inDropdown: true },
                // }}

                />
                {/* <textarea
                    disabled
                    value={JSON.stringify(contentState, null, 4)}
                /> */}
            </div>
        );
    }
}

export default EditorConvertToJSON;