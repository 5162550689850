import React from 'react';
import { Line } from "react-chartjs-2";
import {
    Chart,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
} from 'chart.js';
import { useState } from 'react';

Chart.register(

    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Tooltip,

);



export default function Analytics() {

    const [fullorhalfscreenvalue, setfullorhalfscreenvalue] = useState("Enter Fullscreen");

    function fullorhalfscreen() {
        if (fullorhalfscreenvalue == "Enter Fullscreen") {
            setfullorhalfscreenvalue("Exit Fullscreen")
        }
        else {
            setfullorhalfscreenvalue("Enter Fullscreen")
        }

    }

    const data = {
        labels: ["jan", "fab", "march", "Apr", "may", "june"],
        datasets: [{
            label: 'My First Dataset1',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.2,

        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }


    const data2 = {
        labels: ["jan", "fab", "march", "Apr", "may", "june"],
        datasets: [{
            label: 'My First Dataset',
            data: [50, 59, 80, 45, 56, 10],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.2
        }]
    };

    const data3 = {
        labels: ["jan", "fab", "march", "Apr", "may", "june"],
        datasets: [{
            label: 'My First Dataset',
            data: [50, 59, 80, 45, 35, 10],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.2,

        }]
    };
    return (
        <div>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className="row">

                            <div className="col-md-10 mt-3">
                                <p className='fw-bolder fs-4'>Analytics <span className='fw-normal fs-6'>Last refreshed: 12:43 PM</span></p>

                            </div>
                            <div className="col-md-2 mt-3">
                                <button type="button" class="btn btn-light border" data-widget="pushmenu" role="button" onClick={fullorhalfscreen}> <i class="fa fa-arrows-alt" aria-hidden="true"></i> {fullorhalfscreenvalue}</button>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-10 mt-3">
                                <button type="button" class="btn btn-light border"> <i class="fa fa-calendar" aria-hidden="true"></i> Today</button> compared to Oct 16, 2022

                            </div>
                            <div className="col-md-2 mt-3 ">
                                <label for="autorefresh">
                                    <input type="checkbox" id="autorefresh" /> <span className='fw-normal'>Auto-refresh <i class="fa fa-question-circle" aria-hidden="true"></i></span>
                                </label>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-4 col-12">
                                <div className='card p-3'>
                                    <p className='mb-0'>Total Sales <span className='float-end'><a href='/reportsby/Sales over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>₹ 70</p>
                                    <p style={{ fontSize: "12px" }} className="fw-bolder">SALES OVER TIME</p>
                                    <Line data={data} options={options} >

                                    </Line>
                                    <div>
                                        <span className='float-end m-1' style={{ fontSize: "12px" }}> Nov 2, 2022</span><span className='float-end m-1' style={{ fontSize: "12px" }}> Nov 2, 2022</span>
                                    </div>

                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Conversion rate <span className='float-end'><a href='/reportsby/Online store conversion over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>0%</p>
                                    <p style={{ fontSize: "12px" }} className="fw-bolder">CONVERSION FUNNEL</p>
                                    <p className='mb-0'>Conversion rate <span className='float-end'>0% -</span></p>
                                    <p>0 sessions</p>
                                    <p className='mb-0'>Reached checkout <span className='float-end'>0% -</span></p>
                                    <p>0 sessions</p>

                                    <p className='mb-0'>Sessions converted <span className='float-end'>0% -</span></p>
                                    <p>0 sessions</p>
                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Top products by units sold <span className='float-end'><a href='/reportsby/Sales by product'>View Report</a></span></p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sales in this date range.</p>

                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Sessions by traffic source <span className='float-end'><a href='/reportsby/Sessions by referrer'>View Report</a></span></p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sessions in this date range.</p>

                                </div>

                                <div className='card p-3'>
                                    <p className='mb-0'>Sales by social source <span className='float-end'><a href='/reportsby/Sales by traffic referrer'>View Report</a></span></p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sales in this date range.</p>

                                </div>

                                <div className='card p-3'>
                                    <p className='mb-0'>Top referrers by sessions </p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sessions in this date range.</p>

                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className='card p-3'>
                                    <p className='mb-0'>Sessions <span className='float-end'><a href='/reportsby/Sessions over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>0</p>
                                    <p style={{ fontSize: "12px" }} className="fw-bolder">SESSIONS OVER TIME</p>
                                    <p>Visitors</p>
                                    <Line data={data2} option={options} >

                                    </Line>
                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Average order value <span className='float-end'><a href='/reportsby/Average order value over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>₹ 70</p>
                                    <p style={{ fontSize: "12px" }} className="fw-bolder">ORDER VALUE OVER TIME</p>
                                    <Line data={data} options={options} >

                                    </Line>
                                    <div>
                                        <span className='float-end m-1' style={{ fontSize: "12px" }}> Nov 2, 2022</span><span className='float-end m-1' style={{ fontSize: "12px" }}> Nov 2, 2022</span>
                                    </div>

                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Sessions by location <span className='float-end'><a href='/reportsby/Sessions by location'>View Report</a></span></p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sessions in this date range.</p>

                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Sales by traffic source <span className='float-end'><a href='/reportsby/Sales by traffic referrer'>View Report</a></span></p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sales in this date range.</p>

                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Top landing pages by sessions <span className='float-end'><a href='/reportsby/Sessions by landing page'>View Report</a></span></p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sessions in this date range.</p>

                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className='card p-3'>
                                    <p className='mb-0'>Returning customer rate <span className='float-end'><a href='#'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>0%</p>
                                    <p style={{ fontSize: "12px" }} className="fw-bolder">CUSTOMERS OVER TIME</p>
                                    <Line data={data3} option={options} >

                                    </Line>
                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Total orders <span className='float-end'><a href='/reportsby/Sales over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>0</p>
                                    <p style={{ fontSize: "12px" }} className="fw-bolder">ORDERS OVER TIME</p>
                                    <Line data={data} options={options} >

                                    </Line>
                                    <div>
                                        <span className='float-end m-1' style={{ fontSize: "12px" }}> Nov 2, 2022</span><span className='float-end m-1' style={{ fontSize: "12px" }}> Nov 2, 2022</span>
                                    </div>

                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Sessions by device type <span className='float-end'><a href='/reportsby/Sessions by device'>View Report</a></span></p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sessions in this date range.</p>

                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Sessions by social source <span className='float-end'><a href='/reportsby/Back
Sessions by referrer'>View Report</a></span></p>
                                    <p className='text-center p-4' style={{ fontSize: "13px" }}>There were no sessions in this date range.</p>

                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Sales attributed to marketing <span className='float-end'><a href='/reportsby/Sales attributed to marketing'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>₹ 70</p>
                                    <p style={{ fontSize: "12px" }} className="fw-bolder">TOTAL SALES OVER TIME</p>
                                    <Line data={data} options={options} >

                                    </Line>
                                    <div>
                                        <span className='float-end m-1' style={{ fontSize: "12px" }}> Nov 2, 2022</span><span className='float-end m-1' style={{ fontSize: "12px" }}> Nov 2, 2022</span>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>

        </div >
    )
}
