
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotSellingCountry = () => {

    let navigate = useNavigate()

  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            {/* for going back btn */}
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Countries/regions you don't sell to</span>
            </div>
            <div className='border-top my-4'></div>
            <div className='card p-3'>
                <p className='text-sm text-secondary'>Customers in these countries or regions can't check out. To start selling to these countries or regions, add them to an existing market or create a new market. <a href="#">Learn more.</a></p>

                <div className="input-group">
                    <span className="input-group-append">
                        <div className="input-group-text bg-white rounded-start "><i className="fa fa-search text-secondary" aria-hidden="true" />
                        </div>
                    </span>
                    <input className="form-control border-left-0 rounded-end border pl-0" type="search" placeholder='Search countries and regions' />
                </div>

                    {/* --- country list --- */}
                <div className='border-top mt-3 pt-3'>
                    <img src="/images/tricolor.svg" className='rounded' alt="india-log" style={{width:'3rem'}}/>
                    <span className='ml-2'>India</span>
                </div>
                <div className='border-top mt-3 pt-3'>
                    <img src="/images/tricolor.svg" className='rounded' alt="india-log" style={{width:'3rem'}}/>
                    <span className='ml-2'>India</span>
                </div>
                <div className='border-top mt-3 pt-3'>
                    <img src="/images/tricolor.svg" className='rounded' alt="india-log" style={{width:'3rem'}}/>
                    <span className='ml-2'>India</span>
                </div>
            </div>                                                                                          
        </div>      
    </React.Fragment>
  )
}

export default NotSellingCountry