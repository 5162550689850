import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom'

function Typeofdisc() {
    const typeofclick = useParams();
    const [manageparmsdisor, setdicplayparamsconsdor] = useState("block")
    const [displayxy, setxydisplay] = useState("block")
    const [displayxym, setxydisplaym] = useState("block")
    const [displayasperperorf, setdisplayasperperor] = useState("block");
    const [dispalyforship, setdispalyforship] = useState("block");

    // manage for params

    const displayforxandy = {
        display: displayxy
    }
    const displayforxandymi = {
        dispaly: displayxym
    }
    const manageforparams = {

        display: manageparmsdisor
    }
    const displayasperperor = {
        display: displayasperperorf
    }

    const forparcondship = {
        display: dispalyforship
    }
    useEffect(() => {
        if (typeofclick.id == "order") {
            setdicplayparamsconsdor("none")
            setdisplayasperperor("none")
        }

        if (typeofclick.id == "buy X get Y") {
            setxydisplay("none");
            setxydisplaym("none");
        }

        if (typeofclick.id == "shipping") {
            console.log("come through shipping")
            setdispalyforship("none");
            setxydisplay("block");
            setdisplayasperperor("none");
            setxydisplay("none");
        }
    })

    //for method
    const [displaymanualdisc, setdisplaymanualdisc] = useState("block");
    const [displayautodisc, setdisplayautodisc] = useState("none");

    const myRadioStyleForDisplayManual = {
        display: displaymanualdisc
    }
    const myRadioStyleForDisplayAuto = {
        display: displayautodisc
    }

    //for purchase req

    const [displaypuramount, setdisppuramou] = useState("none");
    const [displayminiquan, setdispminiquan] = useState("none");

    const dispinputforpuramou = {
        display: displaypuramount
    }

    const dispinputfor = {
        display: displayminiquan
    }

    //maximum disc uses
    const [limitnotime, setlimitnotime] = useState("none");

    const stylelimitnotime = {
        display: limitnotime
    }

    // for value
    const [percen, setpercedis] = useState("block");
    const [peramount, setamoundis] = useState("none");
    const [fixedclick, setfixedclickdisplay] = useState("none");
    const [searchcollectiondisplay, setdisplaycollection] = useState("none");
    const [searchproductsdisplay, setdisplayproducts] = useState("none");

    const searchcollectionstyle = {
        display: searchcollectiondisplay
    }

    const searchproductstyle = {
        display: searchproductsdisplay
    }

    const forpercen = {
        display: percen
    }

    const foramount = {
        display: peramount
    }
    const fixedclickdisplay = {
        display: fixedclick
    }


    // for cstmr eligibility

    const [searchcstmrsegdisplay, setcstmrsegdisplay] = useState("none");
    const [searchcstmrdisplay, setcstmrdisplay] = useState("none");

    const specificcstmrsegstyle = {
        display: searchcstmrsegdisplay
    }

    const specificcstmrstyle = {
        display: searchcstmrdisplay
    }

    // for x and y
    const [froxayinpudis, setfroxayinpudis] = useState("none");
    const [fordisdis, setfordisdis] = useState("block");
    const [fordisamtf, setfordiamf] = useState("none");


    const displayperinpu = {
        display: froxayinpudis
    }


    const fordisf = {
        display: fordisdis
    }

    const foramf = {
        display: fordisamtf
    }

    function fordisff() {
        setfordisdis("block");
        setfordiamf("none");
    }

    function foramff() {
        setfordisdis("none");
        setfordiamf("block");
    }

    function froxayinpudisfn() {

        setfroxayinpudis("block");
    }
    function frefn() {
        setfroxayinpudis("none");
    }

    function handleinputdisplay() {
        setdisplaymanualdisc("block")
        setdisplayautodisc("none")
    }

    function handleinputdisplayauto() {
        console.log("run")
        setdisplayautodisc("block")
        setdisplaymanualdisc("none")
    }

    function nominimum() {
        setdisppuramou("none")
        setdispminiquan("none")
    }

    function minipuram() {
        setdisppuramou("block")
        setdispminiquan("none")
    }

    function miniquan() {
        setdisppuramou("none")
        setdispminiquan("block")
    }

    function maxusesdis() {
        if (stylelimitnotime.display === "none") {
            setlimitnotime("block")
        }
        else {
            setlimitnotime("none")
        }
    }

    function forperc() {
        setpercedis("block");
        setamoundis("none");
        setfixedclickdisplay("none");
    }

    function foramo() {
        setpercedis("none");
        setamoundis("block");
        setfixedclickdisplay("block");
    }

    function specificcollection() {
        setdisplaycollection("block");
        setdisplayproducts("none");
    }

    function specificproducts() {
        setdisplayproducts("block");
        setdisplaycollection("none");
    }

    //csmt eligblty
    function allcstmr() {
        setcstmrsegdisplay("none");
        setcstmrdisplay("none");
    }
    function specificcsmrseg() {
        setcstmrsegdisplay("block");
        setcstmrdisplay("none");

    }

    function specicsmt() {
        setcstmrsegdisplay("none");
        setcstmrdisplay("block");
    }

    return (
        <div>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container '>
                        <div className="row">
                            <div className='mt-3'>
                                <img className='img-fluid' src="/images/previous.png" />
                                <span className='fw-bold ml-3'>Create {typeofclick.id}  discount</span><br />
                            </div>


                        </div>
                        <div className='row justify-content-center py-2'>
                            <div className='col-md-11'>
                                <div className='row'>
                                    <div className='py-2 col-md-8'>
                                        <div className='card p-3'>
                                            <p className='fw-bolder'>Amount off {typeofclick.id}<span className='float-end fw-normal'>{typeofclick.id} discount</span></p>


                                            <hr />
                                            <div className="">
                                                <div style={forparcondship}>
                                                    <label htmlFor="tname" className="form-label fw-normal">Method</label>
                                                    <div class="form-check" onClick={handleinputdisplay}>
                                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                            Discount code
                                                        </label>
                                                    </div>
                                                    <div class="form-check" onClick={handleinputdisplayauto}>
                                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                        <label class="form-check-label" for="flexRadioDefault2">
                                                            Automatic discount
                                                        </label>
                                                    </div>
                                                </div>
                                                <div style={myRadioStyleForDisplayManual}>
                                                    <label htmlFor="tname" className="form-label fw-normal mt-4">Discount code</label>
                                                    <div class="input-group mb-3">
                                                        <input type="text" class="form-control" />
                                                        <button class="btn border rounded ml-3" type="button" id="button-addon2" ><b>Generate</b></button>
                                                    </div>
                                                    <span style={{ fontSize: "13px" }}>Customers must enter this code at checkout.</span>
                                                </div>
                                                <div style={myRadioStyleForDisplayAuto} >
                                                    <label htmlFor="tname" className="form-label fw-normal mt-4">Title</label>
                                                    <div class="input-group mb-3">
                                                        <input type="text" class="form-control" />
                                                    </div>
                                                    <span style={{ fontSize: "13px" }}>Customers will see this in their cart and at checkout.</span>
                                                </div>
                                            </div>


                                        </div>

                                        <div style={displayforxandy} >
                                            <div className='card p-3 mb-0 '>

                                                <div className="">
                                                    <p className='fw-bolder'>Value</p>
                                                    <div class="input-group mb-3">
                                                        <button class="btn btn-outline-secondary" type="button" id="button-addon1" onClick={forperc}>Percentage</button>
                                                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={foramo}>Fixed Amount</button>
                                                        <input type="text" class="form-control ml-4" style={forpercen} ></input> <span class="input-group-text" id="basic-addon1" style={forpercen}>%</span>

                                                        <span class="input-group-text ml-4" id="basic-addon1" style={foramount}>Rs</span><input type="text" class="form-control" style={foramount} ></input>
                                                    </div>
                                                </div>
                                                <div className="" style={manageforparams}>
                                                    <p className='fw-bolder'>APPLIES TO</p>
                                                    <div class="form-check" onClick={specificcollection} >
                                                        <input class="form-check-input" type="radio" name="valueappliesto" id="specificcolle" />
                                                        <label class="form-check-label" for="specificcolle">

                                                            Specific collections
                                                        </label>
                                                    </div>

                                                    <div class="form-check" onClick={specificproducts} >
                                                        <input class="form-check-input" type="radio" name="valueappliesto" id="specificpro" />
                                                        <label class="form-check-label" for="specificpro">
                                                            Specific products
                                                        </label>
                                                    </div>
                                                    <div style={searchcollectionstyle}>
                                                        <div class="input-group my-3 " >
                                                            <span class="input-group-text bg-white border-end-0" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span><input type="text" class="form-control border-start-0" placeholder='Search Collection' />
                                                            <button class="btn border rounded ml-3" type="button" id="button-addon2" ><b>Browse</b></button>
                                                        </div>
                                                    </div>
                                                    <div style={searchproductstyle}>
                                                        <div class="input-group my-3 " >
                                                            <span class="input-group-text bg-white border-end-0" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span><input type="text" class="form-control border-start-0" placeholder='Search Products' />
                                                            <button class="btn border rounded ml-3" type="button" id="button-addon2" ><b>Browse</b></button>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                            <div style={manageforparams}>
                                                <div className='card p-3 rounded-0' style={fixedclickdisplay}>

                                                    <div className="">

                                                        <div class="form-check" >
                                                            <input class="form-check-input" type="checkbox" name="cstmreleg" id="onlyapplydisc" />
                                                            <label class="form-check-label" for="onlyapplydisc">

                                                                Only apply discount once per order

                                                            </label>
                                                            <p style={{ fontSize: "13px" }}>If not selected, the amount will be taken off each eligible item in an order.</p>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div style={displayforxandymi}>
                                            <div className='card p-3  mt-4'>

                                                <div className="">
                                                    <p className='fw-bolder'>Minimum purchase requirements</p>
                                                    <div class="form-check" onClick={nominimum} >
                                                        <input class="form-check-input" type="radio" name="minipurreq" id="nominireq" />
                                                        <label class="form-check-label" for="nominireq">

                                                            No minimum requirements
                                                        </label>
                                                    </div>
                                                    <div class="form-check" onClick={minipuram}>
                                                        <input class="form-check-input" type="radio" name="minipurreq" id="minipuramou" />
                                                        <label class="form-check-label" for="minipuramou">

                                                            Minimum purchase amount (₹)
                                                        </label>
                                                        <div style={dispinputforpuramou} >

                                                            <input type="text" class="form-control w-25" />

                                                            <p>Applies only to selected collections.</p>
                                                        </div>
                                                    </div>
                                                    <div class="form-check" onClick={miniquan}>
                                                        <input class="form-check-input" type="radio" name="minipurreq" id="miniquantiitem" />
                                                        <label class="form-check-label" for="miniquantiitem">

                                                            Minimum quantity of items

                                                        </label>
                                                        <div style={dispinputfor}>

                                                            <input type="text" class="form-control w-25" />

                                                            <p>Applies only to selected collections.</p>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        {/*buy x and y*/}
                                        <div style={displayasperperor}>
                                            <div className='card p-3 mb-0'>

                                                <div className="">
                                                    <p className='fw-bolder'>Customer buys</p>
                                                    <div class="form-check" onClick={fordisff} >
                                                        <input class="form-check-input" type="radio" name="csmtrbu" id="miniqntitem" />
                                                        <label class="form-check-label" for="miniqntitem">
                                                            Minimum quantity of items
                                                        </label>
                                                    </div>
                                                    <div class="form-check" onClick={foramff} >
                                                        <input class="form-check-input" type="radio" name="csmtrbu" id="minimpuram" />
                                                        <label class="form-check-label" for="minimpuram">
                                                            Minimum purchase amount
                                                        </label>
                                                    </div>

                                                </div>
                                                <div class="row g-3 mt-2">
                                                    <div class="col-sm-3" style={fordisf}>
                                                        <label className='fw-normal'>Quantity</label>
                                                        <input type="text" class="form-control" aria-label="State" />
                                                    </div>
                                                    <div class="col-sm-3" style={foramf}>
                                                        <label className='fw-normal'>Amount</label>
                                                        <input type="text" class="form-control" aria-label="State" />
                                                    </div>
                                                    <div class="col-sm-7">
                                                        <label className='fw-normal'> Any items from</label>
                                                        <input type="text" class="form-control" aria-label="City" />
                                                    </div>


                                                </div>
                                                <div>
                                                    <div class="input-group my-3 " >
                                                        <span class="input-group-text bg-white border-end-0" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span><input type="text" class="form-control border-start-0" placeholder='Search Customer Segments' />
                                                        <button class="btn border rounded ml-3" type="button" id="button-addon2" ><b>Browse</b></button>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className='card p-3'>

                                                <div className="">
                                                    <p className='fw-bolder'>Customer gets</p>
                                                    <p>Customers must add the quantity of items specified below to their cart.</p>
                                                </div>
                                                <div class="row g-3">

                                                    <div class="col-sm-3">
                                                        <label className='fw-normal'>Quantity</label>
                                                        <input type="text" class="form-control" aria-label="State" />
                                                    </div>
                                                    <div class="col-sm-7">
                                                        <label className='fw-normal'> Any items from</label>
                                                        <input type="text" class="form-control" aria-label="City" />
                                                    </div>


                                                </div>
                                                <div>
                                                    <div class="input-group my-3 " >
                                                        <span class="input-group-text bg-white border-end-0" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span><input type="text" class="form-control border-start-0" placeholder='Search Customer Segments' />
                                                        <button class="btn border rounded ml-3" type="button" id="button-addon2" ><b>Browse</b></button>
                                                    </div>

                                                </div>
                                                <p className='fw-bolder'>AT A DISCOUNTED VALUE</p>
                                                <div class="form-check" onClick={froxayinpudisfn}>
                                                    <input class="form-check-input" type="radio" name="bxay" id="Percentagef" />
                                                    <label class="form-check-label" for="Percentagef">
                                                        Percentage
                                                    </label>
                                                </div>
                                                <div class="form-check" onClick={frefn} >
                                                    <input class="form-check-input" type="radio" name="bxay" id="fre" />
                                                    <label class="form-check-label" for="fre">
                                                        Free
                                                    </label>
                                                </div>
                                                <input type="text" class="form-control w-25 mt-2" style={displayperinpu} />
                                                <div className='border-top mt-2'></div>

                                                <div class="form-check mt-2" data-bs-toggle="collapse" href="#setmn" role="button" aria-expanded="false" aria-controls="setmn" >

                                                    <input class="form-check-input" type="checkbox" name="limitnmbr" id="setmno" />
                                                    <label class="form-check-label" for="setmno" >
                                                        Set a maximum number of uses per order
                                                    </label>


                                                </div>
                                                <div class="collapse" id="setmn">
                                                    <input type="text" class="form-control w-25 mt-2" />
                                                </div>

                                            </div>
                                        </div>

                                        <div className='card p-3'>

                                            <div className="">
                                                <p className='fw-bolder'>Customer eligibility</p>
                                                <div class="form-check" onClick={allcstmr} >
                                                    <input class="form-check-input" type="radio" name="cstmreleg" id="allcstmr" />
                                                    <label class="form-check-label" for="allcstmr">

                                                        All customers
                                                    </label>
                                                </div>
                                                <div class="form-check" onClick={specificcsmrseg} >
                                                    <input class="form-check-input" type="radio" name="cstmreleg" id="specificcstmrseg" />
                                                    <label class="form-check-label" for="specificcstmrseg">
                                                        Specific customer segments
                                                    </label>
                                                </div>
                                                <div class="form-check" onClick={specicsmt}>
                                                    <input class="form-check-input" type="radio" name="cstmreleg" id="specocstm" />
                                                    <label class="form-check-label" for="specocstm">

                                                        Specific customers

                                                    </label>
                                                </div>
                                            </div>
                                            <div style={specificcstmrsegstyle}>
                                                <div class="input-group my-3 " >
                                                    <span class="input-group-text bg-white border-end-0" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span><input type="text" class="form-control border-start-0" placeholder='Search Customer Segments' />
                                                    <button class="btn border rounded ml-3" type="button" id="button-addon2" ><b>Browse</b></button>
                                                </div>
                                                <p style={{ fontSize: "12px " }}>Select customer segments that can use this discount. <a href="#" style={{ textDecoration: "underline" }}>Learn more</a></p>
                                            </div>
                                            <div style={specificcstmrstyle} >
                                                <div class="input-group my-3 " >
                                                    <span class="input-group-text bg-white border-end-0" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i></span><input type="text" class="form-control border-start-0" placeholder='Search Customers' />
                                                    <button class="btn border rounded ml-3" type="button" id="button-addon2" ><b>Browse</b></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='card p-3'>

                                            <div className="">
                                                <p className='fw-bolder'>Maximum discount uses</p>
                                                <div class="form-check"  >

                                                    <input class="form-check-input" type="checkbox" name="cstmreleg" id="limitnum" />
                                                    <label class="form-check-label" for="limitnum" onClick={maxusesdis}  >
                                                        Limit number of times this discount can be used in total
                                                    </label>
                                                    <input type="text" class="form-control w-25" style={stylelimitnotime} />

                                                </div>
                                                <div class="form-check" >
                                                    <input class="form-check-input" type="checkbox" name="cstmreleg" id="limitcstmr" />
                                                    <label class="form-check-label" for="limitcstmr">
                                                        Limit to one use per customer
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='card p-3'>

                                            <div className="">
                                                <p className='fw-bolder'>Combinations <span className='bg-secondary px-2 rounded-pill py-1'>New</span></p>

                                                <p>This product discount can be combined with:</p>
                                                <div class="form-check" data-bs-toggle="collapse" href="#otherproddis" role="button" aria-expanded="false" aria-controls="otherproddis">

                                                    <input class="form-check-input" type="checkbox" name="cstmreleg" id="otprdis" />
                                                    <label class="form-check-label" for="otprdis"  >
                                                        Other product discounts

                                                    </label>


                                                </div>
                                                <div class="collapse" id="otherproddis">
                                                    <div className='pl-4 py-2' style={{ fontSize: "13px" }}>
                                                        No product discounts are currently set to combine. To let customers use more than one discount, set up at least one product discount that combines with product discounts.
                                                        <br /><a href="#" style={{ textDecoration: "underline" }}>Learn More</a>
                                                    </div>
                                                </div>
                                                <div class="form-check" data-bs-toggle="collapse" href="#shipingdisc" role="button" aria-expanded="false" aria-controls="shipingdisc">
                                                    <input class="form-check-input" type="checkbox" name="cstmreleg" id="shipdisc" />
                                                    <label class="form-check-label" for="shipdisc">

                                                        Shipping discounts
                                                    </label>
                                                </div>
                                                <div class="collapse" id="shipingdisc">
                                                    <div className='pl-4 py-2' style={{ fontSize: "13px" }}>
                                                        No product discounts are currently set to combine. To let customers use more than one discount, set up at least one product discount that combines with product discounts.
                                                        <br /><a href="#" style={{ textDecoration: "underline" }}>Learn More</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='card p-3'>

                                            <div className="">
                                                <p className='fw-bolder'>Active dates</p>
                                                <div class="row g-3">
                                                    <div class="col">
                                                        <label className='fw-normal'>Start Date</label>
                                                        <input type="date" class="form-control" />
                                                    </div>
                                                    <div class="col">
                                                        <label className='fw-normal'>Start time (IST)</label>
                                                        <input type="text" class="form-control" />
                                                    </div>
                                                </div>
                                                <div class="form-check mt-3 " data-bs-toggle="collapse" href="#setendend" role="button" aria-expanded="false" aria-controls="setendend" >
                                                    <input class="form-check-input" type="checkbox" name="cstmreleg" id="setenddate" />
                                                    <label class="form-check-label" for="setenddate">
                                                        Set end date

                                                    </label>

                                                </div>
                                                <div class="collapse mt-3" id="setendend">
                                                    <div class="">
                                                        <div class="row g-3">
                                                            <div class="col">
                                                                <label className='fw-normal'>End Date</label>
                                                                <input type="date" class="form-control" />
                                                            </div>
                                                            <div class="col">
                                                                <label className='fw-normal'>End time (IST)</label>
                                                                <input type="text" class="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className='py-2 col-md-4'>
                                        <div className='card p-3' style={{ background: "#0051f505" }}>
                                            <div className="">
                                                <p className='fw-normal fs-5'>Summary</p>
                                                <p className='text-secondary text-sm pt-2'>No discount code yet.</p>
                                            </div>

                                            <div>
                                                <p className='text-sm py-2'>TYPE AND METHOD</p>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>
                                                        Amount off products
                                                    </li>
                                                    <li style={{ listStyleType: "circle" }}>
                                                        Amount off products
                                                    </li>
                                                </ul>

                                            </div>
                                            <div>
                                                <p className='text-sm py-2'>DETAILS</p>
                                                <ul>
                                                    <li style={{ listStyleType: "circle" }}>
                                                        Can’t combine with other discounts
                                                    </li>

                                                </ul>

                                            </div>
                                            <div className='border-top'></div>
                                            <div>
                                                <p className='text-sm py-2'>PERFORMANCE</p>
                                                <p>Discount is not active yet.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                <button className='bg-light px-2 border rounded py-2'>Discard</button><button className='float-end bg-success px-2 border rounded py-2'>Save Disscount</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Typeofdisc