import React from 'react'
import { useNavigate, Link } from 'react-router-dom';

function MetafieldOrder() {
    let navigate = useNavigate();
  return (
    <div className='pl-md-3 mt-3 mt-md-0'>
            {/* for going back btn */} 
        <div className='d-flex align-items-start'>
            <img className='img-fluid d-none d-sm-block' src="/images/previous.png" alt='back_logo' onClick={() => { navigate(-1) }} />
            <span className='ml-2 fw-normal fs-5'>Order metafield definitions</span>
        </div> 
        <div className='border-top my-4'></div>
        <div className='card p-3'>
            <div className='text-center'>
                <img src="/images/uploadImageLogo.svg" alt="add-field"/>
                <h5 className='mt-1'>Add new fields to orders</h5>
                <p className='text-sm text-secondary py-2'>Use definitions to add metafield values on your order pages.</p>
                <Link to='/setting/metafield/order/create_definition' className='btn btnColorCustome btnColorOnHover'>Add definition</Link>
            </div>
        </div>
    </div>  

  )
}

export default MetafieldOrder