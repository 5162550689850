import React from 'react'
import { useNavigate } from 'react-router-dom'

const PackingSlipTemplate = () => {
    let navigate = useNavigate()

  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                    <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                    <span className='px-2 fw-normal fs-5 lh-1'>Edit packing slip template</span>
                </div> 
                <a href='#' className='btn-sm btnColorCustome btnColorOnHover text-nowrap'>Preview template</a>
            </div>
            <div className='border-top my-4'></div>
            <div className='row'>
                <div className='col-md-8 mt-3'>
                    <div className='packingSlipTem border rounded p-3 bg-white'>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus numquam cumque odit sint facere repellat possimus voluptate vitae. Deleniti omnis eaque tempore provident a enim, suscipit voluptatum excepturi in ullam!</p>
                    </div>
                </div>
                <div className='col-md-4 mt-3'>
                   <div className='card p-3 bg-light'>
                       <h6 className='py-2'>Editing your template</h6>
                       <p className='text-sm'>To edit your template, use HTML, CSS, and <a href="#">Liquid variables</a> for packing slips.</p>
                       <p className='text-sm'>Learn how to make <a href="#">common edits</a>.</p>
                   </div>
                </div>
            </div>
            <div className='border-top my-4'></div>
            <div className='d-flex justify-content-between'>
                <button className='btn btn-outline-light border text-dark'>Revert to default</button>
                <button className=' btn btnColorCustome btnColorOnHover'>Save</button>
            </div>
            <div className='text-center mt-5 mb-md-0 mb-2'>
                Learn more about <a href="#">packing slips</a>.
            </div>

        </div>
    </React.Fragment>
  )
}

export default PackingSlipTemplate