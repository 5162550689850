
import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const ProcessingTime = () => {
    let navigate = useNavigate();
  return (
    
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Processing time</span>
            </div> 
            <div className='border-top my-4'></div>
            <div className='card p-3'>
                <h6>Processing time</h6>
                <p className='text-secondary text-sm py-2'>How long it takes you to process an order, from the time it is placed to when the package is handed to the carrier. This will be added to the transit time of the carrier.</p>
                <div className="alert alert-primary d-flex" role="alert">
                    <div className='mr-3 mt-1'>
                        <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                    </div>
                    <div>
                        You currently don't have any shipping rates with transit time. Once you've set up transit times, you can then use processing time to show delivery dates to your customers. <a href="#" className='text-dark'>Learn more</a>
                    </div>
                </div>
                <div className="form-check ml-1">
                    <input className="form-check-input shadow-none" type="checkbox" defaultValue id="processTimeCheck" data-bs-toggle="collapse" data-bs-target="#deliveryDateSelect" aria-expanded="false" aria-controls="deliveryDateSelect"/>
                    <label className="form-check-label" htmlFor="processTimeCheck">
                        Use processing time to show delivery dates at checkout
                    </label>
                </div>
                <div className='pt-3 collapse' id="deliveryDateSelect">
                    <select className="form-select shadow-none" aria-label="Default select example">
                        <option>Same business day</option>
                        <option>Next business day</option>
                        <option>2 business day</option>
                    </select>
                    <div className='pt-2 text-secondary text-sm'>Orders placed after 12 pm are treated as if they came in the next business day.</div>
                </div>

            </div>
            <div className='text-center py-3'>
                Learn more about <a href="#">processing time</a>
            </div>
        </div>
    </React.Fragment>
  )
}
export default ProcessingTime