import React from 'react'
import { Link} from 'react-router-dom';

const DashbordSideBarMenu = () => {

  return (

    <React.Fragment>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-light-primary elevation-4">
        {/* Brand Logo */}
        <div className="sideBarMenu brand-link d-flex justify-content-between align-items-center">
          <a className="brand-link" href="/">
            <img src="/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
            <span className="brand-text fw-normal">e-Web Builder</span>
          </a>
          <a className="pushmenu d-md-none d-sm-block" data-widget="pushmenu" href="/" role="button">
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>

        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="myItem nav-item">
                <Link to='/' className="nav-link">
                  <i className="nav-icon fa fa-home" />
                  <p>Dashbord</p>
                </Link>
              </li>

              <li className="myItem nav-item">
                <a href="#forOrder" className="nav-link" role='button' data-bs-toggle="collapse" aria-expanded="false" aria-controls="forProduct">
                  <i className="nav-icon fa fa-shopping-bag" />
                  <p>Orders</p>
                </a>
              </li>
              <ul id="forOrder" className="collapse multilevelLink">
                <li className="nav-item">
                  <Link to='/orderpage' className="nav-link">
                    <p>Orders</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Drafts</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Abandoned Checkouts</p>
                  </Link>
                </li>
              </ul>
              <li className="myItem nav-item">
                <a href="#forProduct" className="nav-link" role='button' data-bs-toggle="collapse" aria-expanded="false" aria-controls="forProduct">
                  <i className="nav-icon fa fa-product-hunt" />
                  <p>Products</p>
                </a>
              </li>
              <ul id="forProduct" className="collapse multilevelLink">
                <li className="nav-item">
                  <Link to='/products' className="nav-link">
                    <p>Product</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='/products/Inventory' className="nav-link">
                    <p>Inventory</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='/products/Transfers' className="nav-link">
                    <p>Transfers</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='/products/Collections' className="nav-link">
                    <p>Collections</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='/products/GiftCard' className="nav-link">
                    <p>Gift cards</p>
                  </Link>
                </li>
              </ul>


              <li className="myItem nav-item">
                <a href="/customer" className="nav-link">
                  <i className="nav-icon fa fa-user-o" />
                  <p>Customers</p>
                </a>
              </li>
              <li className="myItem nav-item">
                <a href="#forAnalytics" className="nav-link" role='button' data-bs-toggle="collapse" aria-expanded="false" aria-controls="forProduct">
                  <i className="nav-icon fa fa-line-chart" />
                  <p>Analytics</p>
                </a>
              </li>
              <ul id="forAnalytics" className="collapse multilevelLink">
                <li className="nav-item">
                  <Link to='/Analytics' className="nav-link">
                    <p>Analytics</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Reports</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Live View</p>
                  </Link>
                </li>
              </ul>
              <li className="myItem nav-item">
                <a href="#forMarketing" className="nav-link" role='button' data-bs-toggle="collapse" aria-expanded="false" aria-controls="forProduct">
                <i className="nav-icon fa fa-handshake-o" aria-hidden="true"></i>
                  <p>Marketing</p>
                </a>
              </li>

              <ul id="forMarketing" className="collapse multilevelLink">

                <li className="nav-item">
                  <Link to='marketing' className="nav-link">
                    <p>Marketing</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Campaigns</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Automations</p>
                  </Link>
                </li>
              </ul>
              <li className="myItem nav-item">
                <a href="/disscounts" className="nav-link">
                  <i className="nav-icon fa fa-percent" />
                  <p>Disscount</p>
                </a>
              </li>


              <li className="nav-header">SALES CHANNELS</li>
              <li className="myItem nav-item">
                <a href="#forOnline" className="nav-link" role='button' data-bs-toggle="collapse" aria-expanded="false" aria-controls="forProduct">
                  <i className="nav-icon fa fa-shopping-cart" />
                  <p>Online Store</p>
                </a>
              </li>
              <ul id="forOnline" className="collapse multilevelLink">

                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Online Store</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Themes</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Blog Post</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Page</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Navigation</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Prefrences</p>
                  </Link>
                </li>
              </ul>
              <li className="myItem nav-item">
                <a href="#forpointofsale" className="nav-link" role='button' data-bs-toggle="collapse" aria-expanded="false" aria-controls="forProduct">
                  <i className="nav-icon fas fa-store-alt" />
                  <p>Point of Sale</p>
                </a>
              </li>
              <ul id="forpointofsale" className="collapse multilevelLink">
                <li className="nav-item">
                  <Link to='/pointofsale' className="nav-link">
                    <p>Point of Sale</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Overview</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Staff</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link">
                    <p>Location</p>
                  </Link>
                </li>
              </ul>

              <li className="myItem nav-item">
                  <Link to="/setting" className="nav-link">
                    <i className="nav-icon fa fa-cogs"/>
                    <p>Settings</p>
                  </Link>
              </li>

            </ul>

          </nav>

        </div>

      </aside>

    </React.Fragment>
  )
}

export default DashbordSideBarMenu;