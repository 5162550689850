
import React from "react";
import { Link } from "react-router-dom";

const SettingBillings = ()=>{

     return(
      <React.Fragment>
      <div className='pl-md-3 mt-3 mt-md-0' >
           {/* -- Billings -- */} 
          <h5>Billing</h5>
          <div className='border-top my-4'></div>

              {/* -- Payment methods -- */}
            <div className='card p-3'>
               <div className='pt-2'>
                  <h6 className=''>Payment methods</h6>
               </div>
 
               <div className='border-top my-2'></div>

               <div className='pt-2'>
                  <p className='text-secondary text-sm'>Add a payment method for purchases and bills in Ecom builder.</p>
               </div>
                  {/* add payment method modal*/}
               <div className="addPayMethod">
                     <button type="button" className="btn btnColorCustome btnColorOnHover" data-bs-toggle="modal" data-bs-target="#exampleModal">Add payment method</button>
                     
                     <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-lg">
                           <div className="modal-content">
                              <div className="modal-header">
                                 <h5 className="modal-title" id="exampleModalLabel">Add payment method</h5>
                                 <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                              </div>
                              <div className="modal-body">

                                       {/* alert message */}
                                    <div className="alert alert-primary d-flex align-items-center mt-4" role="alert">
                                       <div className='mr-3'>
                                          <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                                       </div>
                                       <div>
                                          To comply with the Reserve Bank of India's new guidelines, you must use one of our <a href="#" className="text-dark">accepted credit cards</a> or <a href="#" className="text-dark">co-branded debit cards</a>.
                                       </div>
                                    </div>

                                       {/* other input feilds */}
                                    <div className="SelectPayMethodType">
                                       <label htmlFor="" className="fw-normal">Payment method type</label>
                                       <select className="form-select shadow-none" aria-label="Default select example">
                                       <option value={1}>Credit card</option>
                                       <option value={2}>UPI</option>
                                       </select>
                                    </div>

                                    {/*credit card information */}
                                    <div className="pt-3">
                                       <p className="text-sm">CREDIT CARD INFORMATION</p>
                                    </div>
                                    <div>
                                       <label htmlFor="" className="fw-normal">Card number</label>
                                       <input type="text" className="form-control" />
                                    </div>
                                    
                                    <div className="row">
                                       <div className="col-md-6 pt-3">
                                          <label htmlFor="" className="fw-normal">Expires</label>
                                          <input type="text" className="form-control" placeholder='MM/YY' />
                                       </div>
                                       <div className="col-md-6 pt-3">
                                          <label htmlFor="" className="fw-normal">CVV<span className="mx-2" data-bs-toggle="tooltip" data-bs-placement="top" title="About CVV"><i className="fa fa-info-circle fs-5 text-secondary"></i></span></label>
                                          <input type="password" className="form-control" />
                                       </div>
                                    </div>

                                       {/* address billing */}
                                    <div className="pt-3">
                                       <p className="text-sm">BILLING ADDRESS</p>
                                    </div>
                                    <div className="SelectCountry">
                                       <label htmlFor="" className="fw-normal">Country/region</label>
                                       <select className="form-select shadow-none" aria-label="Default select example">
                                       <option value={1}>India</option>
                                       <option value={2}>Pakistan</option>
                                       <option value={2}>Nepal</option>
                                       <option value={2}>Bhutan</option>
                                       </select>
                                    </div>
                                    <div className="row pt-3">
                                       <div className="col-md-6">
                                          <label htmlFor="" className="fw-normal">First Name</label>
                                          <input type="text" className="form-control shadow-none" placeholder="First Name" />
                                       </div>

                                       <div className="col-md-6">
                                          <label htmlFor="" className="fw-normal">Last Name</label>
                                          <input type="text" className="form-control shadow-none" placeholder="Last Name"/>
                                       </div>
                                    </div>
                                    <div className="pt-3">
                                       <label htmlFor="" className="fw-normal">Address</label>
                                       <input type="text" className="form-control shadow-none" placeholder="Address"/>
                                    </div>
                                    <div className="pt-3">
                                       <label htmlFor="" className="fw-normal">Apartment, suite, etc.</label>
                                       <input type="text" className="form-control shadow-none" placeholder="Apartment, suite, etc."/>
                                    </div>
                                    <div className="row">
                                       <div className="col-md-4 pt-3">
                                       <label htmlFor="" className="fw-normal">City</label>
                                       <input type="text" className="form-control shadow-none" placeholder="City"/>
                                       </div>
                                       <div className="col-md-4 pt-3">
                                       <label htmlFor="" className="fw-normal">State</label>
                                       <select className="form-select shadow-none">
                                          <option value="">Bihar</option>
                                          <option value="">Punjab</option>
                                          <option value="">Hariyana</option>
                                          <option value="">Jharkhand</option>
                                       </select>
                                       </div>
                                       <div className="col-md-4 pt-3">
                                          <label htmlFor="" className="fw-normal">PIN code</label>
                                          <input type="text" className="form-control shadow-none" placeholder="PIN code"/>
                                       </div>
                                    </div>

                                       <div className="pt-3">
                                          <label htmlFor="" className="form-label fw-normal">Phone</label>
                                          <div className="input-group">
                                             <input className="form-control" type="text"/>
                                             <select className="form-select shadow-none">
                                                <option value="">+91 India</option>
                                                <option value="">+91 India</option>
                                                <option value="">+91 India</option>
                                                <option value="">+91 India</option>
                                             </select>
                                          </div>
                                       </div>

                                       <div className="pt-3">
                                          <label htmlFor="" className="fw-normal">Email</label>
                                          <input type="email" className="form-control shadow-none"/>
                                       </div>
                                       
                                       {/* on checkbox alert */}
                                    <div className="pt-3 ml-1">
                                       <div className="form-check">
                                          <input className="form-check-input shadow-none" type="checkbox" defaultValue id="defaultCheck1"
                                          data-bs-toggle="collapse" 
                                          data-bs-target="#clickOnCheckAlert" 
                                          aria-expanded="true" 
                                          aria-controls="clickOnCheckAlert" />
                                          <label className="form-check-label" htmlFor="defaultCheck1">
                                             I consent to having my card saved securely for future payments
                                          </label>
                                       </div>
                                    </div>

                                       {/* alert message */}
                                    <div className="collapse" id='clickOnCheckAlert'>
                                       <div className="alert alert-primary d-flex align-items-center mt-4"    role="alert">
                                          <div className='mr-3'>
                                             <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                                          </div>

                                          <div className="text-sm">  
                                             You will be redirected to Razorpay to finish authentication and may be requested for a one-time mandate for ₹15,000. This will allow Shopify to automatically charge future bills below the mandate amount.
                                          </div>
                                       </div>
                                    </div>

                                 </div>
                              <div className="modal-footer">
                                 <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                                 <button type="button" className="btn btnColorCustome btnColorOnHover" disabled>Add credit card</button>
                              </div>
                           </div>
                        </div> 
                     </div>

               </div>

            </div>

              {/* Billing currency*/}
            <div className='card p-3'>
              <div className='pt-2 d-flex justify-content-between'>
                <h6 className=''>Billing currency <a href="#" className="text-sm mx-1"><i className="fa fa-info-circle px-1" aria-hidden="true"></i>Learn more</a></h6>
                <a href="#" data-bs-toggle="modal" data-bs-target="#ChangeCurrModal">Change currency</a>
              </div>

              {/* Change currency modal */}
               <div className="modal fade" id="ChangeCurrModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                     <div className="modal-header">
                     <h5 className="modal-title" id="exampleModalLabel">Change currency</h5>
                     <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"/>
                     </div>
                     <div className="modal-body">
                        <div>
                           <label htmlFor="" className="fw-normal">Select the currency you want to be billed in.</label>
                           <select className="form-select shadow-none" aria-label="Default select example">
                              <option value={1}>USD (US Dollar)</option>
                           </select>
                        </div>
                        <div className="pt-4">
                            <p className="text-sm">
                            This change will be applied to your next billing cycle. If you have any outstanding charges, you'll be billed for them in your current currency (INR). Any remaining credits will be converted to the new currency. Learn more about <a href="#">your billing currency</a>
                            </p>
                        </div>

                     </div>
                     <div className="modal-footer">
                     <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                     <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button>
                     </div>
                  </div>
               </div>
               </div>

              <div className='border-top my-2'></div>
              <div className="py-2">
                   Your current billing currency is set to <b>INR (Indian Rupee)</b>.
              </div>
            </div>

              {/* Subscriptions */}
             <div className='card p-3'>
               <div className='pt-2'>
                  <h6 className=''>Subscriptions</h6>
                  <p className="text-sm text-secondary">Items you're billed for on a recurring basis, like your Ecom builder plan and apps.</p>
               </div>
               <div className='border-top my-2'></div>

               <div className='d-flex align-items-center pt-2'>
                  <div className="border rounded mr-2">
                        <span><i className="fa fa-shopping-bag p-2 fs-5 text-success" aria-hidden="true"></i></span>
                  </div>
                  <div className='text-break'>
                        <span>Trial</span><br/>
                        <span className='text-secondary text-sm'>Your trial ends on December 9, 2022</span>
                  </div>
               </div>
               <div className='border-top my-3'></div>
               <div>
                  <Link to="/setting/billing/subscription">View all</Link>
               </div>
            </div>

              {/* Upcoming bill  */}
            <div className='card p-3'>
               <div className='pt-2'>
                  <h6 className=''>Upcoming bill <a href="#" className="text-sm mx-1"><i className="fa fa-info-circle px-1" aria-hidden="true"></i>Learn more</a></h6>
                  <p className="text-sm text-secondary">If this cycle's fees exceed the equivalent of $60.00 USD, you'll need to pay these fees before incurring more. Your billing threshold is set in USD, even if your billing currency is different.</p>
               </div>
               <div className='border-top'></div>
               <div className="pt-3">
                  <p>Billing cycle: Billed on Dec 9, 2022</p>
               </div>
               <div className='border-top'></div>
               <div className="d-flex justify-content-between pt-3">
                  <p className="text-sm">Subscription charges</p>
                  <p className="text-sm">₹0.00</p>
               </div>
               <div className='border-top'></div>
               <div className="d-flex justify-content-between pt-3">
                  <p className="text-sm">Running total</p>
                  <p className="text-sm">₹0.00 INR</p>
               </div>
               <div className='border-top'></div>
               <div className="pt-3">
                  <Link to="/setting/billing/upcoming">View details</Link>
               </div>

            </div>

              {/* Credits */}
            <div className='card p-3'>
               <div className='pt-2'>
                  <h6 className=''>Credits</h6>
               </div>
               <div className='border-top mt-3'></div>
               <div className="pt-3">
                  <p className="text-sm text-secondary">There are no credits on your account right now</p>
               </div>
            </div>

              {/* -- Tax registration -- */}
            <div className='card p-3'>
               <div className='pt-2'>
                  <h6 className=''>Tax registration</h6>
                  <p className="text-sm text-secondary">Manage your tax registration information. Ecom's services are intended for business use. Check with a tax expert to understand your tax obligations.</p>
               </div>
               <div className='border-top'></div>
               <div className="pt-3">
                  <h6 className='fw-normal'>GSTIN</h6>
                  <p className="text-sm text-secondary">Ecom builder is required to charge Goods and Services Tax (GST) in India. Your ecom bills may be exempt from Indian GST if you are GST registered in India and enter a valid GSTIN</p>
               </div>
               <div className='border-top'></div>

               <div className="pt-3">
                  <button type="button" className="btn btnColorCustome btnColorOnHover"  data-bs-toggle="modal" data-bs-target="#GSTINModal">Add GSTIN</button>
                  <a href="#" className="pl-2">I don't have a GSTIN</a>
               </div>
               {/* Add GSTIN modal */}
               <div className="modal fade" id="GSTINModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                     <div className="modal-header">
                     <h5 className="modal-title" id="exampleModalLabel">Add GSTIN</h5>
                     <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"/>
                     </div>
                     <div className="modal-body">
                        <p className="text-sm text-secondary">Your Shopify bills may be exempt from Indian GST if you are GST registered in India and enter a valid GSTIN</p>
                        <div>
                           <label htmlFor="" className="fw-normal">GSTIN</label>
                           <input type="text" className="form-control"/>
                        </div>
                        <p className="text-secondary text-sm pt-1">15 digit identifier</p>
                     </div>
                     <div className="modal-footer">
                     <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                     <button type="button" className="btn btnColorCustome btnColorOnHover" disabled>Add GSTIN</button>
                     </div>
                  </div>
               </div>
               </div>


            </div>

              {/* Statement of charges */}
              <div className='card p-3'>
               <div className='pt-2'>
                  <h6 className=''>Statement of charges</h6>
               </div>
               <div className='border-top mt-3'></div>
               <div className="pt-3">
                  <p className="text-sm text-secondary">View a summary of all your Shopify charges for any date range within the last 90 days.</p>
                  <Link to="/setting/billing/statement_of_charges">View summary</Link>
               </div>
            </div>
           




      </div>
  </React.Fragment>
     );

}

export default SettingBillings