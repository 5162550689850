import React from 'react'
import { Link } from 'react-router-dom';

const Products = () => {
  return (
    <React.Fragment>
    <div className='content-wrapper topSpace'>
        <div className="container-fluid">
                <div className="container px-4">
                <div className="row fw-normal fs-5 pb-2">
                    Products
                </div>
                <div className="row rounded " style={{ background: 'white' }}>
                    <div className="text-center p-md-5">
                        <img alt="customer-logo" className="img-fluid" src="/images/customer.svg" />
                        <p className="fs-5">First up: what are you selling?</p>
                        <p className="">Before you open your store, first you need some products.</p>

                        <a href='#' target='_blank' className="btn btnColorCustome btnColorOnHover m-2">Find products to sell</a>

                        <Link to="/products/add-products">
                            <button type="button" className="btn btnColorCustome btnColorOnHover m-2">Add your products</button>
                        </Link>
                    </div>
                </div>
                <p className='text-center py-3'>Learn more about <a href='#'>Products</a></p>
 
            </div>
        </div>
    </div>

</React.Fragment>

    
  )
}

export default Products