import React from 'react';
import { Line } from "react-chartjs-2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Chart,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
} from 'chart.js';
import { useState } from 'react';

Chart.register(

    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Tooltip,

);

function Marketing() {

    const settingsforslider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,

    };

    const data = {
        labels: ["jan", "fab", "march", "Apr", "may", "june"],
        datasets: [{
            label: 'My First Dataset1',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: 'green',
            tension: 0.2,

        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }


    const data2 = {
        labels: ["jan", "fab", "march", "Apr", "may", "june"],
        datasets: [{
            label: 'My First Dataset',
            data: [50, 59, 80, 45, 56, 10],
            fill: false,
            borderColor: 'green',
            tension: 0.2
        }]
    };

    const data3 = {
        labels: ["jan", "fab", "march", "Apr", "may", "june"],
        datasets: [{
            label: 'My First Dataset',
            data: [50, 59, 80, 45, 35, 10],
            fill: false,
            borderColor: 'green',
            tension: 0.2,

        }]
    };
    return (
        <div>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className="row">

                            <div className="col-md-8 mt-3">
                                <p className='fw-bolder fs-4'>Marketing </p>

                            </div>
                            <div className="col-md-4 mt-3">
                                <button type="button" class="btn btn-success border float-end" >  Create Campaign</button>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-10 mt-3">
                                <button type="button" class="btn btn-light border"> <i class="fa fa-calendar" aria-hidden="true"></i> Today</button> compared to Oct 16, 2022

                            </div>

                        </div>

                        <div className="row mt-4">
                            <div className="col-md-4 col-12">
                                <div className='card p-3'>
                                    <p className='mb-0'>Online store sessions <span className='float-end'><a href='#'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>0</p>

                                    <Line data={data2} option={options} >

                                    </Line>
                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Total Sales <span className='float-end'><a href='/reportsby/Sales over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>₹ 70</p>

                                    <Line data={data} options={options} >

                                    </Line>


                                </div>




                            </div>
                            <div className="col-md-4 col-12">
                                <div className='card p-3'>
                                    <p className='mb-0'>Online store conversion rate <span className='float-end'><a href='#'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>0%</p>

                                    <Line data={data3} option={options} >

                                    </Line>
                                </div>

                                <div className='card p-3'>
                                    <p className='mb-0'>Sales attributed to marketing <span className='float-end'><a href='/reportsby/Average order value over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>₹ 70</p>

                                    <Line data={data} options={options} >

                                    </Line>


                                </div>

                            </div>
                            <div className="col-md-4 col-12">
                                <div className='card p-3'>
                                    <p className='mb-0'>Average order value <span className='float-end'><a href='/reportsby/Average order value over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>₹ 70</p>

                                    <Line data={data} options={options} >

                                    </Line>


                                </div>
                                <div className='card p-3'>
                                    <p className='mb-0'>Orders attributed to marketing <span className='float-end'><a href='/reportsby/Sales over time'>View Report</a></span></p>
                                    <p className='mt-0 fs-2 fw-bolder'>0</p>

                                    <Line data={data} options={options} >

                                    </Line>


                                </div>


                            </div>
                        </div>

                        <div className="Container">
                            <div className='card p-3 m-4'>
                                <p><b>Marketing Apps</b></p>
                                <p>Increase sessions, engage shoppers, and promote products by adding more marketing apps.</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="row p-2">
                                            <div className="col-1">
                                                <img src="/images/shop.png" style={{ width: "180%" }} />

                                            </div>
                                            <div className="col">
                                                <p><a href="#" className='fw-bolder'>Shop</a></p>
                                                <p className=''>Create a free mobile app experience for your online store.</p>
                                                <p><a href='#'>Set up app</a></p>
                                            </div>
                                        </div>
                                        <div className="row p-2">
                                            <div className="col-1">
                                                <img src="/images/emailmarketing.png" style={{ width: "180%" }} />

                                            </div>
                                            <div className="col">
                                                <p><a href="#" className='fw-bolder'>Omnisend Email Marketing & SMS</a></p>
                                                <p className=''>Send emails, newsletters, SMS. Grow subscribers with popups.</p>
                                                <p><a href='#'>Set up app</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row p-2">
                                            <div className="col-1">
                                                <img src="/images/snap.png" style={{ width: "180%" }} />

                                            </div>
                                            <div className="col">
                                                <p><a href="#" className='fw-bolder'>
                                                    Snapchat Ads</a></p>
                                                <p className=''>Grow your business with shoppable ads on Snapchat!</p>
                                                <p><a href='#'>Set up app</a></p>
                                            </div>
                                        </div>
                                        <div className="row p-2">
                                            <div className="col-1">
                                                <img src="/images/sms.webp" style={{ width: "180%" }} />

                                            </div>
                                            <div className="col">
                                                <p><a href="#" className='fw-bolder'>Omnisend Email Marketing & SMS</a></p>
                                                <p className=''>Send emails, newsletters, SMS. Grow subscribers with popups.</p>
                                                <p><a href='#'>Set up app</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row p-2">
                                            <div className="col-1">
                                                <img src="/images/segunoemail.webp" style={{ width: "180%" }} />

                                            </div>
                                            <div className="col">
                                                <p><a href="#" className='fw-bolder'>
                                                    Seguno: Email Marketing</a></p>
                                                <p className=''>Email marketing newsletter, abandoned cart, & email templates.</p>
                                                <p><a href='#'>Set up app</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Container">
                            <div className='card p-3 m-4'>
                                <p><b>Learn more about marketing</b></p>
                                <p>Boost your marketing mindset with expert advice from <a href="#">Shopify Learn.</a></p>

                                <Slider {...settingsforslider}>

                                    <div>
                                        <div className='card m-4'>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <iframe width="100%" src="https://www.youtube.com/embed/FznDt4OE9vY?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </div>
                                                <div className="col-md-8 p-2">
                                                    <ul className='exploreSupport mb-0 float-end'>
                                                        <li className="nav-item dropdown">
                                                            <a className="nav-link text-secondary" data-toggle="dropdown" href="/">
                                                                <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                                <a href="/" className="dropdown-item text-secondary">
                                                                    <i className="fa fa-times mr-3" aria-hidden="true"></i>Dismiss
                                                                </a>
                                                                <a href="/" className="dropdown-item text-secondary"><i className="fa fa-commenting mr-3" aria-hidden="true"></i>Give feedback
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <p className='fw-bolder mt-2'>Top 5 rules of digital advertising</p>
                                                    <p>New to marketing? Here are 5 core principles to creating successful ads.</p>
                                                    <button className='border rounded px-3 py-1 mt-4'>Learn More</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='card m-4'>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <iframe width="100%" src="https://www.youtube.com/embed/FznDt4OE9vY?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </div>
                                                <div className="col-md-8 p-2">
                                                    <ul className='exploreSupport mb-0 float-end'>
                                                        <li className="nav-item dropdown">
                                                            <a className="nav-link text-secondary" data-toggle="dropdown" href="/">
                                                                <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                                <a href="/" className="dropdown-item text-secondary">
                                                                    <i className="fa fa-times mr-3" aria-hidden="true"></i>Dismiss
                                                                </a>
                                                                <a href="/" className="dropdown-item text-secondary"><i className="fa fa-commenting mr-3" aria-hidden="true"></i>Give feedback
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <p className='fw-bolder mt-2'>Top 5 rules of digital advertising</p>
                                                    <p>New to marketing? Here are 5 core principles to creating successful ads.</p>
                                                    <button className='border rounded px-3 py-1 mt-4'>Learn More</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='card m-4'>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <iframe width="100%" src="https://www.youtube.com/embed/FznDt4OE9vY?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </div>
                                                <div className="col-md-8 p-2">
                                                    <ul className='exploreSupport mb-0 float-end'>
                                                        <li className="nav-item dropdown">
                                                            <a className="nav-link text-secondary" data-toggle="dropdown" href="/">
                                                                <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                                <a href="/" className="dropdown-item text-secondary">
                                                                    <i className="fa fa-times mr-3" aria-hidden="true"></i>Dismiss
                                                                </a>
                                                                <a href="/" className="dropdown-item text-secondary"><i className="fa fa-commenting mr-3" aria-hidden="true"></i>Give feedback
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <p className='fw-bolder mt-2'>Top 5 rules of digital advertising</p>
                                                    <p>New to marketing? Here are 5 core principles to creating successful ads.</p>
                                                    <button className='border rounded px-3 py-1 mt-4'>Learn More</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='card m-4'>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <iframe width="100%" src="https://www.youtube.com/embed/FznDt4OE9vY?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </div>
                                                <div className="col-md-8 p-2">
                                                    <ul className='exploreSupport mb-0 float-end'>
                                                        <li className="nav-item dropdown">
                                                            <a className="nav-link text-secondary" data-toggle="dropdown" href="/">
                                                                <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                                <a href="/" className="dropdown-item text-secondary">
                                                                    <i className="fa fa-times mr-3" aria-hidden="true"></i>Dismiss
                                                                </a>
                                                                <a href="/" className="dropdown-item text-secondary"><i className="fa fa-commenting mr-3" aria-hidden="true"></i>Give feedback
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <p className='fw-bolder mt-2'>Top 5 rules of digital advertising</p>
                                                    <p>New to marketing? Here are 5 core principles to creating successful ads.</p>
                                                    <button className='border rounded px-3 py-1 mt-4'>Learn More</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Slider>
                                <div className="btnforpn" style={{ width: "25%" }}>
                                    <button type="button" className='border w-25 p-0 prevbtn' data-role="none"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
                                    <button className='border w-25 nextbtn'><i class="fa fa-arrow-right " aria-hidden="true"></i>    </button>
                                </div>


                            </div>
                        </div>


                    </div>


                </div>
            </div>

        </div >
    )
}

export default Marketing