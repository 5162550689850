
import React from 'react'
import LanguageModal from './SettingCompModal/LanguageModal'

const SettingLanguage = ()=>{
    return(
        <React.Fragment>
            <LanguageModal/>
            <div className='pl-md-3 mt-3 mt-md-0'>
                <h5>Store languages</h5>
                <div className='border-top my-4'></div>

                {/* Expand your global reach */}
                <div className='card p-3'>
                    <h5>Expand your global reach</h5>
                    <div className='text-sm text-secondary'>Meet your customers wherever they are, in their language.</div>
                    <div className='mt-3 border-top pt-3'>
                        <button className='btn btnColorCustome btnColorOnHover' data-bs-toggle="modal" data-bs-target="#addLangModal">Add a language</button>
                    </div>
                </div>

                 {/* Published languages */}
                <div className='card p-3'>
                    <h5>Published languages</h5>
                    <div className='text-sm text-secondary'>Active in the markets they've been added to and visible to customers.</div>
                    <div className='d-flex justify-content-between border-top mt-3 pt-2'>
                        <h6>English</h6>
                        <div className='spanBg rounded-pill px-2'>default</div>
                    </div>
                    <div className='mt-2 pt-3 border-top'>
                        <button className='btn btnColorCustome btnColorOnHover'>Change default</button>
                    </div>
                    <div className='mt-3 text-sm text-secondary'>
                        Prompt customers in supported regions to shop in their local language using the <a href="#">ecomBuilder geolocation app</a>.
                    </div>
                </div>

                <div className='border-top text-center py-3'>
                    Learn more about <a href="#">languages</a>. To change your account language, <a href="#">manage account</a>.
                </div>
            </div>
        </React.Fragment>
    )
}

export default SettingLanguage