
import React from 'react'
import { useNavigate } from 'react-router-dom'
import EditorConvertToJSON from '../../components/EditorConvertToJSON/EditorConvertToJSON';
import { ScheduleOnlineDate } from '../../components/ScheduleOnlineDate/ScheduleOnlineDate';

const CollectionHomePage = () => {
    let navigate = useNavigate();

    return (

        <React.Fragment>
            <div className='content-wrapper topSpace'>
                <div className="container-fluid">
                    <div className='container'>

                        {/* for going back btn */}
                        <div className="row justify-content-center">
                            <div className="col-md-11 pt-2">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex'>
                                        <img className='img-fluid' data-bs-toggle="modal" data-bs-target="#previousBtn" src="/images/previous.png" onClick={() => { navigate(-1) }} />
                                        <span className='ml-2 fw-normal fs-5'>Home page</span>
                                    </div>
                                    <span className='fw-normal' type="" >
                                        <a href="#" className='text-secondary'>View</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* for giving border */}
                        <div className='row justify-content-center'>
                            <div className='col-md-11'>
                                <div className='myBorder pt-3'></div>
                            </div>
                        </div>

                        <div className='row justify-content-center py-2'>
                            <div className='col-md-11'>
                                <div className='row'>
                                    {/* content in 8 col */}
                                    <div className='py-2 col-md-8'>
                                        {/* for collection home page  -> title */}
                                        <div className='card p-3'>
                                            <div className="">
                                                <label htmlFor="tname" className="form-label fw-normal">Title</label>
                                                <input type="text" className="form-control" id="tname" placeholder="Short sleeve t-shirt" />
                                            </div>
                                            {/******text editor */}

                                            <div className='mt-3'>
                                                <EditorConvertToJSON />
                                            </div>
                                        </div>

                                        {/* for collection home page-> Product */}
                                        <div className='card p-3'>
                                            <div className="">
                                                <label htmlFor="pricing" className="form-label fw-normal">Add products</label>

                                                <div className=''>
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <div className="input-group-text bg-white rounded-start "><i className="fa fa-search text-secondary" aria-hidden="true" />
                                                            </div>
                                                        </span>

                                                        <input className="form-control border-left-0 rounded-end border" type="search" id="pricing" placeholder='Search Products' />

                                                        <button className="btn border rounded ml-3" type="button" id="button-addon2" data-bs-toggle="modal" data-bs-target="#CollectionBrowseModal">Browse</button>

                                                        <div className='pl-3 col-md-5'>
                                                            <select className="form-select shadow-none" aria-label="Default select example">
                                                                <option selected>Best selling</option>
                                                                <option value={1}>Product title A-Z</option>
                                                                <option value={2}>Product title Z-A</option>
                                                                <option value={3}>Highest price</option>
                                                                <option value={4}>Lowest</option>
                                                                <option value={5}>Oldest</option>
                                                                <option value={6}>Manually</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div className='text-center pt-4'>
                                                    <div className='py-3'>
                                                        <i class="fa fa-tag fs-1 text-secondary" aria-hidden="true"></i>
                                                    </div>
                                                    <p className='text-sm text-secondary'>There are no products in this collection. <br/>
                                                       Search or browse to add products.
                                                    </p>
                                                   
                                                </div>


                                            </div>
                                        </div>

                                        {/* Browse Modal */}
                                        <div>
                                            <div className="modal fade" id="CollectionBrowseModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="exampleModalLabel">Add products</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                        <div className="modal-body">
                                                            {/* --Search-- */}
                                                            <div className="input-group">
                                                                <span className="input-group-append">
                                                                    <div className="input-group-text bg-white rounded-start ">
                                                                        <i className="fa fa-search text-secondary" aria-hidden="true" />
                                                                    </div>
                                                                </span>
                                                                <input className="form-control border-left-0 rounded-end border" type="search" id="pricing" placeholder='Search Products' />
                                                            </div>



                                                            {/* Products list */}
                                                            <div className=' mt-3'>
                                                                <p className='text-secondary'>
                                                                    Your store doesn't have any products yet.
                                                                </p>
                                                            </div>

                                                        </div>

                                                        <div className="modal-footer justify-content-end">
                                                            <button type="button" className="btn btn-light" disabled>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* .... collection-Manage Modal .... */}
                                        <div className="modal fade" id="manageCollection" tabIndex={-1} role="dialog" aria-labelledby="ModalCollectionTitle" aria-hidden="false">
                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <p className="modal-title fs-5" id="ModalCollectionTitle">Sales channels and apps</p>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">x</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        
                                                    <div className="form-check">
                                                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                            Online Store
                                                        </label>
                                                    </div>

                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                                        <button type="button" className="btn btnColorCustome btnColorOnHover" data-dismiss="modal">Done</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* --- Schedule availability modal --- */}
                                        <div className="modal fade" id="schAvaModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">Schedule Online Store availability</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                    </div>
                                                    <div className="modal-body">

                                                        <div className='mySchAvaModal-min-H'>
                                                            <p>Product will be available on</p>
                                                            <ScheduleOnlineDate/>
                                                        </div>
                                                
                                                    </div>

                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancle</button>
                                                        <button type="button" className="btn btnColorCustome btnColorOnHover">Schedule availability</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            
                                        {/* Search engine listing */}
                                        <div className='card p-3'>
                                            <div className="add-prod">
                                                <div className='d-flex justify-content-between'>
                                                    <p className='fw-normal fs-5'>Search engine listing</p>
                                                    <button type="button" className="btn btn-sm btnColorCustome btnColorOnHover m-2"
                                                        data-bs-toggle="collapse" data-bs-target="#clickOnCheckURL" aria-expanded="true" aria-controls="clickOnCheckURL">Edit</button>

                                                </div>
                                                <p className='text-sm text-secondary'>Add a description to see how this collection might appear in a search engine listing</p>
                                                <div className="add-SEO">
                                                    <div className='collapseOnCheck collapse' id='clickOnCheckURL'>
                                                        <div>
                                                            <label htmlFor="pTitle" className="form-label fw-normal">Page title</label>
                                                            <input type="text" className="form-control" id="pTitle" />
                                                        </div>
                                                        <p className='text-secondary pt-1'>0 of 70 characters used</p>
                                                        <div>
                                                            <label htmlFor="metaDis" className='fw-normal'>Meta description</label>
                                                            <textarea className="form-control" id="metaDis" rows={4} style={{ resize: "none" }} />
                                                        </div>
                                                        <p className='text-secondary pt-1'>0 of 320 characters used</p>
                                                        <div className='forURL'>
                                                            <label htmlFor="url" className="form-label fw-normal">URL handle</label>
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="addon">https://example.com/users/</span>
                                                                <input type="text" className="form-control" id="url" aria-describedby="addon" />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    {/* content in 4 col */}
                                    <div className='py-2 col-md-4'>

                                        {/* -- collection availability -- */}
                                        <div className='card p-3'>
                                            <div className='py-3'>
                                                <label htmlFor="" className='fw-normal d-flex justify-content-between align-items-center'>
                                                    <span className='fs-5'>Collection availability</span>
                                                    <a href='#' data-toggle="modal" data-target="#manageCollection">Manage</a>
                                                </label>
                                            </div>

                                            <div className='collOnlineStore'>
                                                <div className='d-flex align-items-center'>
                                                    <span className='forDot mr-2'></span>
                                                    <span>Online Store</span>
                                                </div>
                                                <div className='online-sch-ava'>
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#schAvaModal">Schedule availability</a>
                                                </div>

                                                  {/* collection alert message */}
                                                <div className='pt-2'>
                                                    <div className="alert alert-dark alert-dismissible fade show text-xs " role="alert">
                                                        <div className='d-flex justify-content-between'>
                                                            <span>
                                                              <i className="fa fa-info-circle mr-2 fs-5" aria-hidden="true">
                                                              </i>
                                                            </span>
                                                            <span className=''>To add this collection to your online store's navigation, you need to  <a href='#'>update your menu</a>
                                                            </span>
                                                            <span>
                                                                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close">
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {/* collection images */}
                                        <div className='card p-3'>
                                            <label className='fw-normal' htmlFor="files">
                                                <div className="add-image">
                                                    <p className='fw-normal fs-5'>Collection image</p>
                                                    <div className="add-image-option d-flex flex-column justify-content-center align-items-center">
                                                        <div>
                                                            <input type="file" className='d-none' id='files' />
                                                            <span className='mx-2 py-1 px-2 rounded btnColorCustome btnColorOnHover'>Add images</span>
                                                        </div>
                                                        <p className='text-sm text-secondary py-2'>or drop an image to upload</p>
                                                    </div>

                                                </div>
                                            </label>
                                        </div>

                                        {/* Online store */}
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-normal fs-5'>Online Store</p>
                                                <label htmlFor="theme" className='fw-normal'>
                                                    Theme template
                                                </label>
                                                <input type="number" className='form-control' id='theme' placeholder='0' min={0} />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                             {/* for giving border */}
                        <div className='row justify-content-center'>
                            <div className='col-md-11'>
                                <div className='myBorder'></div>
                            </div>
                        </div>

                           {/* for delete collection and save */}
                        <div className='row justify-content-center pt-3 pb-5'>
                            <div className='d-flex col-md-11 justify-content-between'>
                                <button type="button" className="btn btn-outline-danger">Delete collection</button>
                                <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button> 
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>

    )
}

export default CollectionHomePage;