
import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import ShippingDeliveryModal from '../SettingCompModal/ShippingDeliveryModal'

const LocalDelivery =()=>{
     let navigate = useNavigate()

    return(
        <React.Fragment>
            <ShippingDeliveryModal/>
            <div className='pl-md-3 mt-3 mt-md-0'>
                <div className='d-flex'>
                    <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                    <span className='ml-2 fw-normal fs-5'>Local delivery for Shop location</span>
                </div> 
                <div className='border-top my-4'></div>
                     {/* Location status */}
                <div className='card'>
                    <div className='p-3'>
                        <h6>Location status</h6>
                        <div className="d-flex align-items-center py-3">
                            <div>
                                <i className="fa fa-map-marker fs-4 border rounded bg-white px-3 py-2"></i>
                            </div>
                            <div className="px-3">
                                <span className="text-dark">Shop location</span><br />
                                <span className="text-secondary">india</span>
                            </div>
                        </div>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="localLocCheck" 
                            
                            data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="offerLocDelicverycollapse "/>
                            <label className="form-check-label" htmlFor="localLocCheck">
                                This location offers local delivery
                            </label>
                        </div>
                    </div>

                    <div className='border-top bg-light rounded-bottom p-3 stopTransition multi-collapse' id='offerLocDelicverycollapse'>
                        <div>
                            Delivery prices for this location are in Indian rupees (INR). <a href="#" data-bs-toggle="modal" data-bs-target="#ChangeCurrModal">Change Currency</a>
                        </div>
                    </div>
                </div>

                {/* Delivery area */}
                <div className='card p-3 stopTransition multi-collapse' id='deliveryAreaCollapse'>
                    <h6>Delivery area</h6>
                    <div className="alert alert-primary d-flex mt-3" role="alert">
                        <div className='mr-3 mt-1'>
                            <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                        </div>
                        <div>
                           Your location address couldn't be verified. Use PIN codes to set your delivery area, or review your address. <a href="#" className='text-dark'> Review location address.</a>
                        </div>
                    </div>
                    <p>Define your delivery area with one of these methods</p>
                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" name='deliveryArea' type="radio" id="pinDeliveryAreaRadio" />
                        <label className="form-check-label" htmlFor="pinDeliveryAreaRadio">
                        Use PIN codes
                        </label>
                        <p className='text-sm text-secondary'>Choose specific areas that you deliver to.</p>
                    </div>
                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" name='deliveryArea' type="radio" id="radiusDeliveryAreaRadio" />
                        <label className="form-check-label" htmlFor="radiusDeliveryAreaRadio">
                        Set a delivery radius
                        </label>
                        <p className='text-sm text-secondary'>Set a distance around your location that you deliver to.</p>
                    </div>

                    {/* DELIVERY ZONE */}
                    <div className='border-top pt-3'>
                        <div className='text-sm'>DELIVERY ZONE</div>
                        <label htmlFor="" className="form-label fw-normal pt-3">Zone name</label>
                        <div className="input-group">
                            <input className="form-control border-right-0 border" type="text" id="" />
                            <span className="input-group-append">
                                <div className="input-group-text bg-white">
                                    <span className='text-sm'>10/50</span>
                                </div>
                            </span>
                        </div>

                        <label htmlFor="" className="form-label fw-normal pt-3">PIN codes</label>
                        <input className="form-control border" type="text" id="" />
                        <div className='pt-1 text-sm text-secondary'>Enter PIN codes separated by a comma. To include a range of PIN codes, add an asterisk (*) after the characters that begin the range.</div>
                        
                        <div className='row'>
                            <div className='col-md-6'>
                                <label htmlFor="" className="form-label fw-normal pt-2">Minimum order price</label>
                                <div className="input-group">
                                    <span className="input-group-append">
                                        <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr text-secondary" /></div>
                                    </span>
                                    <input className="form-control border-left-0 border pl-0" type="text" id="" placeholder='0.00' />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <label htmlFor="" className="form-label fw-normal pt-2">Delivery price</label>
                                <div className="input-group">
                                    <span className="input-group-append">
                                        <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr text-secondary" /></div>
                                    </span>

                                    <input className="form-control border-right-0 border-left-0 border pl-0" type="text" id="" placeholder='0.00' />
                                    <span className="input-group-append">
                                        <div className="input-group-text bg-white">
                                            <span className='spanBg rounded-pill px-2'>free</span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                             
                             {/* add-remove conditional pricing */}
                        <div className='mt-3'>
                            <a href="#" data-bs-toggle="collapse" data-bs-target="#conPricingCollapse" aria-expanded="false" aria-controls="conPricingCollapse">Add conditional pricing</a>
                        </div>
                        <div className='row collapse' id='conPricingCollapse'>
                            <div className='col-md-6 pt-2'>
                                <label htmlFor="" className="form-label fw-normal">Orders up to</label>
                                <div className="input-group">
                                    <span className="input-group-append">
                                        <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr text-secondary" /></div>
                                    </span>
                                    <input className="form-control border-left-0 border pl-0" type="text" id="" placeholder='No limit' />
                                </div>
                            </div>
                            <div className='col-md-6 pt-2'>
                                <label htmlFor="" className="form-label fw-normal">Delivery price</label>
                                <div className="input-group">
                                    <span className="input-group-append">
                                        <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr text-secondary" /></div>
                                    </span>

                                    <input className="form-control border-right-0 border-left-0 border pl-0" type="text" id="" placeholder='0.00' />
                                    <span className="input-group-append">
                                        <div className="input-group-text bg-white">
                                            <span className='spanBg rounded-pill px-2'>free</span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                             {/* Delivery information textarea box */}
                        <div className="pt-3">
                            <div className='d-flex justify-content-between'>
                            <label htmlFor="" className="form-label fw-normal">Delivery information</label>
                            <span>0/300</span>
                            </div>
                            <textarea className="form-control border" id="" rows={4} style={{resize:"none"}} />
                        </div>
                        <div className='pt-2 text-sm text-secondary'>
                             This message will appear at checkout and in the <span><a href="#">order confirmation notification</a></span>.
                        </div>
                        <div className='border-top mt-4 pt-3'>
                            <a href="#"><i className="fa fa-plus-circle mr-1"></i>Create new delivery zone</a> 
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default LocalDelivery