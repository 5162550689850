
import React from "react";
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom";

const Subscription = ()=>{
    let navigate = useNavigate();

    return(
        <React.Fragment>
            <div className='pl-md-3' >

               {/* for going back btn */}         
                <div className='d-flex'>
                    <img className='img-fluid d-none d-sm-block' alt="back_logo" src="/images/previous.png" onClick={() => { navigate(-1) }} />
                    <span className='ml-2 fw-normal fs-5'>Active Subscriptions</span>
                </div>   
                <div className='border-top my-4'></div>

                 {/*ecom Subscriptions */}
                <div className='card p-3'>
                    <div className='pt-2'>
                        <h6 className=''> Ecom-builder Subscriptions</h6>
                    </div>
                    <div className='border-top my-2'></div>

                    <div className='d-flex justify-content-between align-items-center pt-2'>
                        <div className="d-flex align-items-center">
                            <div className="border rounded mr-2">
                                <span><i className="fa fa-shopping-bag p-2 fs-5 text-success" aria-hidden="true"></i></span>
                            </div>
                            <div className='text-break'>
                                <span>Trial</span><br/>
                                <span className='text-secondary text-sm'>Your trial ends on December 9, 2022</span>
                            </div>
                        </div>
                        <div>
                            <a href="/plans" className="btn btnColorCustome btnColorOnHover">Select plan</a>
                        </div>
                    </div>
                </div>
     
                {/* App subscription */}
                <div className='card p-3'>
                    <div className='pt-2'>
                        <h6 className=''> App subscriptions (0 installed)</h6>
                    </div>
                    <div className='border-top my-2'></div>
                    <div className="p-md-5 justify-content-center text-center">
                        <h5>You don't have any app subscriptions</h5>
                        <p className="text-secondary text-sm py-2">After you install an app that has recurring and/or usage charges, it will be shown here.</p>
                        <a href="#" className="btn btnColorCustome btnColorOnHover">Browse apps</a>
                    </div>
                </div>

                <div className="text-center pt-2">
                   <p>Learn more about <a href="#">active subscriptions</a></p>
                </div>
                <div className="text-secondary">
                    <p>* This price is estimated based on current exchange rates (₹1.00 INR = $0.0123 USD). The exact amount charged will vary and depends on the exchange rate when a charge is issued. This price excludes any applicable taxes and fees, which will be added to the cost of all services purchased. You will be billed in your local currency. <a href="#">Learn more</a></p>
                </div>

            </div>
        </React.Fragment>
    );
}

export default Subscription;