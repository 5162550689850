
import React from 'react'
import { useNavigate } from 'react-router-dom'

function AddBlogsDefinition() {
    let navigate = useNavigate()

  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>

            {/* for going back btn */} 
            <div className='d-flex'>
            <img className='img-fluid d-none d-sm-block' src="/images/previous.png" alt='back_logo' onClick={() => { navigate(-1) }} />
            <span className='ml-2 fw-normal fs-5'>Add blog metafield definition</span>
            </div> 
            <div className='border-top my-4'></div>
            <div className='card p-3'>
              <div>
                <div className='mb-1'>Name</div>
                <input type="text" className="form-control border"/>
              </div>

              <div className='mt-2'>
                <div className='mb-1'>Namespace and key</div>
                <input type="text" className="form-control border"/>
                <div className='my-1 text-sm text-secondary'>The system uses this to identify the definition.</div>
              </div>

              <div className='mt-2'>
                <div className='mb-1'>Description</div>
                <div className="input-group mb-3">
                <input type="text" className="form-control border"/>
                <span className="input-group-text bg-white">0/100</span>
                </div>
              </div>

              <div className="dropdown mt-3">
                <button className="btn btn-outline-light border text-info w-100 py-3" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa fa-plus-circle mr-1"></i>Select type
                </button>
                <ul className="dropdown-menu w-100 seletTypeUl" aria-labelledby="dropdownMenuButton1">
                  <li><a className="dropdown-item" href="#">Action</a></li>
                  <li><a className="dropdown-item" href="#">Another action</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </div>
            </div>

                {/* Access options */}
            <div className='card p-3'>
              <h5>Access options <a href="#" className='text-sm ml-1'>Learn more</a></h5>
              <div className='text-sm text-secondary'>Give read access to selected options.</div>
              <div className='form-check ml-1 mt-3'>
                <input className="form-check-input shadow-none" type="checkbox" id='storeFront'/>
                <label className="form-check-label" htmlFor='storeFront' >Storefronts</label>
              </div>
            </div>

            <div className='text-right'>
              <button className='btn btnColorCustome btnColorOnHover'>Save</button>
            </div>

        </div>

    </React.Fragment>
  )

}

export default AddBlogsDefinition