import React from 'react'

export default function OrderPage() {
    return (
        <div>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className="row">
                            <p className='fw-bold fs-5 mt-2 '>Orders</p>

                        </div>

                        <div className="row m-2 rounded" style={{ background: "white" }}>
                            <div className="container text-center">
                                <img alt="no-orders" className='img-fluid mt-5' src="images/no-orders.svg" />
                                <p className='fs-4'>Your orders will show here</p>
                                <p className='ml-5 mr-5 '>To get orders and accept payments from customers, you need to select a plan. You’ll only be charged for your plan after your free trial ends.</p>
                                <a href='/plans'><button type="button" class="btn bg-grenncolor fcw mb-5">Select Plan</button></a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
