import React from 'react'

function Reports() {
    return (
        <div>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className="row">

                            <div className="col-md-9 mt-3">
                                <p className='fw-bolder fs-4'>Reports</p>

                            </div>
                            <div className="col-md-3 mt-3">
                                <button type="button" class="btn btn-success  border" > Create Custom Report</button>
                            </div>
                        </div>
                        <div className='row p-4' >
                            <div class="input-group p-0">
                                <div class="input-group-prepend bg-white border-0" >
                                    <span class="input-group-text bg-white border-0" ><i class="fa fa-search" aria-hidden="true"></i></span>
                                </div>
                                <input type="text" class="form-control border-0" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-white border-0" id="basic-addon1"><i class="fa fa-refresh " aria-hidden="true"></i></span>
                                </div>
                            </div>
                            <div class="input-group p-0 bg-white border-top py-2">

                                <ul className='m-1 p-0'>
                                    <li className="nav-item dropdown">
                                        <a className="px-2 py-1 text-secondary border rounded-pill " data-toggle="dropdown" href="/">
                                            Billing
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu">
                                            <div className="form-group mb-0 mx-2">
                                                <label htmlFor="address" className='fw-normal' for="adrescopy">
                                                    <input type="checkbox" id="adrescopy" />&nbsp; Shopify
                                                </label>
                                            </div>
                                            <div className="form-group mb-0 mx-2">
                                                <label htmlFor="address" className='fw-normal' for="adrescopy">
                                                    <input type="checkbox" id="adrescopy" />&nbsp; Shopify
                                                </label>
                                            </div>
                                            <a href='#' className='px-2'>Clear</a>

                                        </div>
                                    </li>
                                </ul>
                                <ul className='m-1 p-0'>
                                    <li className="nav-item dropdown">
                                        <a className="px-2 py-1 text-secondary border rounded-pill " data-toggle="dropdown" href="/">
                                            Category
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu">
                                            <div className="form-group mb-0 mx-2">
                                                <label htmlFor="address" className='fw-normal' for="adrescopy">
                                                    <input type="checkbox" id="adrescopy" />&nbsp; Shopify
                                                </label>
                                            </div>
                                            <div className="form-group mb-0 mx-2">
                                                <label htmlFor="address" className='fw-normal' for="adrescopy">
                                                    <input type="checkbox" id="adrescopy" />&nbsp; Shopify
                                                </label>
                                            </div>
                                            <a href='#' className='px-2'>Clear</a>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <table class="table bg-white rounded shadow-sm Danger table-striped">

                                <tbody>

                                    <tr>
                                        <td >Name</td>
                                        <td>Category</td>
                                        <td>Last viewed
                                        </td>
                                        <td>Author</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td ><a href='/reportsby/Sessions by device'>Sessions by device</a></td>
                                        <td ><span className='bg-secondary px-2' style={{ borderRadius: "25px" }}>Behavior</span></td>
                                        <td>Nov 4, 2022</td>
                                        <td><img src="/images/pfdp.avif" className='rounded-circle' style={{ width: "20px;", height: "20px" }} /> Rishabh</td>
                                        <td><i class="fa fa-thumb-tack" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <td >Sessions by device</td>
                                        <td ><span className='bg-secondary px-2' style={{ borderRadius: "25px" }}>Behavior</span></td>
                                        <td>Nov 4, 2022</td>
                                        <td><img src="/images/pfdp.avif" className='rounded-circle' style={{ width: "20px;", height: "20px" }} /> Rishabh</td>
                                        <td><i class="fa fa-thumb-tack" aria-hidden="true"></i></td>
                                    </tr>
                                    <tr>
                                        <td >Sessions by device</td>
                                        <td ><span className='bg-secondary px-2' style={{ borderRadius: "25px" }}>Behavior</span></td>
                                        <td>Nov 4, 2022</td>
                                        <td><img src="/images/pfdp.avif" className='rounded-circle' style={{ width: "20px;", height: "20px" }} /> Rishabh</td>
                                        <td><i class="fa fa-thumb-tack" aria-hidden="true"></i></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports