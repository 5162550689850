
import React from 'react'
import { Link } from 'react-router-dom'
import NotificationsModal from './SettingCompModal/NotificationsModal'

const SettingNotification = () => {
  return (
    <React.Fragment>
        <NotificationsModal/>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <h5>Notifications</h5>
            <div className='border-top my-4'></div>
            <h6>Customer notifications</h6>
            <p className='text-secondary text-sm'>Email and select SMS notifications are automatically sent out to customers. Click the notification title links below to view or edit the content. See also the help documentation for <a href="#">notification variables</a>.</p>

                {/* Customize email templates */}
            <div className='card p-3'>
                <h6>Customize email templates</h6>
                <p className='text-sm'>Change the look and feel of email notifications your customers receive. Add your logo and color theme.</p>
                <div>
                    <Link to='/setting/email_setting' className='btn btn-outline-light border text-dark'>Customize email templates</Link>
                </div>
            </div>

                     {/* Orders */}
            <div className='card p-3'>
                <h6 className='pb-2'>Orders</h6>

                     {/* Order confirmation */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Order confirmation</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer after they place their order.</p>
                    </div>
                </div>

                     {/* Order edited */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Order edited</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer after their order is edited (if you select this option).</p>
                    </div>
                </div>

                     {/* Order invoice */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Order invoice</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer when the order has an outstanding balance.</p>
                    </div>
                </div>

                      {/* Order cancelled */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Order cancelled</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer if their order is cancelled (if you select this option).</p>
                    </div>
                </div>

                      {/* Order refund */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Order refund</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer if their order is refunded (if you select this option).</p>
                    </div>
                </div>

                      {/* Draft order invoice */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Draft order invoice</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer when a draft order invoice is created. You can edit this email invoice before you send it.</p>
                    </div>
                </div>

                      {/* Abandoned POS checkout */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Abandoned POS checkout</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer when you email their cart from POS. Includes a link to buy online.</p>
                    </div>
                </div>

                      {/* Abandoned checkout */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Abandoned checkout</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer if they leave checkout before they buy the items in their cart. Configure options in "<a href="#">checkout settings</a>".</p>
                    </div>
                </div>

                      {/* POS exchange receipt */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>POS exchange receipt</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer after they complete an exchange in person and want to be emailed a receipt.</p>
                    </div>
                </div>

                      {/* POS exchange V2 receipt */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>POS exchange V2 receipt</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer after they complete an exchange V2 in person and want to be emailed a receipt.</p>
                    </div>
                </div>

                      {/* Gift card created */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Gift card created</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer when you issue or fulfill a gift card.</p>
                    </div>
                </div>

                      {/* Payment error */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Payment error</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer if their payment can't be processed during checkout.</p>
                    </div>
                </div>

                      {/* Pending payment error */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Pending payment error</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer if their pending payment can't be processed after they have checked out. Learn more about <a href="#">pending payments</a>.</p>
                    </div>
                </div>

                      {/* Pending payment success */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Pending payment success</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer when their pending payment is successfully processed after they have checked out. Learn more about <a href="#">pending payments</a>.</p>
                    </div>
                </div>

                      {/* Payment reminder */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Payment reminder</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer on or after the due date for unpaid orders. <a href="#">Manage payment reminders</a>.</p>
                    </div>
                </div>

            </div>

                 {/* Shipping */}
            <div className='card p-3'>
                <h6 className='pb-2'>Shipping</h6>

                      {/* Fulfillment request*/}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Fulfillment request</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to a third-party fulfillment service provider when order items are fulfilled.</p>
                    </div>
                </div>

                      {/* Shipping confirmation */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Shipping confirmation</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer when their order is fulfilled (if you select this option).</p>
                    </div>
                </div>

                      {/* Shipping update */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Shipping update</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer if their fulfilled order's tracking number is updated (if you select this option).</p>
                    </div>
                </div>

                      {/* Out for delivery */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Out for delivery</Link>
                    </div>  
                    <div className='col-md-8'>
                        <div className="form-check">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckChecked" defaultChecked />
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Sent to the customer automatically after orders with tracking information are out for delivery.
                            </label>
                        </div>
                    </div>
                </div>

                      {/* Delivered */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Delivered</Link>
                    </div>  
                    <div className='col-md-8'>
                        <div className="form-check">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="deleveredCheck" />
                            <label className="form-check-label" htmlFor="deleveredCheck">
                                Sent to the customer automatically after orders with tracking information are delivered.
                            </label>
                        </div>
                    </div>
                </div>

            </div>

                 {/* Local delivery */}
            <div className='card p-3'>
                <h6 className='pb-2'>Local delivery</h6>

                      {/* Local order out for delivery */}
                <div className='border-top py-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Local order out for delivery</Link>
                    </div>  
                    <div className='col-md-8'>
                        <div className="form-check">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="OutForDeliver" />
                            <label className="form-check-label" htmlFor="OutForDeliver">
                                Sent to the customer when their local order is out for delivery.
                            </label>
                        </div>
                    </div>
                </div>

                      {/* Local order delivered */}
                <div className='border-top py-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Local order delivered</Link>
                    </div>  
                    <div className='col-md-8'>
                        <div className="form-check">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="Delivered" />
                            <label className="form-check-label" htmlFor="Delivered">
                                Sent to the customer when their local order is delivered.
                            </label>
                        </div>
                    </div>
                </div>

                      {/* Local order missed delivery */}
                <div className='border-top py-3  row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Local order missed delivery</Link>
                    </div>  
                    <div className='col-md-8'>
                        <div className="form-check">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="missedDelivery" />
                            <label className="form-check-label" htmlFor="missedDelivery">
                                Sent to the customer when they miss a local delivery.
                            </label>
                        </div>
                    </div>
                </div>
            </div>

                     {/* Local pickup */}
            <div className='card p-3'>
                <h6 className='pb-2'>Local pickup</h6>

                      {/* Ready for pickup */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Ready for pickup</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer manually through Point of Sale or admin. Lets the customer know their order is ready to be picked up.</p>
                    </div>
                </div>

                      {/* Picked up */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Picked up</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer when the order is marked as picked up.</p>
                    </div>
                </div>
            </div>

                        {/* Customer */}
            <div className='card p-3'>
                <h6 className='pb-2'>Customer</h6>

                      {/* Customer account invite */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Customer account invite</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer with account activation instructions. You can edit this email before you send it.</p>
                    </div>
                </div>

                      {/* Customer account welcome */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Customer account welcome</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer when they complete their account activation.</p>
                    </div>
                </div>

                      {/* Customer account password reset */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Customer account password reset</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer when they ask to reset their accounts password.</p>
                    </div>
                </div>

                      {/* B2B access email */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>B2B access email</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to a customer when they are added to a company.</p>
                    </div>
                </div>

                      {/* Contact customer */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Contact customer</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer when you contact them from the orders or customers page. You can edit this email before you send it.</p>
                    </div>
                </div>
            </div>

                {/* Email marketing */}
            <div className='card p-3'>
                <h6 className='pb-2'>Email marketing</h6>

                      {/* Customer marketing confirmation */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Customer marketing confirmation</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer automatically when they sign up for email marketing (if email double opt-in is enabled).</p>
                    </div>
                </div>
            </div>

                {/* Returns */}
            <div className='card p-3'>
                <h6 className='pb-2'>Returns</h6>

                      {/* Return label/tracking for a return */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Return label/tracking for a return</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent when you create a return with a return label or tracking information, or when you update this information on a return.</p>
                    </div>
                </div>

                      {/* Return label for an order */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Return label for an order</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent when you create a return label for an order from the "More actions" menu and is not connected to the return of specific items (available in the US only).</p>
                    </div>
                </div>

                      {/* Return request confirmation */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Return request confirmation</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent automatically to the customer after they have requested a return.</p>
                    </div>
                </div>

                      {/* Return request approved */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Return request approved</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer when you approve a return request. Return shipping information is included (if applicable).</p>
                    </div>
                </div>

                      {/* Return request declined */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>Return request declined</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to the customer with a message indicating why a return request was declined. You can edit the message to the customer before you send it.</p>
                    </div>
                </div>
            </div>
         
             {/* Marketing */}
            <div className='py-2'>
                <h5>Marketing</h5>
                <p className='text-sm text-secondary'>Manage consent and tracking options.</p>
            </div> 
  
                {/* Double opt-in */}
            <div className='card p-3'>
                <h6>Double opt-in</h6>
                <div className='pt-2 text-secondary'>Get explicit consent from customers to send them email and SMS marketing.</div>
                <a href="#">Learn more</a>
                <p className='text-bold pt-2'>Require customers to confirm their:</p>

                <div className="form-check ml-1">
                    <input className="form-check-input shadow-none" type="checkbox" defaultValue id="emailSub" />
                    <label className="form-check-label" htmlFor="emailSub">
                        Email subscription
                    </label>
                </div>
                <div className="form-check ml-1">
                    <input className="form-check-input shadow-none" type="checkbox" defaultValue id="smsSub" />
                    <label className="form-check-label" htmlFor="smsSub">
                        SMS subscription
                    </label>
                </div>

                <div className='text-sm text-secondary mt-3'>Customers who sign up will receive a confirmation message to validate their subscription. Previous subscribers will not be affected.
                </div>
            </div>

                {/* EcomBuilder Email open tracking */}
            <div className='card p-3'>
                <h6>EcomBuilder Email open tracking</h6>
                <p className='text-sm text-secondary'>Open tracking allows you to see how many emails are opened.</p>
                <div>
                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="optimizeTracking" />
                        <label className="form-check-label" htmlFor="optimizeTracking">
                            Optimize open tracking (recommended)
                        </label>
                        <p className='text-sm text-secondary'>Choose this option to balance tracking email open rates with maintaining your sender reputation.</p>
                    </div>

                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="consent" />
                        <label className="form-check-label" htmlFor="consent">
                            Ask for consent
                        </label>
                        <p className='text-sm text-secondary'>By default, email opens will not be tracked. Subscribers will be able to opt-in to tracking through the footer of your emails. Your open rate will be reported based on subscribers who opt-in, combined with overall engagement.</p>
                    </div>

                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="doNotTrack" />
                        <label className="form-check-label" htmlFor="doNotTrack">
                            Do not track
                        </label>
                        <p className='text-sm text-secondary'>Your email open rate will not be reported. You will still be able to see other metrics, such as the number of clicks from subscribers in your emails.</p>
                    </div>

                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="trackAll" />
                        <label className="form-check-label" htmlFor="trackAll">
                            Tracks all email opens
                        </label>
                        <p className='text-sm text-secondary'>See how many subscribers open your emails. This will provide the most accurate reporting into open behavior.</p>
                    </div>
                </div>
            </div>

                {/* Staff order notifications */}
            <div className='pt-2'>
                <h5>Staff order notifications</h5>
                <p className='text-sm text-secondary'>Choose who receives <a href="#">order notifications</a>. If you choose to receive email notifications you will receive both new order and return request notifications. You can also subscribe to the <a href="#">RSS feed</a> to see new orders for this store.</p>
                <button className='btn btnColorCustome btnColorOnHover' data-bs-toggle="modal" data-bs-target="#addRecipientModal">Add recipient</button>
            </div>

                   {/* Returns */}
            <div className='card p-3 mt-4'>
                <h6 className='pb-2'>Templates</h6>

                      {/* New order */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>New order</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to order notification subscribers when a customer places an order.</p>
                    </div>
                </div>

                      {/* New return request */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>New return request</Link>
                    </div>
                    <div className='col-md-8'>
                        <p>Sent to order notification subscribers when a customer requests a return.</p>
                    </div>
                </div>
            </div>

            <div className='card p-3'>
                <div className='text-center text-secondary'>You haven't created any order notifications yet</div>
            </div>

                {/* Draft orders */}
            <div className='card p-3'>
                <h6 className='pb-2'>Draft orders</h6>

                    {/* New draft order */}
                <div className='border-top pt-3 row text-sm mx-0'>
                    <div className='col-md-4'>
                        <Link to='#'>New draft order</Link>
                    </div>
                    <div className='col-md-8'>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" id="newDraft" />
                            <label className="form-check-label" htmlFor="newDraft">
                                Sent to store owner when a customer submits an order for review.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            
                {/* Webhooks */}
            <div className='pt-2'>
                <h6>Webhooks</h6>
                <p className='text-secondary text-sm'>You can subscribe to events for your products and orders by creating web hooks that will push XML or JSON notifications to a given URL .</p>
                <button className='btn btnColorCustome btnColorOnHover' data-bs-toggle="modal" data-bs-target="#addWebhookModal">Create webhook</button>
            </div>

            <div className='card p-3 mt-4 text-center text-secondary'>
                You haven't created any webhooks yet
            </div>
         
        </div>
    </React.Fragment>
  )
}

export default SettingNotification