

import React from 'react'
import { useState } from 'react'


const SettingStoreDetails = () => {
  const[currency,setCurrency]=useState("Rs. {{amount}}");
function inputforstoreCurrency(e)
  {
    setCurrency(e.target.value);

  }

  return (
     <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0' >
            <h5>Store details</h5>
            <div className='border-top my-4'></div>

              {/* -- Basic information -- */}
            <div className='card p-3'>
              <div className="storeBasicInfo">
                <div className='d-flex justify-content-between'>
                  <p className='fw-normal fs-5'>Basic information</p>
                  <button type="button" className="btn btn-sm btnColorCustome btnColorOnHover m-2"
                    data-bs-toggle="collapse" data-bs-target="#clickOnBasicInfo" aria-expanded="true" aria-controls="clickOnBasicInfo">Edit</button>
                </div>
              
                <div className="basic-info">
                <div className='myBorder pt-2'></div>
                <div className='row mt-3'>
                    <div className='col-6 d-flex align-items-center'>
                        <div className=''>
                          <i className="fas fa-store-alt mr-2 rounded-circle bg-light p-3 fs-5"/> 
                        </div>
                        <div>
                           <span>mystore-name</span>
                        </div>
                    </div>
                    <div className='col-6 d-flex align-items-center'>
                        <div>
                            <span><i className="fa fa-industry mr-2 rounded-circle bg-light p-3 fs-5" aria-hidden="true"></i></span>
                        </div>
                        <div>
                            <span className='text-secondary'>Industry</span><br/>
                            <span>Other</span>
                        </div>
                    </div>
                </div>

                  <div className='collapseOnBasicInfo collapse' id='clickOnBasicInfo'>
                  
                    <div>
                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Store name</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" />
                      </div>

                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Legal name of company</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" />
                      </div>

                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Store industry</label>
                        <select className="form-control" aria-label=".form-select-lg example"    id="exampleFormControlInput1">
                          <option>Other</option>
                          <option value={1}>Beauty</option>
                          <option value={2}>Clothing</option>
                          <option value={3}>Electronics</option>
                          <option value={4}>Furniture</option>
                          <option value={5}>Handcrafts</option>
                          <option value={6}>Jewelry</option>
                          <option value={7}>Painting</option>
                          <option value={8}>Photography</option>
                          <option value={9}>Restaurants</option>
                          <option value={10}>Groceries</option>
                          <option value={11}>Other food & drink</option>
                          <option value={12}>Sports</option>
                          <option value={13}>Toys</option>
                          <option value={14}>Services</option>
                          <option value={15}>Virtual services</option>
                          <option value={16}>Sports</option>
                          <option value={17}>I have not decided yet</option>
                        </select>
                      </div>
                   
                    </div>

                  </div>
                </div>
              </div>
            </div>

              {/* -- Address -- */}
            <div className='card p-3'>
              <div className="storeAddress">
                <div className='d-flex justify-content-between'>
                  <p className='fw-normal fs-5'>Address</p>
                  <button type="button" className="btn btn-sm btnColorCustome btnColorOnHover m-2"
                    data-bs-toggle="collapse" data-bs-target="#clickOnAddress" aria-expanded="true" aria-controls="clickOnAddress">Edit</button>
                </div>
              
                <div className="address">
                <p className='text-sm text-secondary'>Used on customer order confirmations and your Ecom builder bill.</p>
                <div className='myBorder'></div>
                    <div className='d-flex align-items-center pt-2'>
                        <div className=''>
                          <i className="fa fa-map-marker mr-2 rounded-circle bg-light px-3 py-2 fs-2"/> 
                        </div>
                        <div>
                           <span>India</span>
                        </div>
                    </div>
                  
                  <div className='collapseOnAddress collapse' id='clickOnAddress'>
                    
                    <div>
                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Address</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder='Address' />
                      </div>

                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Apartment, suite, etc.</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder='Apartment, suite, etc.'/>
                      </div>

                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Country/region</label>
                        <select className="form-control" aria-label=".form-select-lg example"    id="exampleFormControlInput1">
                          <option value={1}>India</option>
                          <option value={2}>Nepal</option>
                          <option value={3}>Bangladesh</option>
                          <option value={4}>Pakistan</option>
                        </select>
                      </div>
                      <div className='row pt-3'>
                     
                         <div className='col-md-4'>
                            <div className="">
                              <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">City</label>
                              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder='City' />
                            </div>
                         </div>
                         <div className='col-md-4'>
                            <div className="">
                              <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">State</label>

                                 <select className="form-control" aria-label=".form-select-lg example"    id="exampleFormControlInput1">
                                    <option value={1}>Bihar</option>
                                    <option value={2}>Jharkhand</option>
                                    <option value={3}>Delhi</option>
                                    <option value={4}>Punjab</option>
                                 </select>
                            </div>
                         </div>

                         <div className='col-md-4'>
                            <div className="">
                              <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">PIN code</label>
                              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder='PIN code' />
                            </div>
                         </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

              {/* -- Contact information -- */}
            <div className='card p-3'>
              <div className="storeContact">
                <div className='d-flex justify-content-between'>
                  <p className='fw-normal fs-5'>Contact information</p>
                  <button type="button" className="btn btn-sm btnColorCustome btnColorOnHover m-2"
                    data-bs-toggle="collapse" data-bs-target="#clickOnContactInfo" aria-expanded="true" aria-controls="clickOnContactInfo">Edit</button>
                </div>
              
                <div className="Contact-info">
                  <div className='myBorder pt-2'></div>
                  <div className='row'>
                    <div className='col-md-6 d-flex align-items-center pt-3'>
                        <div className=''>
                          <i className="fa fa-phone mr-2 rounded-circle bg-light p-3 fs-5"/> 
                        </div>
                        <div>
                           <span></span>
                        </div>
                    </div>
                    <div className='col-md-6 d-flex align-items-center pt-3'>
                        <div>
                            <span><i className="fa fa-envelope mr-2 rounded-circle bg-light p-3 fs-5" aria-hidden="true"></i></span>
                        </div>
                        <div className='text-break'>
                            <span>upendra.cityinnovates@gmail.com</span><br/>
                            <span className='text-secondary'>Ecom builder uses this to contact you</span>
                        </div>
                    </div>

                    <div className='col-md-6 d-flex align-items-center pt-3'>
                        <div>
                            <span><i className="fa fa-envelope mr-2 rounded-circle bg-light p-3 fs-5" aria-hidden="true"></i></span>
                        </div>
                        <div className='text-break'>
                            <span>upendra.cityinnovates@gmail.com</span><br/>
                            <span className='text-secondary'>Customers see this if you email them</span>
                        </div>
                    </div>
                    
                  </div>

                  <div className='collapseOnContactInfo collapse' id='clickOnContactInfo'>
                    <div>
                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Phone</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" />
                      </div>

                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Store contact email</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" />
                        <div className='text-secondary pt-1'>Ecom builder uses this to contact you</div>
                      </div>

                      <div className="pt-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Sender email</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" />
                        <div className='text-secondary pt-1'>Customers see this if you email them</div>
                      </div>
                    </div>
                    <div className="alert alert-primary d-flex align-items-center mt-4" role="alert">
                      <div className='mr-3'>
                          <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                      </div>
                      <div>
                          Emails you send may show up differently in some customers' inboxes. <a href="#" className='text-dark'>View email settings</a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

              {/* -- Store currency -- */}
            <div className='card p-3'>
              <div className="storeCurrency">
                <div className=''>
                  <h5 className='fw-normal'>Store currency</h5>
                  <p className='text-secondary text-sm'>The currency your products are sold in. For your payout currency, go to <a href="#">payments settings</a>.</p>
                </div>
              
                <div className="Store-currency">
                  <div className='myBorder pt-2'></div>
                    <div className='pt-4'>
                        <div className='d-flex justify-content-between'>
                          <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Store currency</label>
                          
                          <a href='#' className=""
                          data-bs-toggle="collapse" data-bs-target="#clickOnStoreCurrency" aria-expanded="true" aria-controls="clickOnStoreCurrency">Change formatting
                          </a>
                        </div>
                      
                        <select className="form-control" aria-label=".form-select-lg example"    id="exampleFormControlInput1">
                          <option value={1}>Indian rupees(INR)</option>
                          <option value={1}>Indian rupees(INR)</option>
                          <option value={1}>Indian rupees(INR)</option>
                          <option value={1}>Indian rupees(INR)</option>
                          <option value={1}>Indian rupees(INR)</option>
                          <option value={1}>Indian rupees(INR)</option>
                        </select>
                    </div>

                  <div className='collapseOnStoreCurrency collapse' id='clickOnStoreCurrency'>
                    <div>
                      <div className='pt-4'>
                          <h6 className='fw-normal'>CURRENCY FORMATTING</h6>
                          <p className='text-secondary text-sm'>Change how currencies are displayed on your store. {"{{amount}}"} and {"{{amount_no_decimals}}"} will be replaced with the price of your product.</p>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 pt-3'>
                           <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">HTML with currency</label>
                           <input onChange={inputforstoreCurrency} type="text" className="form-control" id="exampleFormControlInput1" value={currency}/>
                        </div>

                        <div className='col-md-6 pt-3'>
                           <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">HTML without currency</label>
                           <input onChange={inputforstoreCurrency} type="text" className="form-control" id="exampleFormControlInput1" value={currency}/>
                        </div>

                        <div className='col-md-6 pt-3'>
                           <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Email with currency</label>
                           <input onChange={inputforstoreCurrency} type="text" className="form-control" id="exampleFormControlInput1" value={currency}/>
                        </div>

                        <div className='col-md-6 pt-3'>
                           <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Email without currency</label>
                           <input onChange={inputforstoreCurrency} type="text" className="form-control" id="exampleFormControlInput1" value={currency}/>
                        </div>

                      </div>

                    </div>
                 
                  </div>

                </div>
              </div>

            </div>

            {/* -- Standards and formats -- */}
            <div className='card p-3'>
              <div className="storeStanFormats">
                <div className=''>
                  <h5 className='fw-normal'>Standards and formats</h5>
                  <p className='text-secondary text-sm'>Used to calculate product prices, shipping weights, and order times.</p>
                </div>

                <div className="Stan-format">
                  <div className='myBorder pt-2'></div>
                      <div className="row pt-4">
                          <div className="col">
                            <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Time zone</label>
                            <select className="form-control" aria-label=".form-select-lg example"    id="exampleFormControlInput1">
                              <option value={1}>(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                              <option value={1}>(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                              <option value={1}>(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                              <option value={1}>(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                              <option value={1}>(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                           </select>
                          </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6 pt-3'>
                          <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Unit system</label>
                          <select className="form-control" aria-label=".form-select-lg example"    id="exampleFormControlInput1">
                              <option value={1}>Metric system</option>
                              <option value={1}>Imperial system</option>
                           </select>
                        </div>

                        <div className='col-md-6 pt-3'>
                          <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Default weight unit</label>
                          <select className="form-control" aria-label=".form-select-lg example"    id="exampleFormControlInput1">
                              <option value={1}>Kilogram (kg)</option>
                              <option value={1}>Gram(g)</option>
                           </select>
                        </div>
                      </div>
              
                  <div className='myBorder my-4'></div>
                    <div className=''>
                      <h6 className='fw-normal'>EDIT ORDER ID FORMAT (OPTIONAL)</h6>
                      <p className='text-secondary text-sm'>Order numbers start at #1001 by default. While you can't change the order number itself, you can add a prefix or suffix to create IDs like "EN1001" or "1001-A."</p>
                    </div>

                  <div className='row pt-2'>
                    <div className='col-md-6'>
                      <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Prefix</label>
                      <input type='text' className="form-control"id="exampleFormControlInput1"> 
                      </input>
                    </div>

                    <div className='col-md-6'>
                      <label htmlFor="exampleFormControlInput1" className="form-label fw-normal">Suffix</label>
                      <input type='text' className="form-control"id="exampleFormControlInput1"> 
                      </input>
                    </div>
                  </div>
                  <div className='py-3 text-secondary text-sm'>Your order ID will appear as #1001, #1002, #1003 ...</div>

                </div>

              </div>

            </div>

        </div>
     </React.Fragment>
  )
}

export default SettingStoreDetails