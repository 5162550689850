
import React from 'react'
import { useNavigate } from 'react-router-dom'

const AddStaff = () => {
    let navigate = useNavigate();
  return (
      <React.Fragment>
         <div className='pl-md-3'>

               {/* for going back btn */}   
            <div className='d-flex justify-content-between align-items-center'>
               <div className='d-flex'>
                  <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                  <span className='ml-2 fw-normal fs-5'>Add staff</span>
               </div>
               <div>
                    <button className='btn btnColorCustome btnColorOnHover' type='button' disabled>Send invite</button>
               </div>  
            </div>

            <div className='border-top my-4'></div>

            <div>
               <h6>Staff</h6>
               <p className='text-sm text-secondary'>Give staff access to your store by sending them an invitation. If you're working with a designer, developer, or marketer, find out how to <a href="#">give collaborator access to your store</a>.
               </p>
            </div>

            {/* add new staff form */}
            <div className='card p-3'>
               <div className='row'>
                  <div className='col-md-6'>
                      <label htmlFor="" className='fw-normal'>First Name</label>
                      <input type="text" className='form-control' />
                  </div>
                  <div className='col-md-6'>
                      <label htmlFor="" className='fw-normal'>First Name</label>
                      <input type="text" className='form-control' />
                  </div>
               </div>
               <div className='pt-2'>
                  <p className='text-secondary text-sm'>Enter the staff member's first and last name as they appear on their government-issued ID.</p>
               </div>
               <div>
                  <label htmlFor="" className='fw-normal'>Email</label>
                  <input type="email" className='form-control' />
               </div>

               <div className='border-top my-4'></div>

               <div className='d-flex justify-content-between align-items-center'>
                  <p className='text-sm text-secondary'>This staff will have no permissions in this store.</p>
                  <button className='btn btn-outline-light border text-dark text-nowrap'>
                     <span><i className="fa fa-check-square mr-1 text-secondary" aria-hidden="true"></i></span>
                     <span className=''>Select all</span>
                  </button>
               </div>

               {/* all checkbox list */}
               <div className='row'>
                  <div className='col-md-6 pt-2'>
                        <div className='py-2'>
                           <b>General</b>
                        </div>
                        <ul className='pl-1 pt-2'>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Home
                                 </label>
                              </div>
                           </li>

                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Orders
                                 </label>
                              </div>
                                 {/* ul inside li for ORDERS */}
                              <ul className='pl-3 py-1 text-secondary'>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Edit orders
                                       </label>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Refund orders
                                       </label>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Charge vaulted card
                                       </label>
                                    </div>
                                 </li>
                              </ul>
                           </li>

                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Draft orders
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Products
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Gift cards
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Customers
                                 </label>
                              </div> 
                               {/* ul inside li for CUSTOMERS */}
                              <ul className='pl-3 py-1 text-secondary'>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Erase customer personal data
                                       </label>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Request customer data
                                       </label>
                                    </div>
                                 </li>
                              </ul>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Reports
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Dashboards
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Marketing
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Discounts
                                 </label>
                              </div>
                           </li>
                           {/* oline store */}
                           <div className='py-3'>
                              <b>Online store</b>
                           </div>

                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Themes
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Blog posts and pages
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Navigation
                                 </label>
                              </div>
                           </li>
                        </ul>
                  </div>  

                  <div className='col-md-6 pt-2'>
                        <div className='py-2'>
                           <b>Administration</b>
                        </div>
                        <ul className='pl-1 pt-2'>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Manage settings
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Locations
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Domains
                                 </label>
                              </div>
                                {/* ul inside li for DOMAINS */}
                                <ul className='pl-3 py-1 text-secondary'>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                          Transfer domain to another ecom builder store
                                       </label>
                                    </div>
                                 </li>
                                </ul>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                   Edit permissions
                                 </label>
                              </div>
                               {/* ul inside li for EDIT PERMISSION */}
                              <ul className='pl-3 py-1 text-secondary'>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Add and remove staff
                                       </label>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Manage collaborator requests
                                       </label>
                                    </div>
                                 </li>
                              </ul>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 External login services
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Revoke access tokens for other staff
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 View apps developed by staff and collaborators
                                 </label>
                              </div>
                                {/* ul inside li for APPS developed */}
                                <ul className='pl-3 py-1 text-secondary'>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Develop apps
                                       </label>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Enable app development
                                       </label>
                                    </div>
                                 </li>
                              </ul>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 View customer events
                                 </label>
                              </div>
                                {/* ul inside li for customer events */}
                                <ul className='pl-3 py-1 text-secondary'>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Manage and add custom pixels
                                       </label>
                                    </div>
                                 </li>
                              </ul>
                           </li>
                           {/* -- Finance -- */}
                           <div className='py-3'>
                              <b>Finance</b>
                           </div>

                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 View billing and receive billing emails
                                 </label>
                              </div>
                                {/* ul inside li for billing and receive */}
                                <ul className='pl-3 py-1 text-secondary'>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Edit billing payment methods and pay invoices
                                       </label>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Manage plan
                                       </label>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="form-check">
                                       <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                       <label className="form-check-label" htmlFor="flexCheckDefault">
                                       Manage app billing
                                       </label>
                                    </div>
                                 </li>
                              </ul>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 View ecom builder Payments payouts
                                 </label>
                              </div>
                           </li>
                           <li>
                              <div className="form-check">
                                 <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                 <label className="form-check-label" htmlFor="flexCheckDefault">
                                 Manage other payment settings
                                 </label>
                              </div>
                           </li>
                       </ul>
                  </div>  
               </div>
               
               <div className='border-top mt-2'></div>

                  {/* -- Apps and Channels -- */}
               <div className='pt-3'>
                  <b>Apps and Channels</b>
               </div>

               <div>
                  <ul className='pl-1 pt-2'>
                     <li>
                        <div className="form-check">
                           <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                           <label className="form-check-label" htmlFor="flexCheckDefault">
                              Manage and install apps and channels
                           </label>
                           <p className='text-secondary'>Add, delete, and access any installed apps or channels.</p>
                        </div>
                           {/* ul inside li for install apps and channels */}
                           <ul className='pl-3 py-1 text-secondary'>
                              <li>
                                 <div className="form-check">
                                    <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Approve app charges
                                    </label>
                                 </div>
                              </li>
                           </ul>
                     </li>
                  </ul>
               </div>

               <div className='border-top mt-2'></div>
               <div className='pl-1 pt-3 pb-2'>
                  <div className="form-check">
                     <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                     <label className="form-check-label" htmlFor="flexCheckDefault">
                        <span className="px-2"><i className="fa fa-truck fs-4 text-success" aria-hidden="true"></i></span> Advanced Cash on Delivery
                     </label>
                  </div>
               </div>
               <div className='border-top mt-2'></div>
               <div className='d-flex pt-2'>
                  <span><i className="fa fa-info-circle text-info fs-5" aria-hidden="true"></i></span>
                  <p className='px-2 text-secondary'>Learn more about <a href="#">permissions</a>.</p>
               </div>

            </div>
            <div className='border-top mt-3'></div>
            
               <div className='d-flex justify-content-between align-items-center py-3'>
                  <button className='btn btn-outline-light border text-dark' type='button'>Cancel</button>
                  <button className='btn btnColorCustome btnColorOnHover' type='button' disabled>Send invite</button>
               </div>

         </div>
      </React.Fragment>
  )
}

export default AddStaff