
import React from 'react'
import { Link } from 'react-router-dom'
import DomainModal from './SettingCompModal/DomainModal'

const SettingDomains = () => {
  return (
    <React.Fragment>
        <DomainModal/>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <h5>Domains</h5>
            <div className='border-top my-4'></div>
               
                {/* Buy or connect a domain */}
            <div className='card p-3'>
                <div className='d-flex align-items-start'>
                    <div>
                        <h6>Buy or connect a domain</h6>
                        <p className='text-sm text-secondary'>Secure the perfect domain for your store that customers can trust and find easily. Buy a new domain from ecomBuilder, or connect a domain you already purchased from a third-party like Google Domains or GoDaddy.</p>
                    </div>
                    <div className='d-none d-sm-block'>
                        <img src="/images/www-logo.svg" alt="www-logo" style={{width:"5rem"}} />
                    </div>
                </div>
                <div className='d-flex justify-content-between border-top pt-3'>
                    <Link to='/setting/domains/buy_domain' className='btn btn-sm btnColorCustome btnColorOnHover mr-2'>Buy new domain</Link>
                    <Link to='/setting/domains/connect_domain' className='btn btn-sm btnColorCustome btnColorOnHover'>Connect existing domain
                    </Link>
                </div>
            </div>

                {/* search domain */}
            <div className='card p-3'>
                <div className="input-group">
                    <span className="input-group-append">
                        <div className="input-group-text bg-white rounded-start "><i className="fa fa-search text-secondary" aria-hidden="true" />
                        </div>
                    </span>
                    <input className="form-control border-left-0 rounded-end border pl-0" type="search" placeholder='Search countries and regions' />
                </div>
                <div className='mt-3'>
                    <h6>Domain</h6>
                </div>
                <div className='border rounded mt-2 bg-light py-2'>
                    <Link to="#">
                        <div className='d-flex'>
                            <div>
                                <span><i className="fa fa-globe mx-2 py-2 fs-3"></i></span>
                            </div>
                            <div className='text-break'>
                                <span className='text-dark'>mystore-17-7275.myEcomBuilder.com</span><br/>
                                <span className='text-secondary'>Primary for Online Store</span><br />
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='mt-2'>
                    <a href="#" data-bs-toggle="modal" data-bs-target="#ChangeDomainModal" className='text-sm'>Change to a new ecombuilder.com domain</a>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default SettingDomains