
import React from 'react'
import { useNavigate } from 'react-router-dom'

const BuyNewDomain = () => {
    let navigate = useNavigate()
  return (
    <React.Fragment>
      <div className='pl-md-3 mt-3 mt-md-0'>

        {/* for going back btn */}
        <div className='d-flex'>
            <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
            <span className='ml-2 fw-normal fs-5'>Buy new domain</span>
        </div>
        <div className='border-top my-4'></div>
        <div className='card p-3'>
          <h6>Domain</h6>
          <div className='mt-2'>
            <input type="text" className='form-control border' placeholder='Example: example.com'/>
          </div>
          <div className='mt-2 text-secondary'>Enter the domain you want to buy.</div>
        </div>

            {/* Learn more about domains */}
        <p className='text-center'>Learn more about <a href="#">domains</a></p>

      </div>
    </React.Fragment>
  )
}

export default BuyNewDomain