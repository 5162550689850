
import React from "react";
import { useNavigate } from 'react-router-dom'

const PayPalCheckout = ()=>{
    let navigate = useNavigate();
    return (
        <React.Fragment>
        <div className='px-md-5'>
                {/* for going back btn */}         
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt="back_logo" src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>PayPal Express Checkout</span>
            </div>   
            <div className='border-top my-4'></div>

                {/* alert message for --> PAYPAL CHECKOUT */}
            <div>
                <div className="alert alert-primary d-flex align-items-center mt-4" role="alert">
                    <div className='mr-3'>
                        <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                    </div>
                    <div>
                        PayPal Express Checkout is available only for international / cross border payments, i.e. for non-INR currency transactions.
                    </div>
                </div>
            </div>

                    {/* account status */}
            <div className='card p-3'>
                <div className="d-flex justify-content-between">
                    <div className="text-sm pr-1">
                        <span>ACCOUNT CONNECTED</span><br />
                        <span>No account connected</span>
                    </div>
                    <div className="text-sm px-2 ">
                        <span>TRANSACTION FEES</span><br />
                        <span>0%</span>
                    </div>
                    <div className="text-sm pl-1">
                        <span>STATUS</span><br />
                        <span className="bg-warning rounded-pill px-2">Inactive</span>
                    </div>
                </div>
            </div>

                    {/* About PayPal Express Checkout */}
            <div className="card p-3">
                <h6>About PayPal Express Checkout</h6>
                <p className="py-2">Receive payments in foreign currencies with PayPal. Manage payment approval in payment preferences in PayPal. <a href="#">Learn more</a>.</p>
                <p>Learn more about <a href="#">PayPal Express Checkout</a>.</p>
                <div>
                    <a href="#" className="btn btn-outline-light border text-dark">Get support from PayPal</a>
                </div>
            </div>

            <div className='border-top my-4'></div>
            <div className="text-right">
                <a href="#" className="btn btnColorCustome btnColorOnHover">Activate PayPal Express Checkout</a>
            </div>
        </div>
    </React.Fragment>

    );
}

export default PayPalCheckout;