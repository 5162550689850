
import React from 'react'
import { Link } from 'react-router-dom'

function SettingMetafield() {
  return (
    <React.Fragment>
        <div className="pl-md-3 mt-3 mt-md-0">
            <h5>Metafields</h5>
            <div className="border-top my-3"></div>
            <div className='card'>
                <div className='p-3'>
                    <h6>Metafields </h6>
                    <div className='text-secondary text-sm'>Add a custom piece of data to a specific part of your store. <a href="#">Learn more</a>
                    </div>
                </div>

                <div>
                        {/* Row Name and definition */}
                    <div className='row py-2 bg-light m-0 border-top px-2'>
                        <div className='col-6'>
                            <div>Name</div>
                        </div>
                        <div className='col-6'>
                            <div>Definitions</div>
                        </div>
                    </div>

                            {/* Product row*/}
                    <Link to='/setting/metafield/product'>
                        <div className='metafieldRow row py-3 m-0 border-top px-2 text-secondary'>
                            <div className='col-6'>
                                <div><i className="fa fa-product-hunt mr-1"/>Product</div>
                            </div>
                            <div className='col-6'>
                                <div>0</div>
                            </div>
                        </div>
                    </Link>

                          {/* Variants row */}
                    <Link to='/setting/metafield/variant'>
                        <div className='metafieldRow row py-3 m-0 border-top px-2 text-secondary'>
                            <div className='col-6'>
                                <div><i className="fa fa-file-code-o mr-1" />Variants</div>
                            </div>
                            <div className='col-6'>
                                <div>0</div>
                            </div>
                        </div>
                    </Link>

                          {/* Collections row */}
                    <Link to='/setting/metafield/collection'>
                        <div className='metafieldRow row py-3 m-0 border-top px-2 text-secondary'>
                            <div className='col-6'>
                                <div><i className="fa fa-file-code-o mr-1" />Collections</div>
                            </div>
                            <div className='col-6'>
                                <div>0</div>
                            </div>
                        </div>
                    </Link>

                          {/* Customers row */}
                    <Link to='/setting/metafield/customer'>
                        <div className='metafieldRow row py-3 m-0 border-top px-2 text-secondary'>
                            <div className='col-6'>
                                <div><i className="fa fa-file-code-o mr-1" />Customers</div>
                            </div>
                            <div className='col-6'>
                                <div>0</div>
                            </div>
                        </div>
                    </Link>

                          {/* Orders row */}
                    <Link to='/setting/metafield/order'>
                        <div className='metafieldRow row py-3 m-0 border-top px-2 text-secondary'>
                            <div className='col-6'>
                                <div><i className="fa fa-file-code-o mr-1" />Orders</div>
                            </div>
                            <div className='col-6'>
                                <div>0</div>
                            </div>
                        </div>
                    </Link>

                          {/* Pages row */}
                    <Link to='/setting/metafield/page'>
                        <div className='metafieldRow row py-3 m-0 border-top px-2 text-secondary'>
                            <div className='col-6'>
                                <div><i className="fa fa-file-code-o mr-1" />Pages</div>
                            </div>
                            <div className='col-6'>
                                <div>0</div>
                            </div>
                        </div>
                    </Link>

                          {/* Blogs row */}
                    <Link to='/setting/metafield/blog'>
                        <div className='metafieldRow row py-3 m-0 border-top px-2 text-secondary'>
                            <div className='col-6'>
                                <div><i className="fa fa-file-code-o mr-1" />Blogs</div>
                            </div>
                            <div className='col-6'>
                                <div>0</div>
                            </div>
                        </div>
                    </Link>

                          {/* Blogs posts row */}
                    <Link to='/setting/metafield/blog_post'>
                        <div className='metafieldRow row py-3 m-0 border-top px-2 text-secondary'>
                            <div className='col-6'>
                                <div><i className="fa fa-file-code-o mr-1" />Blogs posts</div>
                            </div>
                            <div className='col-6'>
                                <div>0</div>
                            </div>
                        </div>
                    </Link>

                </div>
            </div>
        </div>  


    </React.Fragment>
  )
}

export default SettingMetafield