
import React from 'react'

const SettingGiftCard = () => {
  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <h5>Gift cards</h5>
            <div className='border-top my-3'></div>

               {/* Auto-expiration option */}
            <div className="card p-3">
                <h6>Auto-expiration</h6>
                <p className='text-sm text-secondary'>Set your gift cards to expire a certain amount of time after they've been purchased.</p>
            </div>

              {/* gift card expier options */}
            <div className='card p-3'>
                <div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input shadow-none" name='gCardExpire' type="radio" id="inlineCheckbox1"/>
                        <label className="form-check-label" htmlFor="inlineCheckbox1">Gift cards never expire</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input shadow-none" name='gCardExpire' type="radio" id="inlineCheckbox2"/>
                        <label className="form-check-label" htmlFor="inlineCheckbox2">Gift cards expire</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-6 mt-3'>
                        <input type="text" className='form-control border' />
                    </div>
                    <div className='col-sm-6 mt-3'>
                        <select className="form-select shadow-none border" aria-label="Default select example">
                            <option>Years</option>
                            <option>Months</option>
                            <option>Days</option>
                        </select>
                    </div>
                </div>
                <div>
                    <p className='text-sm text-secondary mt-3'>Countries have different laws for gift card expiry dates. Check the laws for your country before changing this date.</p>
                </div>
            </div> 

                {/* Apple Wallet */}
            <div className='card p-3'>
                <h6>Apple Wallet</h6>
                <p className='text-secondary text-sm'>Give your customers a digital gift card to use online or in your retail stores. <a href="#">Learn more about Apple Wallet Passes</a>.</p>
                <div className="form-check ml-1">
                    <input className="form-check-input shadow-none" type="checkbox" id="flexCheckDefault"  data-bs-toggle="collapse" data-bs-target="#appleWalletcollapse" aria-expanded="false" aria-controls="appleWalletcollapse"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Enable Apple Wallet Passes
                    </label>
                </div>

                <div className='row mt-2 collapse' id="appleWalletcollapse">
                    <div className='col-sm-6'>
                        <p className='text-secondary text-sm'>You can customize the look and feel of your card with the following options.</p>
                        <label htmlFor="" className='fw-normal'>Apple Pass Header</label>
                        <input type="text" className='form-control border'/>
                        <div className='mt-3'>
                            <input type="color" className='btn btn-outline-light border p-1' value="#e33434"/><span className='ml-2'>Card Color</span>
                        </div>
                        <div className='mt-3'>
                            <input type="color" className='btn btn-outline-light border p-1' value="#ffffff"/><span className='ml-2'>Regular text color</span>
                        </div>
                        <div className='mt-3'>
                            <input type="color" className='btn btn-outline-light border p-1' value="#ffb4b4"/><span className='ml-2'>Secondary text color</span>
                        </div>
                        <div className='border-top mt-3 py-3'>
                            <div className="form-check ml-1">
                                <input className="form-check-input shadow-none" type="checkbox" id="customLogoCheck" data-bs-toggle="collapse" data-bs-target="#customLogoCollapse" aria-expanded="false" aria-controls="customLogoCollapse"/>
                                <label className="form-check-label" htmlFor="customLogoCheck">
                                    Use a custom logo (90px x 90px)
                                </label>
                            </div>
                            <div className='mt-3 collapse stopTransition' id='customLogoCollapse'>
                                <input type="file"  className='form-control border'/>
                            </div>
                        </div>
                        <div className='border-top py-3'>
                            <div className="form-check ml-1">
                                <input className="form-check-input shadow-none" type="checkbox" id="customBannerCheck" data-bs-toggle="collapse" data-bs-target="#customBannerCollapse" aria-expanded="false" aria-controls="customBannerCollapse"/>
                                <label className="form-check-label" htmlFor="customBannerCheck">
                                   Use a custom banner (1125px x 432px)
                                </label>
                            </div>
                            <div className='mt-3 collapse stopTransition' id='customBannerCollapse'>
                                <input type="file"  className='form-control border'/>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 mt-3 mt-sm-0 text-center'>
                       <img src="/images/mobilePhoto.png" alt="" style={{width:"12rem"}} />
                    </div>
                </div>

            </div>

            <div className='text-right'>
               <button className='btn btn-sm btnColorCustome btnColorOnHover'>Save</button>
            </div>
            
        </div>
    </React.Fragment>
  )
}

export default SettingGiftCard