
import React from 'react'
import { useNavigate} from 'react-router-dom'

const LocalPickup =()=>{
     let navigate = useNavigate()

    return(
        <React.Fragment>
            <div className='pl-md-3 mt-3 mt-md-0'>
                <div className='d-flex'>
                    <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                    <span className='ml-2 fw-normal fs-5'>Local pickup for Shop location</span>
                </div> 
                <div className='border-top my-4'></div>
                     {/* Location status */}
                <div className='card'>
                    <div className='p-3'>
                        <h6>Location status</h6>
                        <div className="d-flex align-items-center py-3">
                            <div>
                                <i className="fa fa-map-marker fs-4 border rounded bg-white px-3 py-2"></i>
                            </div>
                            <div className="px-3">
                                <span className="text-dark">Shop location</span><br />
                                <span className="text-secondary">india</span>
                            </div>
                        </div>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="localLocCheck" 
                            
                            data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="pickupInfoCheckoutCollapse pickupNotifCollapse"/>
                            <label className="form-check-label" htmlFor="localLocCheck">
                                This location offers local pickup
                            </label>
                        </div>
                    </div>
                </div>

                {/* Information at checkout */}
                <div className='card p-3 stopTransition multi-collapse' id='pickupInfoCheckoutCollapse'>
                    <h6>Information at checkout</h6>
                    <label htmlFor="" className='fw-normal mt-3'>Expected pickup time</label>
                    <select className="form-select shadow-none" aria-label="Default select example">
                        <option>Usually ready in 1 hour</option>
                        <option>Usually ready in 2 hours</option>
                        <option>Usually ready in 4 hours</option>
                        <option>Usually ready in 24 hours</option>
                        <option>Usually ready in 2-4 days</option>
                        <option>Usually ready in 5+ days</option>
                    </select>
                    
                    {/* CHECKOUT PREVIEW */}
                    <div className='my-3 text-sm'>CHECKOUT PREVIEW</div>
                    <div className='p-2 border rounded d-flex justify-content-between'>
                        <div className="d-flex align-items-center">
                            <i className="fa fa-dot-circle-o fs-4 text-success"></i>
                            <div className="ml-3">
                                <span>Shop location</span><br />
                                <span className="text-secondary text-sm">india</span>
                            </div>
                        </div>
                        <div className='text-sm text-right'>
                            <span>Free</span><br />
                            <span className="text-secondary">Usually ready in 24 hours</span>
                        </div>
                    </div>
                    <div className='text-sm text-secondary mt-2'>Location name and address shown at checkout are set in <a href="#">location settings</a></div>
                </div>

                     {/* Order ready for pickup notification */}
                <div className='card p-3 stopTransition multi-collapse' id='pickupNotifCollapse'>
                    <h6>Order ready for pickup notification</h6>
                    <div className="mt-2">
                        <div className='d-flex justify-content-between'>
                        <label htmlFor="" className="form-label fw-normal">Pickup instructions</label>
                        <span>0/300</span>
                        </div>
                        <textarea className="form-control border" id="" rows={4} style={{resize:"none"}} />
                    </div>
                    <div className='pt-2 text-sm text-secondary'>
                        If you're offering curbside pickup, you can let customers know what to do when they arrive at your store.
                    </div>
                </div>
                
            </div>

        </React.Fragment>
    )
}

export default LocalPickup