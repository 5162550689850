import React from 'react'

function Disscount() {
    return (
        <div>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className="row">
                            <p className='fw-bold fs-5 mt-2 '>Discounts</p>

                        </div>

                        <div className="row m-2 rounded" style={{ background: "white" }}>
                            <div className="container text-center">
                                <img alt="no-orders" className='img-fluid mt-5' src="images/disccountpage.svg" />
                                <p className='fs-4'>Manage discounts and promotions</p>
                                <p className='ml-5 mr-5 '>Create discount codes and automatic discounts that apply at checkout. You can also use discounts with compare at prices.</p>
                                <button type="button" class="btn bg-grenncolor fcw mb-5" data-bs-toggle="modal" data-bs-target="#createdisscountmodal">Create discounts</button>
                            </div>

                        </div>
                        <div className="modal fade" id="createdisscountmodal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-secondary" id="exampleModalLabel">Select discount type</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>

                                    <div className="modal-body text-sm" style={{ padding: "0px" }}>

                                        <a href='/typeof/product' className="typeofdisc"> <div className="modal-header typeofdisc">
                                            <p className="modal-title text-secondary fs-6" id="exampleModalLabel">Amount Off Products<br /><span className='' style={{ fontSize: "14px" }}>Products discount</span></p>
                                            <i class="fa fa-chevron-right mt-3" aria-hidden="true"></i>
                                        </div>
                                        </a>
                                        <a href='/typeof/order' className="typeofdisc"> <div className="modal-header typeofdisc">
                                            <p className="modal-title text-secondary fs-6" id="exampleModalLabel">Amount Off Order<br /><span className='' style={{ fontSize: "14px" }}>Order discount</span></p>
                                            <i class="fa fa-chevron-right mt-3" aria-hidden="true"></i>
                                        </div>
                                        </a>
                                        <a href='/typeof/buy X get Y' className="typeofdisc"> <div className="modal-header typeofdisc">
                                            <p className="modal-title text-secondary fs-6" id="exampleModalLabel">Buy X get Y<br /><span className='' style={{ fontSize: "14px" }}>Products discount</span></p>
                                            <i class="fa fa-chevron-right mt-3" aria-hidden="true"></i>
                                        </div>
                                        </a>
                                        <a href='/typeof/shipping' className="typeofdisc"> <div className="modal-header typeofdisc">
                                            <p className="modal-title text-secondary fs-6" id="exampleModalLabel">Free shipping
                                                <br /><span className='' style={{ fontSize: "14px" }}>Shipping discount</span></p>
                                            <i class="fa fa-chevron-right mt-3" aria-hidden="true"></i>
                                        </div>
                                        </a>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn border " data-bs-dismiss="modal" aria-label="Close"  >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disscount