import React from 'react'
import { Link } from 'react-router-dom';

const DashbordPage = () => {
    return (
        <React.Fragment>
                
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container'>

                        {/* alert message */}
                        <div className='row justify-content-center'>
                            <div className='col-md-9 col-12'>

                                <div className='alertBox pt-2'>
                                    <div className="alert alert-dark alert-dismissible fade show text-xs " role="alert">
                                        <div className='d-flex justify-content-between'>
                                           <span className=''>Give your business time to grow. Get Shopify monthly or select a one-year, two-year, or three-year plan for savings of up to 25%.
                                           </span>
                                           <span>
                                              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close">
                                              </button>
                                           </span>
                                        </div>
                                        <span className=''>
                                            <button type="button" className="btn btnColorCustome btnColorOnHover text-sm mt-2 btn-sm">Select a plan</button>
                                        </span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Setup guide*/}
                        <div className='row justify-content-center'>
                            <div className='col-md-9 col-12'>
                                <div className="setupGuideBtn accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button shadow-none card-heading-p pb-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Setup guide
                                            </button>
                                        </h2>
                                        <div className='setupGuideProBar'>
                                            <div className='taskText pb-2'>0 of 4 tasks complete</div>
                                            <div className="progress" style={{ height: "3px" }}>
                                                <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: '1%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className='myBorder'></div>
                                            <div className="guideStep accordion-body">
                                                <ul className="timeline">
                                                    <li>
                                                        <a data-bs-toggle="collapse" href="#add-product-task" role="button" aria-expanded="false" aria-controls="add-product-task">Add your first product
                                                        </a>
                                                        <div className='row collapse multi-collapse' id="add-product-task">
                                                            <div className='col-md-8'>
                                                                <div className='card-body pl-0'>
                                                                   <p>
                                                                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare. Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula. <a href='#'>Learn more about products</a>
                                                                    </p>
                                                                    <Link to='/products/add-products' type="button" className="btn btnColorCustome btnColorOnHover">Add product</Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 d-none d-md-block">
                                                                <img src="/images/add-product-task.svg" className="img-fluid rounded-start" alt="..." />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a data-bs-toggle="collapse" href="#add-brand-task" role="button" aria-expanded="false" aria-controls="add-brand-task">Add brand assets
                                                        </a>
                                                        <div className='row collapse multi-collapse' id="add-brand-task">
                                                            <div className='col-md-8'>
                                                                <div className='card-body pl-0'>
                                                                  <p>
                                                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare. Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula. <a href='#'>Learn more about brand</a>
                                                                    </p>
                                                                    <button type="button" className="btn btnColorCustome btnColorOnHover">Add brand assets</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 d-none d-md-block">
                                                                <img src="/images/add-product-task.svg" className="img-fluid rounded-start" alt="..." />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a data-bs-toggle="collapse" href="#customize-theme-task" role="button" aria-expanded="false" aria-controls="customize-theme-task">Customize theme
                                                        </a>
                                                        <div className='row collapse multi-collapse' id="customize-theme-task">
                                                            <div className='col-md-8'>
                                                                <div className='card-body pl-0'>
                                                                 <p>
                                                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare. Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula. <a href='#'>Learn more about custom theme </a>
                                                                 </p>
                                                                    <button type="button" className="btn btnColorCustome btnColorOnHover mt-3">Customize theme</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 d-none d-md-block">
                                                                <img src="/images/add-product-task.svg" className="img-fluid rounded-start" alt="..." />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a data-bs-toggle="collapse" href="#add-cusDomain-task" role="button" aria-expanded="false" aria-controls="add-cusDomain-task">Add your first product
                                                        </a>
                                                        <div className='row collapse multi-collapse' id="add-cusDomain-task">
                                                            <div className='col-md-8'>
                                                                <div className='card-body pl-0'>
                                                                   <p>
                                                                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque diam non nisi semper, et elementum lorem ornare. Maecenas placerat facilisis mollis. Duis sagittis ligula in sodales vehicula. <a href='#'>Learn more how to add custom domain</a>
                                                                   </p>
                                                                    <button type="button" className="btn btnColorCustome btnColorOnHover">Add domain</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 d-none d-md-block">
                                                                <img src="/images/add-product-task.svg" className="img-fluid rounded-start" alt="..." />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                         {/* Explore more support */}
                        <div className='row justify-content-center pt-3'>
                            <div className='col-md-9 col-12'>

                                <div className="card px-4 pb-4 pt-3">
                                    <div className='card-heading-dis d-flex justify-content-between align-items-center'>
                                      <p className='card-heading-p lh-1'>Explore more support</p>
                                      <ul className='exploreSupport mb-0'>
                                          <li className="nav-item dropdown">
                                                <a className="nav-link text-secondary" data-toggle="dropdown" href="/">
                                                   <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                <a href="/" className="dropdown-item text-secondary">
                                                    <i className="fa fa-times mr-3" aria-hidden="true"></i>Dismiss
                                                </a>
                                                <a href="/" className="dropdown-item text-secondary"><i className="fa fa-commenting mr-3" aria-hidden="true"></i>Give feedback
                                                </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className='card-dis-p text-sm'>Check out these resources for answers to your questions, videos, and best practices.</p>

                                    <ul className="list-group">
                                        <li className="list-group-item d-flex justify-content-start align-items-center">
                                           <span className='mr-3'>
                                               <img src="/images/video.jpeg" alt="video-logo"       />
                                           </span>
                                           <span>
                                              <span>Help Center</span><br/>
                                              <span className='text-sm text-secondary'>Find documentation and tutorials on how to use e-Web Builder. <a href='#'>See our help docs</a></span>
                                           </span>        
                                        </li>
                                        <li className="list-group-item d-flex justify-content-start align-items-center">
                                           <span className='mr-3'>
                                               <img src="/images/video.jpeg" alt="video-logo"       />
                                           </span>
                                           <span>
                                              <span>e-Web Builder Learn</span><br/>
                                              <span className='text-sm text-secondary'>Find documentation and tutorials on how to use e-Web Builder. <a href='#'>Get started</a></span>
                                           </span>        
                                        </li>
                                        <li className="list-group-item d-flex justify-content-start align-items-center">
                                           <span className='mr-3'>
                                               <img src="/images/video.jpeg" alt="video-logo"       />
                                           </span>
                                           <span>
                                              <span>Courses</span><br/>
                                              <span className='text-sm text-secondary'>Find documentation and tutorials on how to use e-Web Builder. <a href='#'>Browse courses</a></span>
                                           </span>        
                                        </li>
                                     
                                        <li className="list-group-item d-flex justify-content-start align-items-center">
                                           <span className='mr-3'>
                                               <img src="/images/video.jpeg" alt="video-logo"       />
                                           </span>
                                           <span>
                                              <span>Community</span><br/>
                                              <span className='text-sm text-secondary'>Find documentation and tutorials on how to use e-Web Builder. <a href='#'>Join the discussion</a></span>
                                           </span>        
                                        </li>
                                        <li className="list-group-item d-flex justify-content-start align-items-center">
                                           <span className='mr-3'>
                                               <img src="/images/video.jpeg" alt="video-logo"       />
                                           </span>
                                           <span>
                                              <span>Ask us about a topic</span><br/>
                                              <span className='text-sm text-secondary'>Find documentation and tutorials on how to use e-Web Builder. <a href='#'>Ask about a topic</a></span>
                                           </span>        
                                        </li>
                                     
                                    </ul>

                                </div>
                                
                            </div>
                        </div>

                       {/* Earn trust with a climate commitment badge */}
                        <div className='row justify-content-center'>
                            <div className='col-md-9 col-12'>
                                
                                <div className="card px-4 pb-4 pt-3">
                                    <div className='card-heading-dis d-flex justify-content-between align-items-center'>
                                      <p className='card-heading-p lh-1'>Earn trust with a climate commitment badge</p>
                                      <ul className='exploreSupport mb-0'>
                                          <li className="nav-item dropdown">
                                                <a className="nav-link text-secondary" data-toggle="dropdown" href="/">
                                                   <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                <a href="/" className="dropdown-item text-secondary">
                                                    <i className="fa fa-times mr-3" aria-hidden="true"></i>Dismiss
                                                </a>
                                                <a href="/" className="dropdown-item text-secondary"><i className="fa fa-commenting mr-3" aria-hidden="true"></i>Give feedback
                                                </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className='card-dis-p text-sm'>Show your shoppers that your store features carbon-neutral shipping with Shopify's new Planet app. For an average of $0.03-$0.15 per order, Planet lets you support the most innovative climate solutions in the world, and storefront trust badges tell shoppers about your commitment.</p>
                                    <span>
                                      <button type="button" className="btn btnColorCustome btnColorOnHover">Try planet</button>
                                    </span>
                                </div>
                                
                            </div>
                        </div>

                        {/* Use POS to boost sales with gift cards */}
                        <div className='row justify-content-center'>
                            <div className='col-md-9 col-12'>

                                <div className="card px-4 pb-4 pt-3">
                                    <div className='card-heading-dis d-flex justify-content-between align-items-center'>
                                      <p className='card-heading-p lh-1'>Use POS to boost sales with gift cards</p>
                                      <ul className='exploreSupport mb-0'>
                                          <li className="nav-item dropdown">
                                                <a className="nav-link text-secondary" data-toggle="dropdown" href="/">
                                                   <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                <a href="/" className="dropdown-item text-secondary">
                                                    <i className="fa fa-times mr-3" aria-hidden="true"></i>Dismiss
                                                </a>
                                                <a href="/" className="dropdown-item text-secondary"><i className="fa fa-commenting mr-3" aria-hidden="true"></i>Give feedback
                                                </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className='card-dis-p text-sm'>Retailers who offer gift cards see an additional 25-35% in spend. Activate payment by gift card for the Shopify POS app to seamlessly sell, redeem, or refund physical and digital gift cards in person and online.</p>
                                    <span>
                                      <button type="button" className="btn btnColorCustome btnColorOnHover">Learn more</button>
                                    </span>
                                </div>
                                
                            </div>
                        </div>

                        {/* Speed up checkout with Shopify Email’s new express checkout buttons */}
                        <div className='row justify-content-center'>
                            <div className='col-md-9 col-12'>

                                <div className="card px-4 pb-4 pt-3">
                                    <div className='card-heading-dis d-flex justify-content-between align-items-center'>
                                      <p className='card-heading-p lh-1'>Speed up checkout with Shopify Email's new express checkout buttons</p>
                                      <ul className='exploreSupport mb-0'>
                                          <li className="nav-item dropdown">
                                                <a className="nav-link text-secondary" data-toggle="dropdown" href="/">
                                                   <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                <a href="/" className="dropdown-item text-secondary">
                                                    <i className="fa fa-times mr-3" aria-hidden="true"></i>Dismiss
                                                </a>
                                                <a href="/" className="dropdown-item text-secondary"><i className="fa fa-commenting mr-3" aria-hidden="true"></i>Give feedback
                                                </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className='card-dis-p text-sm'>Let customers shop a recent product launch from their email with just a few clicks using Shop Pay.</p>
                                    <span>
                                      <button type="button" className="btn btnColorCustome btnColorOnHover">Try now</button>
                                    </span>
                                </div>
                
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default DashbordPage;