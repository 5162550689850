
import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import ShippingDeliveryModal from '../SettingCompModal/ShippingDeliveryModal';

const CreateShippingProfile = () => {
    let navigate = useNavigate();
  return (
    <React.Fragment>
        <ShippingDeliveryModal/>
        <div className='pl-md-3 mt-3 mt-md-0'>

             {/* for going back btn */} 
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' src="/images/previous.png" alt='back_logo' onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Create shipping profile</span>
            </div> 
            <div className='border-top my-4'></div>

                 {/* Name */}
            <div className='card p-3'>
                <h6 className='py-2'>Name</h6>
                <input type="text" className='form-control' placeholder='Fragile products'/>
                <div className='pt-1 text-secondary text-sm'>
                    Customers won't see this.
                </div>
            </div>

                  {/* Products */}
            <div className='card p-3'>
                <div className='d-flex justify-content-between'>
                    <h6>Products</h6>
                    <a href="#" data-bs-toggle="modal" data-bs-target="#EditProfileItemModal">Add products</a>
                </div> 
                <div className='text-center mt-3 p-3 bg-light rounded'>
                    <span>No products</span><br />
                    <span className='text-secondary text-sm'>Move products here from another profile to set up separate rates.</span>
                </div>
            </div>

                  {/* shipping from */}
            <div className='card'>    
                <div className='p-3'>
                    <h6>Shipping from</h6>
                    <div className="py-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div>
                                <i className="fa fa-map-marker fs-4 border rounded bg-white px-3 py-2"></i>
                            </div>
                            <div className="px-2"> 
                                <span className="text-dark">Shop location</span><br />
                                <span className="text-secondary">india</span>
                            </div>
                        </div>
                        <div>
                           <a href="#" data-bs-toggle="modal" data-bs-target="#shopLocationModal">Manage</a>
                        </div>
                    </div>
                </div>

                <div className='border-top p-3'>
                    <div className='d-flex justify-content-between'>
                        <h6>Shipping to</h6>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#shippingZoneModal">Create shipping zone</a>
                    </div>
                    <div className='text-center mt-3 p-2 bg-light rounded'>
                        <span>No zones or rates</span><br />
                        <span className='text-sm text-secondary'>Add zones to create rates for places you want to ship to.</span>
                    </div>
                </div>
            </div>
     
            <div className='border-top my-3'></div>
            <div className='d-flex justify-content-between'>
                <button className='btn btn-outline-light border text-dark'>Discard</button>
                <button className=' btn btnColorCustome btnColorOnHover'>Save</button>
            </div>
        </div>

    </React.Fragment>
  )
}

export default CreateShippingProfile