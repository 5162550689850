
import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const TransferDomain = () => {
    let navigate = useNavigate()
  return (
    <React.Fragment>
      <div className='pl-md-3 mt-3 mt-md-0'>

        {/* for going back btn */}
        <div className='d-flex'>
            <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
            <span className='ml-2 fw-normal fs-5'>Transfer domain</span>
        </div>
        <div className='border-top my-4'></div>
          
            {/* Domain */}
        <div className='card p-3'>
            <h6>Transfer domain</h6>
            <div className='mt-2'>
                <label htmlFor="" className='fw-normal'>Domain</label>
                <input type="text" className='form-control border' placeholder='e.g. example.com'/>
            </div>
            <div className='mt-2 text-secondary'>Enter the domain you would like to transfer to ecomBuilder
            </div>

            <div className='text-right mt-3'>
                <button className='btn btnColorCustome btnColorOnHover'>Next</button>
            </div> 
        </div>

        <div className='text-center text-secondary'>
                {/* connect domain */}
            <p>Or you can <Link to="/setting/domains/connect_domain">connect your domain</Link> to ecomBuilder (management will remain with your current registrar).</p>  

                {/* Learn more about domains */}
            <p>Learn more about <a href="#">domains</a></p>
        </div>
        
      </div>
    </React.Fragment>
  )
}

export default TransferDomain