
import React from 'react'
import { useNavigate } from 'react-router-dom';

const Upcoming = () => {
    let navigate = useNavigate();
  return (
    <React.Fragment>
        <div className='pl-md-3'>
            {/* for going back btn */}         
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Current billing cycle: Billed on Dec 9, 2022</span>
            </div> 
            <div className='border-top my-4'></div>

            <div className='row'>
                <div className='col-md-8'>

                    <div className="accordion" id="accordionExample">   
                        <div className="accordion-item">
                            <h2 className='accordion-header' id="headingOne">
                                <button className="accordion-button shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Subscription (1 item) <span className='px-5 mx-md-5'>₹0.00</span>
                                </button>   
                            </h2>

                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                {/* --- <div className="accordion-body"></div> --- */}
                            </div>
                            <div className='border-top'></div>
                            <div className='accordion-body d-flex justify-content-between'>
                                <h6 className=''>Subtotal</h6>
                                <h6 className=''>₹0.00</h6>
                            </div>
                        </div>
                    </div>

                    <div className='card p-3 mt-3'>
                        <div className='d-flex justify-content-between'>
                            <h6 className='fw-normal'>Running total</h6>
                            <h6 className='fw-normal'>₹0.00</h6>
                        </div> 
                    </div>

                </div>

                <div className='col-md-4'>
                    <div className='card p-3'>
                        <h4 className='text-center py-2'>₹0.00</h4>
                        <div className='border-top'></div>
                        <div className='pt-3'>
                           <p>If you reach the equivalent of $60.00 USD in fees before the end of your billing cycle, a fee threshold bill will be issued automatically. Your fee threshold doesn't include subscription charges.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4 text-center'>
                <p>Learn more about <a href="#">how your bill works</a>.</p>
            </div>

        </div>
    </React.Fragment>
  )
}

export default Upcoming