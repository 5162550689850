
import React from 'react'

const NotificationsModal = () => {
  return (
    <React.Fragment>
        {/* Add recipient -> modal */}
        <div className="modal fade" id="addRecipientModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add an order notification</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div>
                            <div>Notification method</div>
                            <select className="form-select mt-1 shadow-none">
                                <optgroup label='Notification Method'>
                                    <option>Email address</option>
                                </optgroup>
                                <optgroup label='or email a staff member'>
                                    <option>Upendra Kumar</option>
                                </optgroup>
                            </select>
                        </div>
                        <div className='mt-3 mb-1'>Email address</div>
                        <input type="email" className='form-control shadow-none'/>

                        <div className='mt-3 text-sm text-secondary'>To get notifications on your phone, download the <a href="#">ecomBuilder Mobile app</a>.</div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light text-dark border" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Add an order notification</button>
                    </div>

                </div>
            </div>
        </div>

        {/* Create webhook -> modal */}
        <div className="modal fade" id="addWebhookModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add webhook</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                       <div className='mb-1'>Event</div>
                        <select className="form-select shadow-none">
                            <option value={1}>One</option>
                            <option value={2}>Two</option>
                            <option value={3}>Three</option>
                        </select>
                        <div className='mb-1 mt-3'>Format</div>
                        <select className="form-select shadow-none">
                            <option>JSON</option>
                            <option>XML</option>
                        </select>

                        <div className='mb-1 mt-3'>URL</div>
                        <input type="url" className='form-control' />

                        <div className='mb-1 mt-3'>Webhook API version</div>
                        <select className="form-select shadow-none">
                            <option>unstable</option>
                            <option>unstable</option>
                            <option>unstable</option>
                        </select>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light text-dark border" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button>
                    </div>

                </div>
            </div>
        </div>
         
    </React.Fragment>
  )
}

export default NotificationsModal