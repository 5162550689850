import React from 'react'
import { useNavigate } from 'react-router-dom'
import EditorConvertToJSON from './EditorConvertToJSON/EditorConvertToJSON'

const AddProduct = () => {

    let navigate = useNavigate();
    const commentMess = "To show a reduced price, move the product's original price into Compare at price. Enter a lower value into Price."

    return (
        <React.Fragment>
            <div className='content-wrapper topSpace' >
                <div className="container-fluid">
                    <div className='container'>

                        {/* for going back btn */}
                        <div className="row justify-content-center">
                            <div className="col-md-11 pt-2">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex'>
                                        <img className='img-fluid' data-bs-toggle="modal" data-bs-target="#previousBtn" src="/images/previous.png" onClick={() => { navigate(-1) }} />
                                        <span className='ml-2 fw-normal fs-5'>Add products</span>
                                    </div>
                                    <span className='fw-normal' type="" >
                                        <a href="#" className='text-secondary' data-bs-toggle="modal" data-bs-target="#importProduct">Import  </a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* for giving border */}
                        <div className='row justify-content-center'>
                            <div className='col-md-11'>
                                <div className='myBorder pt-3'></div>
                            </div>
                        </div>

                        {/* import product modal */}
                        <div className="modal fade" id="importProduct" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-secondary" id="exampleModalLabel">Import products from .csv</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>

                                    {/* Add product modal */}
                                    <div className="modal-body text-sm">
                                        <p> <a href="#">Download a sample CSV</a> to see an example of the required format.</p>
                                        <div className='container text-center py-4'>
                                            <label htmlFor="files" className="btn bg-light py-4" style={{ width: '12rem' }}>
                                                <img src="/images/csv-icon.svg" alt='csv file logo'></img>
                                            </label>
                                            <input id="files" style={{ display: 'none' }} type="file" />
                                        </div>

                                        <div className="form-check pb-2">
                                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Overwrite any current products that have the same handle. Existing values will be used for any missing columns.<a href='#'>Learn more</a>
                                            </label>
                                        </div>

                                        <p><a href='#'>Click here</a> to understand more about importing products</p>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" disabled>Upload and continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* add product from URL modal */}
                        <div className="modal fade" id="addFromURL" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <p className="modal-title fs-5" id="exampleModalLabel">Add file from URL</p>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body">
                                        <label htmlFor="addUrl" className="form-label text-secondary text-sm fw-normal">Image, YouTube, or Vimeo URL</label>
                                        <input type="email" className="form-control" id="addUrl" placeholder="https://" />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary disabled" data-bs-dismiss="modal">Add Files</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ....Manage Modal.... */}
                        <div className="modal fade" id="manageScrollable" tabIndex={-1} role="dialog" aria-labelledby="ModalScrollableTitle" aria-hidden="false">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <p className="modal-title fs-5" id="ModalScrollableTitle">Manage tags</p>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">x</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='myMenageModal-Min-H'>
                                            <div className='row'>
                                                {/* search box */}
                                                <div className='col-7'>
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <div className="input-group-text bg-white rounded-start"><i className="fa fa-search text-secondary" /></div>
                                                        </span>
                                                        <input className="form-control border-left-0 border" type="search" placeholder='Search to find or create tags' />
                                                    </div>
                                                </div>
                                                {/* select box */}
                                                <div className='col-5'>
                                                    <select className="form-select shadow-none" aria-label="">
                                                        <option selected>Alphabetical</option>
                                                        <option value={1}>Frequently used</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='addBox'>
                                                <a href="#">
                                                    <span>
                                                        <i className="fa fa-plus-circle pr-2 pt-3" aria-hidden="true"></i>
                                                    </span>Add
                                                </a>
                                            </div>
                                            <p className='text-secondary text-sm text-center pt-3'>No tags yet</p>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" className="btn btnColorCustome btnColorOnHover" data-dismiss="modal">Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-center py-2'>
                            <div className='col-md-11'>
                                <div className='row'>
                                    {/* content in 8 col */}
                                    <div className='py-2 col-md-8'>
                                        {/* for add product -> title */}
                                        <div className='card p-3'>
                                            <div className="">
                                                <label htmlFor="tname" className="form-label fw-normal">Title</label>
                                                <input type="text" className="form-control" id="tname" placeholder="Short sleeve t-shirt" />
                                            </div>
                                            
                                            {/* ---- text editor ---- */}
                                            <div className='mt-3'>
                                                <EditorConvertToJSON />
                                            </div>
                                        </div>
                                    
                                        {/* for add product -> media */}
                                        <div className='card p-3'>
                                            <label className='fw-normal' htmlFor="files">
                                                <div className="add-prod">
                                                    <p className='fw-normal fs-5'>Media</p>
                                                    <div className="add-media-option d-flex flex-column justify-content-center align-items-center">
                                                        <div>
                                                            <input type="file" className='d-none' id='files' />
                                                            <span className='mx-2 py-1 px-2 rounded btnColorCustome btnColorOnHover'>Add files</span>
                                                            <a className="mx-2 py-1 px-2 rounded btnColorCustome btnColorOnHover" data-bs-toggle="modal" data-bs-target="#addFromURL" href="#">Add from URL</a>
                                                        </div>
                                                        <p className='text-sm text-secondary py-2'>Accepts images, videos, or 3D models</p>
                                                    </div>

                                                </div>
                                            </label>
                                        </div>

                                        {/* for add product-> Pricing */}
                                        <div className='card p-3'>
                                            <div className="add-prod">
                                                <p className='fw-normal fs-5'>Pricing</p>
                                                <div className="add-pricing">
                                                    <label htmlFor="pricing" className="form-label fw-normal">Price</label>
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr text-secondary" /></div>
                                                        </span>
                                                        <input className="form-control border-left-0 border" type="text" id="pricing" placeholder='0.00' />
                                                    </div>

                                                    <label htmlFor="cPrice" className="form-label pt-2 fw-normal">Compare at price</label>
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr text-secondary" /></div>
                                                        </span>

                                                        <input className="form-control border-right-0 border-left-0 border" type="text" id="cPrice" placeholder='0.00' />

                                                        <span className="input-group-append">
                                                            <div className="input-group-text bg-white" data-toggle="tooltip" data-placement="top" title={commentMess}>
                                                                <i className="fa fa-info-circle text-secondary" />
                                                            </div>
                                                        </span>
                                                    </div>

                                                    <div className="form-check pl-4 py-3">
                                                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="chargeTax" />
                                                        <label className="form-check-label" htmlFor="chargeTax">
                                                            Charge tax on this product
                                                        </label>
                                                    </div>
                                                    <div className='border-top my-2'></div>

                                                    <label htmlFor="costPitem" className="form-label pt-2 fw-normal">Cost per item</label>
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr text-secondary" /></div>
                                                        </span>
                                                        <input className="form-control border-left-0 border" type="text" id="costPitem" placeholder='0.00' />
                                                    </div>
                                                    <p className='text-sm text-secondary pt-1'>Customers won't see this</p>

                                                </div>

                                            </div>

                                        </div>

                                        {/* for add product-> Inventory */}
                                        <div className='card p-3'>
                                            <div className="add-prod">
                                                <p className='fw-normal fs-5'>Inventory</p>
                                                <div className="add-inventory">
                                                    <div className='row pb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="sku" className="form-label fw-normal">SKU (Stock Keeping Unit)</label>
                                                            <input type="text" className="form-control" id="sku" />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="barcode" className="form-label fw-normal">Barcode (ISBN, UPC, GTIN, etc.)</label>
                                                            <input type="text" className="form-control" id="barcode" />
                                                        </div>
                                                    </div>

                                                    <div className="form-check pl-4">
                                                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="tQuantity"
                                                            data-bs-toggle="collapse" data-bs-target="#clickOnCheckTrack" aria-expanded="true" aria-controls="clickOnCheckTrack" />
                                                        <label className="form-check-label" htmlFor="tQuantity">
                                                            Track quantity
                                                        </label>
                                                    </div>
                                                    <div className="form-check pl-4 collapse" id='clickOnCheckTrack'>
                                                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="continueSell" />
                                                        <label className="form-check-label" htmlFor="continueSell">
                                                            Continue selling when out of stock
                                                        </label>
                                                    </div>
                                                    <div className='border-top my-2'></div>
                                                    <p>QUANTITY</p>
                                                    <div className='d-flex justify-content-between'>
                                                        <p>Location name</p>
                                                        <p>Available</p>
                                                    </div>
                                                    <div className='border-top'></div>
                                                    <div className='d-flex justify-content-between align-item-center py-3'>
                                                        <p>Shop location</p>
                                                        <div className="">
                                                            <input type="number" className="form-control" min={0} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* for add product-> Shipping */}
                                        <div className='card p-3'>
                                            <div className="add-prod">
                                                <p className='fw-normal fs-5'>Shipping</p>
                                                <div className="add-shipping">
                                                    <div className="form-check pl-4">
                                                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="tQuantity"
                                                            data-bs-toggle="collapse" data-bs-target="#clickOnCheckShipping" aria-expanded="true" aria-controls="clickOnCheckShipping" />
                                                        <label className="form-check-label" htmlFor="tQuantity">
                                                            This is a physical product
                                                        </label>
                                                    </div>

                                                    <div className='forShipping collapse' id="clickOnCheckShipping">
                                                        <div className='border-top my-2'></div>
                                                        <p>WEIGHT</p>
                                                        <p className='text-secondary text-sm'>
                                                            Used to calculate shipping rates at checkout and label prices during fulfillment. See <a href="#">guidelines</a> for estimating product weight.
                                                        </p>
                                                        <div className='row align-items-end'>
                                                            <div className='col-10'>
                                                                <label htmlFor="weight" className='fw-normal'>Weight</label>
                                                                <input type="text" className='form-control' id='weight' placeholder='0.0' />
                                                            </div>
                                                            <div className='col-2'>
                                                                <select className="form-select shadow-none" aria-label="Default select example">
                                                                    <option selected>Kg</option>
                                                                    <option>lb</option>
                                                                    <option>oz</option>
                                                                    <option>g</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='border-top mt-4 mb-3 '></div>
                                                        <p>CUSTOMS INFORMATION</p>
                                                        <p className='text-secondary text-sm'>
                                                            Customs authorities use this information to calculate duties when shipping internationally. Shown on printed customs forms.
                                                        </p>
                                                        <div className='selectCountry'>
                                                            <label htmlFor="country" className='fw-normal'>Country/Region of origin</label>
                                                            <select className="form-select shadow-none" aria-label="Default select example">
                                                                <option selected>Select Country/Region of origin</option>
                                                                <option>India</option>
                                                                <option>America</option>
                                                                <option>Pakistan</option>
                                                            </select>
                                                        </div>
                                                        <p className='text-secondary text-sm py-1'>In most cases, where the product is manufactured.</p>

                                                        <label htmlFor="hsCode" className="form-label fw-normal">HS (Harmonized System) code</label>
                                                        <div className="input-group">
                                                            <span className="input-group-append">
                                                                <div className="input-group-text bg-white rounded-start"><i className="fa fa-search text-secondary" /></div>
                                                            </span>
                                                            <input className="form-control border-left-0 border" type="search" id="hsCode" placeholder='Search or enter a HS code' />
                                                        </div>
                                                        <p className='text-secondary text-sm py-1'>Manually enter codes that are longer than 6 numbers.</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        {/* for add product-> Options */}
                                        <div className='card p-3'>
                                            <div className="add-prod">
                                                <p className='fw-normal fs-5'>Options</p>
                                                <div className="add-option">
                                                    <div className="form-check pl-4">
                                                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="tQuantity"
                                                            data-bs-toggle="collapse" data-bs-target="#clickOnCheckOption" aria-expanded="true" aria-controls="clickOnCheckOption"
                                                        />
                                                        <label className="form-check-label" htmlFor="tQuantity">
                                                            This product has options, like size or color
                                                        </label>
                                                    </div>
                                                    <div className='collapseOnCheck'>
                                                        <div className="collapse" id="clickOnCheckOption">
                                                            <div className='border-top my-2'></div>
                                                            <div className='row justify-content-center align-items-center'>
                                                                <div className='iconSliders col-1 text-center pt-4'>
                                                                    <i className="fa fa-sliders text-secondary" aria-hidden="true"></i>
                                                                </div>
                                                                <div className='col-10'>
                                                                    <label htmlFor="oName" className="form-label fw-normal">Option name</label>
                                                                    <input type="text" className="form-control" id="oName" />
                                                                </div>
                                                                <div className='iconTrash col-1 text-center pt-4'>
                                                                    <i className="fa fa-trash text-secondary" aria-hidden="true"></i>
                                                                </div>
                                                                <div className='col-10 mt-2'>
                                                                    <label htmlFor="oValue" className="form-label fw-normal">Option values</label>
                                                                    <input type="text" className="form-control" id="oValue" />
                                                                    <button type="button" className="btn btnColorCustome btnColorOnHover mt-2">Done</button>

                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {/* Search engine listing */}
                                        <div className='card p-3'>
                                            <div className="add-prod">
                                                <div className='d-flex justify-content-between'>
                                                    <p className='fw-normal fs-5'>Search engine listing</p>
                                                    <button type="button" className="btn btn-sm btnColorCustome btnColorOnHover m-2"
                                                        data-bs-toggle="collapse" data-bs-target="#clickOnCheckURL" aria-expanded="true" aria-controls="clickOnCheckURL">Edit</button>

                                                </div>
                                                <p className='text-sm text-secondary'>Add a title and description to see how this product might appear in a search engine listing</p>
                                                <div className="add-SEO">
                                                    <div className='collapseOnCheck collapse' id='clickOnCheckURL'>
                                                        <div>
                                                            <label htmlFor="pTitle" className="form-label fw-normal">Page title</label>
                                                            <input type="text" className="form-control" id="pTitle" />
                                                        </div>
                                                        <p className='text-secondary pt-1'>0 of 70 characters used</p>
                                                        <div>
                                                            <label htmlFor="metaDis" className='fw-normal'>Meta description</label>
                                                            <textarea className="form-control" id="metaDis" rows={4} style={{ resize: "none" }} />
                                                        </div>
                                                        <p className='text-secondary pt-1'>0 of 320 characters used</p>
                                                        <div className='forURL'>
                                                            <label htmlFor="url" className="form-label fw-normal">URL handle</label>
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text" id="addon">https://example.com/users/</span>
                                                                <input type="text" className="form-control" id="url" aria-describedby="addon" />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    {/* content in 4 col */}
                                    <div className='py-2 col-md-4'>
                                        {/* Product status */}
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-normal fs-5'>Product status</p>
                                                <select className="form-select shadow-none" aria-label="Default select example">
                                                    <option selected>Active</option>
                                                    <option value=''>Draft</option>
                                                </select>
                                                <p className='text-secondary text-sm pt-2'>This product will be available to 0 sales channel.</p>
                                            </div>
                                            <span className='border-top'></span>
                                            <div>
                                                <p className='text-sm py-2'>SALES CHANNELS AND APPS</p>
                                                <div className="form-check pl-4">
                                                    <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"
                                                        data-bs-toggle="collapse" data-bs-target="#clickOnCheckStore" aria-expanded="true" aria-controls="clickOnCheckStore" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        Online Store
                                                    </label>
                                                </div>
                                                <div className='forCheckAvailability collapse' id='clickOnCheckStore'>
                                                    <a href='#' className='pl-4'>Schedule availability</a>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Product organization */}
                                        <div className='card p-3'>
                                            <div className="forProdOrg">
                                                <p className='fw-normal fs-5'>Product organization</p>
                                                <div>
                                                    <label htmlFor="" className='fw-normal'>Product Category</label>
                                                    <select className="form-select shadow-none" aria-label="Default select example">
                                                        <option selected>product 1</option>
                                                        <option value=''>product 2</option>
                                                    </select>
                                                </div>
                                                <div className='pt-3'>
                                                    <label htmlFor="" className='fw-normal'>Product Type</label>
                                                    <input type="text" className='form-control' placeholder='eg., T-shirt' />
                                                </div>
                                                <div className='pt-3'>
                                                    <label htmlFor="" className='fw-normal'>Vendor</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                                <div className='pt-3'>
                                                    <label htmlFor="" className='fw-normal'>Collections</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                                <div className='pt-3'>
                                                    <label htmlFor="" className='fw-normal d-flex justify-content-between'>
                                                        <span>Tags</span>
                                                        <a href='#' data-toggle="modal" data-target="#manageScrollable">Manage</a>
                                                    </label>
                                                    <input type="text" className='form-control' placeholder='Find or create tags' />
                                                </div>

                                            </div>


                                        </div>

                                        {/* Product status */}
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-normal fs-5'>Online Store</p>
                                                <label htmlFor="theme" className='fw-normal'>
                                                    Theme template
                                                </label>
                                                <input type="number" className='form-control' id='theme' placeholder='0' min={0} />
                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AddProduct