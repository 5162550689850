

import React from 'react'
import { Link } from 'react-router-dom'

const SettingLocations = () => {
  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <div className='d-flex justify-content-between'>
                <h5>Locations</h5>
                <Link to='/setting/locations/add-new-location' className='btn btnColorCustome btnColorOnHover text-bold'>Add location</Link>
            </div>
            <div className='border-top my-3'></div>
            
            <div className="card">
                <div className="px-3 pt-3">
                    <h6>Locations</h6>
                    <p className='text-sm text-secondary'>Manage the places you stock inventory, fulfill orders, and sell products. You're using 1 of 4 locations available on your plan. <a href="#">Compare plans</a></p>
                </div>
                <div className='border-top'></div>
                <Link to='/setting/locations/shop-location' className="localShopLocation px-3 py-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div>
                            <i className="fa fa-map-marker fs-4 border rounded bg-white px-3 py-2"></i>
                        </div>
                        <div className="px-2">
                            <span className="text-dark">Shop location</span><br />
                            <span className="text-secondary">india</span>
                        </div>
                    </div>
                    <div>
                        <span className="spanBg text-dark text-sm rounded-pill px-2 py-1 text-nowrap">Default</span>
                    </div>
                </Link>
            </div>
            <div className='text-center'>
                <p className='pt-3'>Learn more about <a href="#">locations</a></p>
            </div>
              
        </div>

    </React.Fragment>
  )
}

export default SettingLocations