
import React from 'react'


const PointOfSale = () => {
    return (
        <React.Fragment>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container'>

                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <span className='mr-2'>
                                    <a href="#"><i className="fa fa-shopping-bag" aria-hidden="true"></i></a>
                                </span>
                                <span>
                                    <a className='text-secondary' href="#">Point of Sale</a>
                                </span>
                            </div>
                            <div className=''>
                                <span className=''>
                                    <a href="#">
                                        <i className="fa fa-thumb-tack text-secondary fs-5" aria-hidden="true"></i>
                                    </a>
                                </span>
                                <span className='ml-3'>
                                    <a href='#'>
                                        <i className="fa fa-ellipsis-h fs-4 text-secondary"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className='border-bottom pt-2'></div>
                        <div className='py-1'>
                            <p className='fs-4'>Getting started</p>
                        </div>
                        <div className='border-top'></div>
                        
                        <div className='row py-3 justify-content-center'>
                            <div className='col-md-9'>
                                 <div className='card p-5'>
                                    <p className='text-secondary'>Tell us about your retail business to get started.</p>
                                    <p className='fs-5'>Where do you want to sell in person?</p>
                                    <p className='text-secondary'>Select any that might interest you — it's okay if you change your mind later.</p> 
                                
                                    <div>
                                        <div className='pt-2'>
                                            <label className="list-group-item px-5 py-3 rounded d-flex justify-content-between align-items-center">
                                                <div>
                                                    <span>
                                                        <input className="form-check-input shadow-none me-1" type="checkbox" defaultValue />
                                                    </span>
                                                     <span className='ml-3 text-secondary fw-normal'>At markets and fairs</span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <img src="/images/market.svg" alt="market-fairs" />
                                                    </span>
                                                </div>
                                            </label>
                                        </div>

                                        <div className='pt-2'>
                                            <label className="list-group-item px-5 py-3 rounded d-flex justify-content-between align-items-center">
                                                <div>
                                                    <span>
                                                        <input className="form-check-input shadow-none me-1" type="checkbox" defaultValue />
                                                    </span>
                                                     <span className='ml-3 text-secondary fw-normal'>At pop-ups</span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <img src="/images/market.svg" alt="market-fairs" />
                                                    </span>
                                                </div>
                                            </label>
                                        </div>

                                        <div className='pt-2'>
                                            <label className="list-group-item px-5 py-3 rounded d-flex justify-content-between align-items-center">
                                                <div>
                                                    <span>
                                                        <input className="form-check-input shadow-none me-1" type="checkbox" defaultValue />
                                                    </span>
                                                     <span className='ml-3 text-secondary fw-normal'>In my own brick and mortar store(s)</span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <img src="/images/market.svg" alt="market-fairs" />
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className='text-right pt-2'>
                                            <a href="#">Skip</a>
                                           <button type="button" className="btn btnColorCustome btnColorOnHover ml-3">Submit</button>
                                        </div>
                                    </div>

                                 </div>
                                 <div className='text-center pt-3'>
                                    <p>Learn more about <a href="#">Shopify POS</a></p>
                                 </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PointOfSale