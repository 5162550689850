import React from 'react'
import { useNavigate } from 'react-router-dom';

const PaymentMethodOption = ()=>{
    let navigate = useNavigate();
    return (
        <React.Fragment>
           <div className='px-md-5'>
                {/* for going back btn */}         
                <div className='d-flex'>
                    <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                    <span className='ml-2 fw-normal fs-5'>(for testing) Bogus Gateway</span>
                </div> 
                <div className='border-top my-4'></div>
                <div className='card p-3'>
                    <h6>About (for testing) Bogus Gateway</h6>
                    <p className='text-sm'>Bogus gateway is meant for testing. Ecom builder will not charge you transaction fees on orders paid with this gateway.</p>
                    <p className='text-sm'>You can use the following codes in place of the credit card number when checking out:</p>
                    <ul>
                        <li>1 - To Simulate an Approved Transaction</li>
                        <li>2 - To Simulate a Declined Transaction</li>
                        <li>3 - To Simulate a Gateway Failure</li>
                    </ul>
                    <p className='text-sm'>Any three numbers can be used as the "Card Security Code" and any expiry date in the future will work.</p>
                    <p className='text-sm'>Get support from <a href="#">(for testing) Bogus Gateway</a>.</p>
                    <div className='border-top'></div>
                    <div className='row'>
                        <div className='col-md-6 pt-3'>
                            <span>Credit card rate</span><br />
                            <span><a href="#">Set by (for testing) Bogus Gateway</a></span>
                        </div>
                        <div className='col-md-6 pt-3'>
                            <span>Transaction fee</span><br />
                            <span>0%</span>
                        </div>
                    </div>
                </div>
                <div className='card p-3'>
                    <div className="form-check" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexCheckDefault"><img src="/images/bogus.svg" alt="bogus" className="border rounded payCardSize mx-3"/>
                        Bogus (for testing)
                        </label>
                    </div>
                    <div className="collapse" id="collapseExample">
                        <div className="alert alert-primary d-flex align-items-center mt-4" role="alert">
                            <div className="mr-3">
                                <i className="fa fa-info-circle fs-4 text-danger" aria-hidden="true"></i>
                            </div>
                            <div>You must add a payment method to your account.</div>
                        </div>
                    </div>

                </div>
    
    
           </div>
        </React.Fragment> 
    )
}
export default PaymentMethodOption