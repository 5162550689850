import React from 'react'
import { Link } from 'react-router-dom';

const GiftCard = () => {
  return (
    <React.Fragment>
      <div className='content-wrapper topSpace'>
        <div className="container-fluid">
          <div className="container px-4">
            <div className="row fw-normal fs-5 pb-2">
              Products
            </div>
            <div className="row rounded " style={{ background: 'white' }}>
              <div className="text-center p-md-5">
                <img alt="customer-logo" className="img-fluid" src="/images/customer.svg" />
                <p className="fs-4">Digital gift cards</p>
                <p className="text-secondary text-sm">Gift cards can be sold as a product, or sent directly to customers.</p>

                <div className='row justify-content-center'>
                  <div className='col-md-4'>
                      <Link to="/products/AddGiftCard">
                        <button type="button" className="btn btnColorCustome btnColorOnHover w-100">Add gift card product</button>
                      </Link>
                      <p className='pt-1 text-secondary text-sm'>Let customers buy gift cards from your store</p>
                  </div>
                  <div className='col-md-4'>
                      <Link to="/products/IssueGiftCard">
                        <button type="button" className="btn btnColorCustome btnColorOnHover w-100">Issue gift card</button>
                      </Link>
                      <p className='pt-1 text-secondary text-sm'>Send a gift card code directly to a customer</p>
                  </div>
                    <p className='pt-3 text-secondary text-sm'>By using gift cards, you agree to our <a href='#'>Terms of Service.</a></p>  

                </div>
             
              </div>
            </div>
            <p className='text-center py-3'>Learn more about <a href='#'>gift cards</a></p>

          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default GiftCard