
import React from 'react'

const CustomerEventModal = () => {
  return (
    <React.Fragment>

        {/* -- Add custom pixel modal -- */}
        <div className="modal fade" id="AddCustomPixelModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add custom pixel</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                       <p className='text-sm text-secondary'>Did you check available <a href="#">apps</a> first? Apps are the most secure option, with automatic updates, for integrating with third-party services.</p>
                        <div>
                            <label htmlFor="" className='fw-normal'>Pixel name</label>
                            <div className="input-group">
                                <input type="text" className="form-control border-right-0 border" />
                                <span className="input-group-text bg-white px-1 text-sm">0/30</span>
                            </div>
                        </div>
                        <p className='text-sm text-secondary mt-3'>
                            This is an advanced feature that requires JavaScript knowledge. ecomBuilder is not responsible for your use of pixels. Compliance with applicable laws, consents, code security, troubleshooting, and updates are your responsibility. Pixels are subject to the <a href="#">ecomBuilder Terms of Service</a>.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Add pixel</button>
                    </div>
                </div>  
            </div>
        </div>

    </React.Fragment>
  )
}

export default CustomerEventModal