
import React from 'react'
import { Link } from 'react-router-dom'

const SettingPayments = () => {
  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <h5>Payments</h5>
            <div className='border-top my-4'></div>

            {/* Payment */}
            <div className='row'>
                    {/* content in col-8 */}
                <div className='col-md-8'>
                    {/* Payment providers */}
                    <div className='card p-3'>
                        <h6>Payment providers</h6>
                        <p className='text-secondary py-2 text-sm'>Providers that enable you to accept payment methods at a rate set by the third-party. An additional fee will apply to new orders once you <a href="/plans">select a plan</a>.</p>
                        <div>
                            <Link to="/setting/payment/Third_party_provider" className='btn btnColorCustome btnColorOnHover'>Choose a provider</Link>
                        </div>
                    </div>

                     {/* Supported payment methods */}
                    <div className='card p-3'>
                        <h6>Supported payment methods</h6>
                        <p className='text-secondary py-2 text-sm'>Payment methods that are available with one of Ecom builder's approved payment providers.</p>
                        <div className='border-top mb-3'></div>
                        <div className='card p-3 bg-light'>
                            <div className='d-flex justify-content-between'>
                                <div className=''>
                                    <img className='rounded border' src="/images/paypal.svg" alt="paypal" style={{width:"3rem", height:"2rem"}}/>
                                </div>
                                <div>
                                    <Link to="/setting/payment/paypal_express_checkout">Activate PayPal</Link>
                                </div>
                            </div>
                            <div className='border-top my-2'></div>
                            <div className='d-flex justify-content-between'>
                               <div>
                                 <span className='text-secondary'>Provider</span><br />
                                 <span>PayPal</span>
                               </div> 
                               <div>
                                 <span className='text-secondary'>Status</span><br />
                                 <span className='text-sm rounded-pill bg-warning px-2'>Inactive</span>
                               </div> 
                               <div>
                                 <span className='text-secondary'>Transaction Fee</span><br />
                                 <span>0%</span>
                               </div> 
                            </div>
                        </div>
                        
                        <div className='mt-3'>
                            <Link to="/setting/payment/add_payment_methods" className='btn btnColorCustome btnColorOnHover'>Add payments methods</Link>
                        </div>
                    </div>

                      {/* Manual payment methods */}
                    <div className='card p-3'>
                        <h6>Manual payment methods</h6>
                        <p className='text-secondary py-2 text-sm'>Payments that are made outside your online store. When a customer selects a manual payment method such as cash on delivery, you'll need to approve their order before it can be fulfilled.</p>
                        <div>
                            <ul className='pl-0 mb-0'>
                                <li className="nav-item dropdown">
                                    <button  className="btn btnColorCustome btnColorOnHover" data-toggle="dropdown">Add manual payment method <i className="fa fa-caret-down ml-2" aria-hidden="true"></i></button>

                                    <div className="dropdown-menu dropdown-menu-left">
                                    <a href="#" className="dropdown-item text-secondary" data-bs-toggle="modal" data-bs-target="#customPayModal">
                                        Create custom payment method 
                                    </a>
                                    <a href="#" className="dropdown-item text-secondary" data-bs-toggle="modal" data-bs-target="#bankDepositPayModal">
                                        Bank Deposit
                                    </a>
                                    <a href="#" className="dropdown-item text-secondary" data-bs-toggle="modal" data-bs-target="#moneyOrderPayModal">
                                        Money Order 
                                    </a>
                                    <a href="#" className="dropdown-item text-secondary" data-bs-toggle="modal" data-bs-target="#CODPayModal"> 
                                        Cash on Delivery(COD)
                                    </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                  
                   {/* contents in col-4 */}
                <div className='col-md-4'>
                    <div className='card p-3'>
                        <h6>Payment capture</h6>
                        <div className='my-2'>
                            <span className='text-sm rounded-pill bg-light px-2 py-1'>Automatic</span>
                        </div>
                        <p className='text-secondary text-sm'>The customer's payment method is authorized and charged automatically.</p>
                        <div>
                            <button className='btn btnColorCustome btnColorOnHover' data-bs-toggle="modal" data-bs-target="#payCaptureManageModal">Manage</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center pt-3'>
                <p>Learn more about <a href="#">payments</a>.</p>
            </div>
        </div> 

              {/* -- Set up manual payment method modal -- */}
            <div className="modal fade" id="customPayModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Set up manual payment method</h5>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='pt-2'>
                                <label htmlFor="" className='fw-normal'>Custom payment method name</label>
                                <input type="text"  className='form-control shadow-none'/>
                            </div>
                            <div className='pt-3'>
                                <label htmlFor="" className='fw-normal'>Additional details</label>
                                <textarea className='form-control shadow-none' style={{resize:"none"}}/>
                            </div>
                            <p className='pt-1 text-secondary text-sm'>Displays to customers when they're choosing a payment method.</p>

                            <div className=''>
                                <label htmlFor="" className='fw-normal'>Payment instructions</label>
                                <textarea className='form-control shadow-none'style={{resize:"none"}}/>
                            </div>
                            <p className='pt-1 text-secondary text-sm'>Displays to customers after they place an order with this payment method.</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btnColorCustome btnColorOnHover">Active</button>
                        </div>
                    </div>
                </div>
            </div>

              {/* Set up Bank Deposit modal */}
            <div className="modal fade" id="bankDepositPayModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Set up Bank Deposit</h5>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='pt-3'>
                                <label htmlFor="" className='fw-normal'>Additional details</label>
                                <textarea className='form-control shadow-none' style={{resize:"none"}}/>
                            </div>
                            <p className='pt-1 text-secondary text-sm'>Displays to customers when they're choosing a payment method.</p>

                            <div className=''>
                                <label htmlFor="" className='fw-normal'>Payment instructions</label>
                                <textarea className='form-control shadow-none'style={{resize:"none"}}/>
                            </div>
                            <p className='pt-1 text-secondary text-sm'>Displays to customers after they place an order with this payment method.</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btnColorCustome btnColorOnHover">Active Bank Deposit</button>
                        </div>
                    </div>
                </div>
            </div>

              {/* Set up Money Order modal  */}
            <div className="modal fade" id="moneyOrderPayModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Set up Money Order modal</h5>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='pt-3'>
                                <label htmlFor="" className='fw-normal'>Additional details</label>
                                <textarea className='form-control shadow-none' style={{resize:"none"}}/>
                            </div>
                            <p className='pt-1 text-secondary text-sm'>Displays to customers when they're choosing a payment method.</p>

                            <div className=''>
                                <label htmlFor="" className='fw-normal'>Payment instructions</label>
                                <textarea className='form-control shadow-none'style={{resize:"none"}}/>
                            </div>
                            <p className='pt-1 text-secondary text-sm'>Displays to customers after they place an order with this payment method.</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btnColorCustome btnColorOnHover">Active Money Order</button>
                        </div>
                    </div>
                </div>
            </div>

              {/* Set up Cash on Delivery (COD) modal  */}
            <div className="modal fade" id="CODPayModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Set up Cash on Delivery (COD)</h5>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='pt-3'>
                                <label htmlFor="" className='fw-normal'>Additional details</label>
                                <textarea className='form-control shadow-none' style={{resize:"none"}}/>
                            </div>
                            <p className='pt-1 text-secondary text-sm'>Displays to customers when they're choosing a payment method.</p>

                            <div className=''>
                                <label htmlFor="" className='fw-normal'>Payment instructions</label>
                                <textarea className='form-control shadow-none'style={{resize:"none"}}/>
                            </div>
                            <p className='pt-1 text-secondary text-sm'>Displays to customers after they place an order with this payment method.</p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btnColorCustome btnColorOnHover">Active Cash on Delivery(COD)</button>
                        </div>
                    </div>
                </div>
            </div>

              {/* -- payment capture --> manage modal -- */}
            <div className="modal fade" id="payCaptureManageModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                           <p className='text-sm'>Choose between <a href="#">automatic and manual capture</a>.</p>
                     
                            <div className="form-check">
                                <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                <label className="form-check-label text-sm" htmlFor="flexRadioDefault1">
                                    <b>Automatically</b> capture payment for orders. <br />
                                    <span className='text-secondary'>The customer's payment method is authorized and charged automatically.
                                    </span>
                                </label>
                            </div>
                            <div className="form-check pt-3">
                                <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"/>
                                <label className="form-check-label text-sm" htmlFor="flexRadioDefault1">
                                    <b>Manually </b> capture payment for orders. <br />
                                    <span className='text-secondary'>The customer's payment method is authorized at the time of their order. You'll need to manually capture payment within the authorization period.</span>
                                </label>

                                <div className="collapse" id="collapseExample">
                                    <div className="form-check pt-1 ml-1">
                                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckChecked" defaultChecked />
                                        <label className="form-check-label text-sm" htmlFor="flexCheckChecked">
                                        Send a warning email when authorizations are close to expiring.
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btnColorCustome btnColorOnHover" disabled>Save</button>
                        </div>
                    </div>
                </div>
            </div>

             
    </React.Fragment>
  )
}

export default SettingPayments