import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useState } from 'react';

// async function loginUser(credentials) {
//     return fetch('http://3.14.168.174/signIn', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',

//         },
//         body: JSON.stringify(credentials)
//     })
//         .then(data => data.json())
// }

function Userlogin() {
    const [state, setState] = useState({
        email: "",
        password: "",
        name: "",
        username: "",
        cnfrmpassword: "",
        agreeTC: ""
    });
    // const [password, setPassword] = useState();

    const handleLoginSubmit = (e) => {
        console.log(state)
        e.preventDefault()
        if (state.email === "abc@gmail.com" && state.password === "123") {
            localStorage.setItem('accessToken', "mynameisrishabhitsafaketoken");
            localStorage.setItem('storeIs',"false");
            window.location.href = "/";
        }
        e.preventDefault()
    }

    const HandelRegister=(e)=>{
        e.preventDefault()
        console.log(state);

    }

    const handleChange=(evt)=>{
        const value=evt.target.value;
        setState({
        ...state,
        [evt.target.name]: value
      });
      console.log(state)
    }

    
    
    return (
        <React.Fragment>
           
            <div className='containerfrol'>
                <div className='userScreen p-3'>
                    <ul className="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                                {/* login button */}
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" type='button' id="tab-login" data-bs-toggle="pill" 
                            data-bs-target="#pills-login" 
                            role="tab"
                            aria-controls="pills-login" 
                            aria-selected="true">Login</button>
                        </li>
                                {/* Register button */}
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" type='button' id="tab-register" data-bs-toggle="pill" 
                            data-bs-target="#pills-register" 
                            role="tab" 
                            aria-controls="pills-register" 
                            aria-selected="false">Register</button>
                        </li>
                    </ul>

                    {/* Tab content */}
                    <div className="tab-content">

                        {/* Login form */}
                        <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
                            <form onSubmit={handleLoginSubmit}>
                                <div className="text-center my-4">
                                    <p>Sign in with</p>
                                    <button type="button" className="btn btn-secondary btn-floating mx-1">
                                        <i className="fab fa-facebook-f" />
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-floating mx-1">
                                        <i className="fab fa-google" />
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-floating mx-1">
                                        <i className="fab fa-twitter" />
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-floating mx-1">
                                        <i className="fab fa-github" />
                                    </button>
                                </div>
                                <p className="text-center">OR</p>

                                {/* Email input */}
                                <div className="form-outline mb-3">
                                    <label className="fw-normal" htmlFor="loginName">Email or Mobile No</label>
                                    <input type="text" id="loginName" name="email" className="form-control" onChange={handleChange}/>
                                </div>
                                {/* Password input */}
                                <div className="form-outline mb-3">
                                    <label className="fw-normal" htmlFor="loginPassword">Password</label>
                                    <input type="password" id="loginPassword" className="form-control" name="password" onChange={handleChange}/>
                                </div>
                            
                                <div className="row mb-4">
                                    <div className="col-sm-6 d-flex justify-content-center">
                                        <div className="form-check mb-3 mb-md-0">
                                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="loginCheck" defaultChecked />
                                            <label className="form-check-label" htmlFor="loginCheck"> Remember me </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 d-flex justify-content-center">
                                        <a href="#">Forgot password?</a>
                                    </div>
                                </div>
                                
                                {/* Submit button */}
                                <button type="submit" className="btn btn-primary btn-block mb-4">Sign in</button>
                            </form>
                        </div>

                                {/* Register form */}
                        <div className="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="tab-register">
                            <form>
                                <div className="text-center my-4">
                                    <p>Sign up with</p>
                                    <button type="button" className="btn btn-secondary btn-floating mx-1">
                                        <i className="fab fa-facebook-f" />
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-floating mx-1">
                                        <i className="fab fa-google" />
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-floating mx-1">
                                        <i className="fab fa-twitter" />
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-floating mx-1">
                                        <i className="fab fa-github" />
                                    </button>
                                </div>
                                <p className="text-center">OR</p>
                                {/* Name input */}
                                <div className="form-outline mb-4">
                                    <label className="fw-normal" htmlFor="registerName">Name</label>
                                    <input type="text" id="registerName" name="name" onChange={handleChange} className="form-control" />
                                </div>
                                {/* Username input */}
                                <div className="form-outline mb-4">
                                    <label className="fw-normal" htmlFor="registerUsername">Username</label>
                                    <input type="text" id="registerUsername" name="username" onChange={handleChange} className="form-control" />
                                </div>
                                {/* Email input */}
                                <div className="form-outline mb-4">
                                    <label className="fw-normal" htmlFor="registerEmail">Email</label>
                                    <input type="email" id="registerEmail" name="email" onChange={handleChange} className="form-control" />
                                </div>
                                {/* Password input */}
                                <div className="form-outline mb-4">
                                    <label className="fw-normal" htmlFor="registerPassword">Password</label>
                                    <input type="password" id="registerPassword" name="password" onChange={handleChange} className="form-control" />
                                </div>
                                {/* Repeat Password input */}
                                <div className="form-outline mb-4">
                                    <label className="fw-normal" htmlFor="registerRepeatPassword">Confirm password</label>
                                    <input type="password" id="registerRepeatPassword" name="cnfrmpassword" onChange={handleChange} className="form-control" />
                                </div>
                                {/* Checkbox */}
                                <div className="form-check mb-4 text-center">
                                    <input className="form-check-input shadow-none" name="agreeTC" onChange={handleChange} type="checkbox" defaultValue id="registerCheck" defaultChecked aria-describedby="registerCheckHelpText" />
                                    <label className="form-check-label" htmlFor="registerCheck">
                                        I have read and agree to the terms
                                    </label>
                                </div>

                                {/* Submit button */}
                                <button type="submit" className="btn btn-primary btn-block mb-3" onClick={HandelRegister}>Sing Up</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>


            {/* <div className="containerfrol">
                <div className="screen">
                    <div className="screen__content">

                        <form className="login" noValidate onSubmit={handleSubmit} >
                            <div className="login__field">
                                <i className="login__icon fas fa-user"></i>
                                <input type="text" className="login__input" placeholder="User name / Email" onChange={e => setUserName(e.target.value)} />
                            </div>
                            <div className="login__field">
                                <i className="login__icon fas fa-lock"></i>
                                <input type="password" className="login__input" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                            </div>
                            <button className="button login__submit">
                                <span className="button__text">Log In Now</span>
                                <i className="button__icon fas fa-chevron-right"></i>
                            </button>
                        </form>
                        
                        <div className="social-login">
                            <h3>log in via</h3>
                            <div className="social-icons">
                                <a href="#" className="social-login__icon fab fa-instagram"></a>
                                <a href="#" className="social-login__icon fab fa-facebook"></a>
                                <a href="#" className="social-login__icon fab fa-twitter"></a>
                            </div>
                        </div>

                    </div>
                    <div className="screen__background">
                        <span className="screen__background__shape screen__background__shape4"></span>
                        <span className="screen__background__shape screen__background__shape3"></span>
                        <span className="screen__background__shape screen__background__shape2"></span>
                        <span className="screen__background__shape screen__background__shape1"></span>
                    </div>
                </div>
            </div> */}


        </React.Fragment>
    )
}

export default Userlogin