
import React from 'react'
import { Link } from 'react-router-dom'


const SettingPlan = () => {
  return (
    <React.Fragment>
        <div className='pl-md-3' >
            <h5>Plan</h5>
            <div className='border-top my-4'></div>

              {/* -- Plan -- */}
              <div className=''>
                  <h5 className='fw-normal'>Plan details</h5>
                  <p className='text-secondary text-sm'>Manage or change your Shopify plan. View our <a href="#">terms of service</a> and <a href="#">privacy policy</a>.</p>
              </div>
              
              <div className='card p-3'>
                <div className='pt-2'>
                  <h6 className='fw-normal'>Trial</h6>
                  <p className='text-secondary text-sm'>You are in a trial store.</p>
                </div>

                <div className='border-top my-2'></div>

                <div className='pt-2'>
                  <h6 className='fw-normal'>Next billing date</h6>
                  <p className='text-secondary text-sm'>Your trial has 2 days remaining</p>
                </div>

                <div className='border-top my-2'></div>

                <div className='pt-2'>
                  <div className='d-flex justify-content-between'>
                    <h6 className='fw-normal'>Payment method</h6>
                    <Link to="/setting/billing" className='text-sm'>Update payment method</Link>
                  </div>

                  <p className='text-secondary text-sm'>No payment method added</p>
                </div>
                <div className='border-top my-2'></div>
                <div className='text-right py-3'>
                  <button type="button" className="btn btn-outline-danger mr-3">Deactivate trial</button>
                  <button type='button' className='btn btnColorCustome btnColorOnHover'>
                      Choose plan
                  </button>
                </div>

              </div>

        </div>
    </React.Fragment>
  )
}

export default SettingPlan
