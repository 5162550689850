import React, { useEffect } from 'react';


export default function Plan() {

    useEffect(() => {
        const scriptFile = document.createElement("script");
        scriptFile.src = "/Custom.js"; //your js file path 
        //scriptFile .async = true; 
        document.body.appendChild(scriptFile);
    });

    return (
        <div>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container '>
                        <div className="row">
                            <div className='mt-3'>
                                <img className='img-fluid' src="images/previous.png" />
                                <span className='fw-bold ml-3'>Pick a plan</span><br />
                                <span className='ml-5'>Cancel before October 24 and you won't be charged.</span>


                            </div>

                        </div>
                        <hr class="border border-1 opacity-50" />
                        <div className="row">
                            <div class="tile" id="tile-1">

                                {/* <!-- Nav tabs --> */}
                                <ul class="nav nav-tabs nav-justified" role="tablist">
                                    <div class="slider"></div>
                                    <li class="nav-item">
                                        <a class="nav-link " id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"> Monthly</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"> Yearly (save 10%)</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false"> 2 Years (save 20%)</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="setting-tab" data-toggle="tab" href="#setting" role="tab" aria-controls="setting" aria-selected="false"> 3 Years (save 30%)</a>
                                    </li>
                                </ul>

                                {/* <!-- Tab panes --> */}
                                {/* <div class="tab-content">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">Home</div>
                                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">Profile</div>
                                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">Contact</div>
                                    <div class="tab-pane fade" id="setting" role="tabpanel" aria-labelledby="setting-tab">Settings</div>
                                </div> */}




                            </div>
                            <div className="row">
                                <div class="col-sm m-1">
                                    <div className="populer position-absolute fcw pl-3 pr-3 ml-3 rounded">Populer</div>
                                    <ul class="price">

                                        <li>
                                            <p className='fw-bolder fs-5'>Basic Ecom-prod</p>
                                            <p className='fs-6'>All the basics for starting a new business</p>
                                            <p className='mt-5'><span className='fcg fw-bolder fs-3'>₹1,499</span> INR / month</p>
                                            <a href='/plan-info'><button className='choseplan bg-grenncolor fcw mt-2 fw-bolder'>Choose the plan</button></a>
                                        </li>

                                        <li>
                                            <p>PAYMENTS</p>
                                            <p className=' lh-1'><span className='fw-bolder'>Transaction fees for all payment providers</span><br />
                                                <div className='mt-2'>2.0%</div> </p>

                                            <p className=' lh-1 mt-4'><span className='fw-bolder'>Currency conversion fee</span><br />
                                                <div className='mt-2'>2.0%</div> </p>
                                        </li>
                                        <li style={{ borderBottom: "none" }}>
                                            <p>FEATURES</p>
                                            <p><i class="fa fa-user" aria-hidden="true"></i> &nbsp; 2 staff members</p>
                                            <p><i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp; Up to 4 locations</p>
                                        </li>



                                    </ul>
                                </div>

                                <div class="col-sm m-1">
                                    <ul class="price">

                                        <li> <p className='fw-bolder fs-5'> Ecom-prod</p>
                                            <p className='fs-6'>All the basics for starting a new business</p>
                                            <p className='mt-5'><span className='fcg fw-bolder fs-3'>₹1,499</span> INR / month</p>
                                            <a href='/plan-info'><button className='choseplan  fcb mt-2 fw-bolder'>Choose the plan</button></a></li>
                                        <li><p>PAYMENTS</p>
                                            <p className=' lh-1'><span className='fw-bolder'>Transaction fees for all payment providers</span><br />
                                                <div className='mt-2'>2.0%</div> </p>

                                            <p className=' lh-1 mt-4'><span className='fw-bolder'>Currency conversion fee</span><br />
                                                <div className='mt-2'>2.0%</div> </p>
                                        </li>
                                        <li >
                                            <p>FEATURES</p>
                                            <p><i class="fa fa-user" aria-hidden="true"></i> &nbsp; 2 staff members</p>
                                            <p><i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp; Up to 4 locations</p>
                                            <p><i class="fa fa-line-chart" aria-hidden="true"></i> &nbsp;
                                                Professional reports</p>
                                        </li>
                                        <li >
                                            <p>FEATURES</p>
                                            <p><i class="fa fa-user" aria-hidden="true"></i> &nbsp; 2 staff members</p>
                                            <p><i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp; Up to 4 locations</p>
                                            <p><i class="fa fa-line-chart" aria-hidden="true"></i> &nbsp;
                                                Professional reports</p>
                                        </li><li style={{ borderBottom: "none" }} >
                                            <p>SHIPPING</p>
                                            <p>USPS Priority Mail Cubic® pricing</p>

                                        </li>

                                    </ul>
                                </div>

                                <div class="col-sm m-1">
                                    <ul class="price">

                                        <li> <p className='fw-bolder fs-5'>Advanced Ecom-prod</p>
                                            <p className='fs-6'>All the basics for starting a new business</p>
                                            <p className='mt-5'><span className='fcg fw-bolder fs-3'>₹1,499</span> INR / month</p>
                                            <a href='/plan-info'><button className='choseplan  fcb  mt-2 fw-bolder'>Choose the plan</button></a></li>
                                        <li><p>PAYMENTS</p>
                                            <p className=' lh-1'><span className='fw-bolder'>Transaction fees for all payment providers</span><br />
                                                <div className='mt-2'>2.0%</div> </p>

                                            <p className=' lh-1 mt-4'><span className='fw-bolder'>Currency conversion fee</span><br />
                                                <div className='mt-2'>2.0%</div> </p>
                                            <p className=' lh-1 mt-4'><span className='fw-bolder'>Duties and import taxes rates</span><br />
                                                <div className='mt-2'>0.85%</div>
                                                <div className='mt-2'>If you are using payment providers other than Shopify Payments: 1.5%</div>

                                            </p>
                                        </li>
                                        <li >
                                            <p>FEATURES</p>
                                            <p><i class="fa fa-user" aria-hidden="true"></i> &nbsp; 2 staff members</p>
                                            <p><i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp; Up to 4 locations</p>
                                            <p><i class="fa fa-line-chart" aria-hidden="true"></i> &nbsp;
                                                Professional reports</p>
                                            <p><i class="fa fa-bar-chart" aria-hidden="true"></i> &nbsp;
                                                Professional reports</p>
                                            <p><i class="fa fa-usd" aria-hidden="true"></i> &nbsp;
                                                Professional reports</p>
                                            <p><i class="fa fa-percent" aria-hidden="true"></i> &nbsp;
                                                Duties and import taxes</p>
                                        </li>
                                        <li style={{ borderBottom: "none" }} >
                                            <p>SHIPPING</p>
                                            <p>USPS Priority Mail Cubic® pricing</p>
                                            <p>Third-party calculated shipping rates <br /><span className='fs12'>Show calculated rates with your own account or third-party apps at checkout.</span></p>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className='row justify-content-center pt-3'>
                        <div className='col-md-10 col-12'>
                            <ul class="starter">
                                <li>
                                    <p className='fs-4 fw-bolder'>Starter</p>
                                    <p>The simplest way to start selling through social and messaging apps</p>
                                    <p className='pricepermnth mt-5 '><span className='fs-2 fcg'>₹399</span> &nbsp; INR / month</p>
                                    <p> <button type="button" class="btn  btn-light border border-success">Choose this Plan</button></p>
                                </li>
                                <li>
                                    <p>PAYMENTS</p>
                                    <p className='fw-bolder'>Transaction fees for all payment providers</p>
                                    <p>5.0%</p>
                                </li>
                                <li>
                                    <p>FEATURES</p>
                                    <p className='fw-bolder'><i class="fa fa-user-circle" aria-hidden="true"></i> &nbsp; 1 user</p>
                                    <p> <i class="fa fa-tag" aria-hidden="true"></i>&nbsp; Shareable product pages</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='row justify-content-center pt-3'>
                        <div className='col-md-10 col-12'>
                            <ul class="starter">
                                <li>
                                    <p>ALL PLANS INCLUDE:</p>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p>BASIC, SHOPIFY, AND ADVANCED PLANS INCLUDE:</p>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <p><i class="fa fa-check-circle text-success" aria-hidden="true"></i> &nbsp;  Shareable product pages</p>
                                        </div>
                                    </div>

                                </li>

                            </ul>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div >
    )
}
