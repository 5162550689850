import React from 'react'

function Floatingbanner() {
    return (
        <>

            <div className="row">

            </div>
        </>
    )
}

export default Floatingbanner