
import React from 'react'

const TaxesDutiesModal = () => {
  return (
    <React.Fragment>

         {/* Country --> Reset to default tax rates ->> Modal */}
        <div className="modal fade" id="resetDefaultTaxModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Reset to default tax rates</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <p className='text-sm'>Are you sure you want to reset all your taxes for India? This will set both the country tax and all region taxes to their current default values.
                        </p>
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="removeTaxCheck" />
                            <label className="form-check-label" htmlFor="removeTaxCheck">
                            Remove all tax overrides
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light text-dark border" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-danger">Reset tax rates</button>
                    </div>
                </div>
            </div>
        </div>

         {/* Country --> Add a tax override ->> Modal */}
        <div className="modal fade" id="addOverrideTaxModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add tax override for India</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body addOverrideTaxModalHeight">
                        <div className='py-1'>Add tax override for :</div>

                            {/* Products checkbox*/}
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="overrideTax" id="ProductRadio"/>
                            <label className="form-check-label" htmlFor="ProductRadio">
                            Products
                            </label>
                        </div>

                             {/*  Shipping checkbox*/}
                        <div className="form-check ml-1">
                            <input className="form-check-input shadow-none" type="radio" name="overrideTax" id="shippingRadio"/>
                            <label className="form-check-label" htmlFor="shippingRadio">
                            Shipping
                            </label>
                        </div>

                        <div className='forOverride'>
                            <div className='my-3 text-sm'>
                                Select a collection to apply the tax override to. You can also <a href="#">create a new collection</a>. 
                            </div> 
                            <div className="dropdown mt-2">
                                <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Select collection
                                </button>
                                <ul className="dropdown-menu dropdown-lg selectCollScrollable-menu py-0"  aria-labelledby="dropdownMenuButton1">
                                    <li className='p-3'>
                                        <div className="input-group">
                                            <span className="input-group-append">
                                                <div className="input-group-text bg-white rounded-start"><i className="fa fa-search text-secondary"/>
                                                </div>
                                            </span>
                                            <input className="form-control border-left-0 border pl-0" type="search"/>
                                        </div>
                                    </li>
                                    <li className="dropdown-divider my-0"></li>
                                    <li className="p-3 selectColectionLI">
                                    Home page</li>
                                    <li className="dropdown-divider my-0"></li>
                                    <li className="p-3">
                                        <div className="btn-group btn-group-sm" role="group" aria-label="Basic outlined example">
                                            <button type="button" className="btn btn-outline-light border">
                                                <i class="fa fa-chevron-left px-2" aria-hidden="true"></i>
                                            </button>
                                               <button type="button" className="btn btn-outline-light border">
                                                <i class="fa fa-chevron-right px-2" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>  

                            {/* Location/tax rate input*/}
                        <div className='row'>
                            <div className='col-sm-6 mt-3'>
                                <label htmlFor="" className='fw-normal'>Location</label>
                                <select className="form-select shadow-none border" aria-label="Default select example">
                                    <option>India</option>
                                    <option>India</option>
                                    <option>India</option>
                                    <option>India</option>
                                    <option>India</option>
                                </select>
                            </div>
                            <div className='col-sm-6 mt-3'>
                                <label htmlFor="" className='fw-normal'>Tax rate</label>
                                <div className="input-group">
                                    <input type="text" className="form-control border border-right-0"/>
                                    <span className="input-group-text bg-white">%</span>
                                </div>
                            </div>
                        </div>  
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light text-dark border" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Add override</button>
                    </div>
                </div>
            </div>
        </div>

    </React.Fragment>
  )
}

export default TaxesDutiesModal
