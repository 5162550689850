
import React from 'react'
import { useNavigate } from 'react-router-dom'

const StatementOfCharges = () => {
    let navigate = useNavigate();
  return (
    <React.Fragment>
        <div className='pl-md-3'>
            {/* for going back btn */}         
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Statement of charges</span>
            </div> 
            <div className='border-top my-4'></div>
                     {/* alert message */}
                <div className="alert alert-primary d-flex align-items-center mt-4" role="alert">
                    <div className='mr-3'>
                        <i className="fa fa-info-circle fs-4 text-info" aria-hidden="true"></i>
                    </div>
                    <div className='py-2'>
                         Other financial data, such as <a href="#" className='text-dark'>Capital</a> , has moved.
                    </div>
                </div>

            <div>
                <button type='button' className='btn btnColorCustome btnColorOnHover'> <i className="fa fa-calendar-check-o mr-1"></i> Month to date</button>
            </div>
            <div className='text-center mt-5 pt-5'>
                <h5>No charges were found for the dates selected.</h5>
                <p className='text-sm'>Charges are incurred when you've been invoiced for things like your Shopify subscription, apps, themes, and shipping labels.</p>
            </div>    

        </div>

    </React.Fragment>
  )
}

export default StatementOfCharges