
import React from 'react'
import CustomerEventModal from './SettingCompModal/CustomerEventModal'

const SettingCustomerEvent = () => {
  return (
    <React.Fragment>
        <CustomerEventModal/>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <h5>Customer events</h5>
            <div className='border-top my-4'></div>
            <div className='card p-3'>
                <h6>Pixels</h6>
                <p className='text-sm text-secondary'>Manage pixels that receive your customer events data.  These include custom pixels added in this section and app pixels installed by apps. Learn more about <a href="#">pixels</a>.
                </p>
            </div>

                {/* custom/app pixels */}
            <div className='card'>
                <div className='navTabStyle pt-3'>
                    <ul className="nav nav-pills">
                        <li className='ml-3'>
                            <a data-toggle="pill" className='text-secondary' href="#menu1">Custom pixels 
                            <span className='px-2 py-1  ml-1 text-sm spanBg rounded-circle'>0</span>
                            </a>
                        </li>   
                        <li className='ml-3'>
                            <a data-toggle="pill" className='text-secondary' href="#menu2">App pixels
                            <span className='px-2 py-1  ml-1 text-sm spanBg rounded-circle'>0</span>
                            </a>
                        </li>
                    </ul>   
                    <div className="tab-content p-3">
                        <div id="menu1" className="tab-pane fade show active">
                            <div className='text-center py-3'>
                                <h5>No custom pixels added</h5>
                                <p className='text-sm text-secondary'>This is where you can manage pixels that you manually add on your online store.</p>
                                <a href="#">Learn about custom pixels</a>
                                <div className='mt-3'>
                                    <button className='btn btnColorCustome btnColorOnHover' data-bs-toggle="modal" data-bs-target="#AddCustomPixelModal">Add custom pixel</button>
                                </div>
                            </div>
                        </div>
                        <div id="menu2" className="tab-pane fade">
                            <div className='text-center py-3'>
                                <h5>No app pixels installed</h5>
                                <p className='text-sm text-secondary'>This is where you can preview pixels automatically installed by apps.</p>
                                <a href="#">Learn about app pixels</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="alert alert-primary alert-dismissible fade show" role="alert">
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
              <h6>Analyze customer behavior with pixels</h6>
                <p className='text-sm text-secondary'>Find out how pixels work, with step-by-step instructions for adding them to your online store and information on managing them over time.</p>
                <a href="#" className='btn btn-outline-light border text-dark text-decoration-none'>Read about pixels</a>
            </div>
            
        </div>
    </React.Fragment>
  )
}

export default SettingCustomerEvent