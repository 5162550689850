import React from 'react'
import { useNavigate } from 'react-router-dom'

export const AddCustomer = () => {

  //   useEffect(() => {
  //     const scriptFile = document.createElement("script");
  //     scriptFile.src = "/Custom.js"; //your js file path 
  //     //scriptFile .async = true; 
  //     document.body.appendChild(scriptFile);
  // });

  let navigate = useNavigate();
 
  return (
    <div className='content-wrapper topSpace' >
      <div className="container-fluid">
        <div className='container'>

          {/* for going back */}
            <div className="row justify-content-center">
                <div className="col-md-11 pt-2">
                    <div className='d-flex'>
                        <img className='img-fluid' data-bs-toggle="modal" data-bs-target="#previousBtn" src="/images/previous.png" />
                        <span className='ml-2 fw-normal fs-5'>New customer</span>
                    </div>
                </div>
            </div>

          {/* open modal on going back */}
              <div className="modal fade" id="previousBtn" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <p className="modal-title fs-5" id="exampleModalLabel">Leave page with unsaved changes?</p>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                       Leaving this page will delete all unsaved changes.
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Stay</button>
                      <button type="button" className="btn btn-danger" onClick={() => {navigate(-1); window.location.reload(false)}}>Leave page</button>
                    </div>
                  </div>
                </div>
              </div>
    

          {/* for giving border */}
          <div className='row justify-content-center'>
            <div className='col-md-11'>
              <div className='myBorder pt-3'></div>
            </div>
          </div>

          {/* Customer overview form */}
          <div className='row justify-content-center pt-3'>
            <div className='col-md-11'>
              <div className='row'>
                <div className='col-md-4 pb-2 fs-5'>
                  Customer overview
                </div>
                <div className='col-md-8'>
                  <form className='card px-4 py-2'>
                    <div className="row form-group">
                      <div className='col-md-6'>
                        <label htmlFor="fname" className='fw-normal'>First Name</label>
                        <input type="text" className="form-control" id="fname" placeholder="Enter first name" />
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor="lname" className='fw-normal'>Last Name</label>
                        <input type="text" className="form-control" id="lname" placeholder="Enter last name" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="lang" className='fw-normal'>Language</label>
                      <select className="form-control" id="lang">
                        <option>English (default)</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email" className='fw-normal'>Email</label>
                      <input type="emial" className="form-control" id="email" />
                    </div>

                    <div className="row form-group">
                      <div className='col-9'>
                        <label htmlFor="phone" className='fw-normal'>Phone</label>
                        <input type="text" className="form-control" id="phone" />
                      </div>
                      <div className='col-3'>
                        <label htmlFor="c-code" className='fw-normal'>code</label>
                        <select className="form-control" id='c-code'>
                          <option>+91</option>
                          <option>+92</option>
                          <option>+93</option>
                          <option>+94</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDisabled" disabled />
                      <label className="form-check-label" htmlFor="flexCheckDisabled">
                        Customer agreed to receive marketing emails.
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDisabled" disabled />
                      <label className="form-check-label" htmlFor="flexCheckDisabled">
                        Customer agreed to receive SMS marketing text messages.
                      </label>
                    </div>
                    <p className='text-sm text-secondary pt-3'>You should ask your customers for permission before you subscribe them to your marketing emails or SMS.</p>
                  </form>
                </div>

              </div>
            </div>
          </div>

          {/* for giving border */}
          <div className='row justify-content-center'>
            <div className='col-md-11'>
              <div className='myBorder'></div>
            </div>
          </div>

          {/* Address  */}
          <div className='row justify-content-center pt-3'>
            <div className='col-md-11'>
              <div className='row'>
                <div className='col-md-4 pb-2 fs-5'>
                  Address
                  <p className='text-sm py-2 text-secondary'>The primary address of this customer</p>
                </div>
                <div className='col-md-8'>
                  <form className='card px-4 py-2'>
                    <div className="row form-group">
                      <div className='col-md-6'>
                        <label htmlFor="fname" className='fw-normal'>First Name</label>
                        <input type="text" className="form-control" id="fname" placeholder="Enter first name" />
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor="lname" className='fw-normal'>Last Name</label>
                        <input type="text" className="form-control" id="lname" placeholder="Enter last name" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="reg" className='fw-normal'>Country/region</label>
                      <select className="form-control" id="reg">
                        <option>India</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="company" className='fw-normal'>Company</label>
                      <input type="text" className="form-control" id="company" placeholder='Company' />
                    </div>
                    <div className="form-group">
                      <label htmlFor="address" className='fw-normal'>Address</label>
                      <input type="text" className="form-control" id="address" placeholder='Address' />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email" className='fw-normal'>Apartment, suite, etc.</label>
                      <input type="emial" className="form-control" id="email" placeholder='Apartment, suite, etc.' />

                    </div>

                    <div className="row form-group">
                      <div className='col-md-4'>
                        <label htmlFor="city" className='fw-normal'>City</label>
                        <input type="text" className="form-control" id="city" placeholder='city' />
                      </div>
                      <div className='col-md-4'>
                        <label htmlFor="state" className='fw-normal'>State</label>
                        <input type="text" className="form-control" id="state" placeholder='State' />
                      </div>
                      <div className='col-md-4'>
                        <label htmlFor="pincode" className='fw-normal'>PIN code</label>
                        <input type="text" className="form-control" id="pincode" placeholder='PIN code' />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className='col-9'>
                        <label htmlFor="phone" className='fw-normal'>Phone</label>
                        <input type="text" className="form-control" id="phone" />
                      </div>
                      <div className='col-3'>
                        <label htmlFor="c-code" className='fw-normal'>code</label>
                        <select className="form-control" id='c-code'>
                          <option>+91</option>
                          <option>+92</option>
                          <option>+93</option>
                          <option>+94</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>

          {/* for giving border */}
          <div className='row justify-content-center'>
            <div className='col-md-11'>
              <div className='myBorder'></div>
            </div>
          </div>

          {/*Tax exemptions*/}
          <div className='row justify-content-center pt-3'>
            <div className='col-md-11'>
              <div className='row'>
                <div className='col-md-4 pb-2 fs-5'>
                  Tax exemptions
                </div>
                <div className='col-md-8'>
                  <form className='card p-4'>
                    <div className="form-check">
                      <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                      <label className="form-check-label" htmlFor="flexCheckDefault">
                        Collect tax
                      </label>
                    </div>

                  </form>
                </div>

              </div>
            </div>
          </div>

          {/* for giving border */}
          <div className='row justify-content-center'>
            <div className='col-md-11'>
              <div className='myBorder'></div>
            </div>
          </div>

          {/*Notes*/}
          <div className='row justify-content-center pt-3'>
            <div className='col-md-11'>
              <div className='row'>
                <div className='col-md-4 pb-2 fs-5'>
                  Notes
                  <p className='text-sm text-secondary py-2'>Add notes about your customer.</p>
                </div>
                <div className='col-md-8'>
                  <form className='card px-4 py-2'>
                    <div className="form-group">
                      <label htmlFor="note" className='fw-normal'>Notel</label>
                      <input type="text" className="form-control" id="note" />
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>

          {/* for giving border */}
          <div className='row justify-content-center'>
            <div className='col-md-11'>
              <div className='myBorder'></div>
            </div>
          </div>

          {/* Tags*/}
          <div className='row justify-content-center pt-3'>
            <div className='col-md-11'>
              <div className='row'>
                <div className='col-md-4 pb-2 fs-5'>
                  Tags
                  <p className='text-sm text-secondary py-2'>Tags can be used to categorize customers into groups.</p>
                </div>
                <div className='col-md-8'>
                  <form className='card px-4 py-2'>
                    <div className="form-group">
                      <label htmlFor="tag" className='fw-normal'>Tags</label>
                      <input type="text" className="form-control" id="tag" />
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
