import React from "react";
import { Link } from 'react-router-dom';

const Transfers = () => {
  return (
    <React.Fragment>
      <div className='content-wrapper topSpace'>
        <div className="container-fluid">
          <div className="container px-4">
            <div className="row fw-normal fs-5 pb-2">
              Transfers
            </div>
            <div className="row rounded " style={{background:'white'}}>
              <div className="text-center p-md-5">
                <img alt="customer-logo" className="img-fluid" src="/images/customer.svg" />
                <p className="fs-5">Manage your incoming inventory</p>
                <p className="">You can track and receive incoming inventory from your suppliers and business locations.</p>

                <Link to="/products/CreateTransfer">
                  <button type="button" className="btn btnColorCustome btnColorOnHover m-2">Create transfer</button>
                </Link>
              </div>
            </div>
            <p className='text-center py-3'>Learn more about <a href='#'>inventory transfers</a></p>

          </div>
        </div>
      </div>


    </React.Fragment>
  );
}
export default Transfers;