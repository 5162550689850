
import React from 'react'
import { useNavigate } from 'react-router-dom'

const IssueGiftCard = () => {

    let navigate = useNavigate();
    
    return (
        <React.Fragment>
            <div className='content-wrapper topSpace' >
                <div className="container-fluid">
                    <div className='container'>

                        {/* for going back btn */}
                        <div className="row justify-content-center">
                            <div className="col-md-11 pt-2">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex'>
                                        <img className='img-fluid' data-bs-toggle="modal" data-bs-target="#previousBtn" src="/images/previous.png" onClick={() => { navigate(-1) }} />
                                        <span className='ml-2 fw-normal fs-5'>Issue gift card</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* for giving border */}
                        <div className='row justify-content-center'>
                            <div className='col-md-11'>
                                <div className='myBorder pt-3'></div>
                            </div>
                        </div>

                        <div className='row justify-content-center py-2'>
                            <div className='col-md-11'>
                                <div className='row'>
                                    {/* content in 8 col */}
                                    <div className='py-2 col-md-8'>
                                        {/* for issue gift card-> */}
                                        <div className='card p-3'>
                                            <div className="add-prod">
                                                <p className='fw-normal fs-5'>Gift card details</p>
                                                <div className="Issue-giftCard">
                                                   <div className="mb-3">
                                                        <label htmlFor="" className="form-label fw-normal">Gift card code</label>
                                                        <input type="text" className="form-control border"/>
                                                    </div>
                                                    <label htmlFor="" className='fw-normal'>Initial value</label>
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <div className="input-group-text bg-white rounded-start"><i className="fa fa-inr stext-secondary"/></div>
                                                        </span>
                                                        <input className="form-control border-left-0 border" type="text" id="pricing" placeholder='10.00'/>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        {/* --- Expiration date --- */}
                                        <div className='card p-3'>
                                            <div className="add-prod">
                                                <p className='fw-normal fs-5'>Expiration date</p>
                                                <div className="Issue-giftCard">
                                                    <p className='text-secondary text-sm'>Countries have different laws for gift card expiry dates. Check the laws for your country before changing this date.</p>

                                                    <div className="form-check">
                                                        <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                            No expiration date
                                                        </label>
                                                    </div>

                                                    <div className="form-check">
                                                        <input className="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1" 
                                                            data-bs-toggle="collapse" data-bs-target="#clickOnSetExpDate" aria-expanded="true" aria-controls="clickOnSetExpDate"/>
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                            Set expiration date
                                                        </label>
                                                    </div>
                                                    <div className='pt-4 collapse' id='clickOnSetExpDate'>
                                                        <label htmlFor="flexRadioDefault" className="form-label fw-normal">Expires on</label>
                                                        <input type="date" className="form-control border"/>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    {/* content in 4 col */}
                                    <div className='py-2 col-md-4'>
                                        {/* Find or create a customer */}
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-normal fs-5'>Find or create a customer</p>
                                                <p className='text-secondary text-sm'>To send the gift card code, add a customer with an email address or phone number.</p>
                                                <div className="input-group">
                                                    <span className="input-group-append">
                                                        <div className="input-group-text bg-white rounded-start "><i className="fa fa-search text-secondary" aria-hidden="true" /></div>
                                                    </span>
                                                    <input className="form-control border-left-0 rounded-end border" type="search" id="pricing" placeholder='Search customers' />
                                                </div>
                                            </div>
                                        </div>

                                    {/* Notes */}
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-normal fs-5'>Notes</p>
                                                    <input type="text" className="form-control border" placeholder='Add a note'/>
                                                <p className='text-secondary text-sm pt-3'>These notes are private and won't be shared with the customer.</p>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default IssueGiftCard;