
import axios from 'axios';
import React, { useState } from 'react'

function Storelogin() {

     const[data,setdata]=useState();
  

    const HandleSubmit = ()=>{
        // setStore("true");
        localStorage.setItem('storeIs',"true");
        window.location.href = "/";
    }



    return (

        <React.Fragment>
            <div className="containerfrol">
                <div className="storeScreen">
                    <div className="storelogin p-3">
                        <div className='d-flex justify-content-between'>
                            <div className='storeLogo fw-bolder fs-4'>Logo </div>
                            <div>
                                <ul className="m-0">
                                    <li className="db-header-user nav-item dropdown    border-none">
                                        <a className="nav-link text-dark" data-toggle="dropdown" href="/">
                                        <i className="fa fa-user-circle-o text-secondary" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                            <a href="#" className="dropdown-item">My Profile</a>
                                            <a href="#" className="dropdown-item">Billing Account</a>
                                            <a href="#" className="dropdown-item">Invoices & receipts</a>
                                            <a href="#" className="dropdown-item">Privacy Policy</a>
                                            <a href="#" className="dropdown-item">Terms and Service</a>
                                            <a href="#"  className="dropdown-item"> Logout</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className='text-center text-secondary fs-2 my-4'>Create a store</div>
                        <p className='text-sm'>This store will be connected to <a href="#">upendra.cityinnovates@gmail.com</a>.</p>
                    
                        <div className='mt-3'>
                            <div className='my-1'>Store name</div>  
                            <input type="text" className='form-control'/>
                        </div>
                        <div className='mt-3 border-top pt-3'>
                            <h5>Let's get started. Which of these best describes you?</h5>
                            <p className='text-sm text-secondary'>We'll help you get set up based on your business needs.</p>

                            <div className="form-check ml-1 mt-2">
                                <input className="form-check-input shadow-none" type="radio" name="started" id="starting" />
                                <label className="form-check-label" htmlFor="starting">
                                    I'm just starting
                                </label>
                            </div>

                            <div className="form-check ml-1 mt-2">
                                <input className="form-check-input shadow-none" type="radio" name="started" id="selling" />
                                <label className="form-check-label" htmlFor="selling">
                                    I'm already selling online or in person
                                </label>
                            </div>
                        </div>
                        <div className='mt-3 border-top py-3'>
                            <h5>Do you want to move an existing store to ecomBuilder ?</h5>
                            <p className='text-sm text-secondary'>When you're ready, we can help you import things like products, customers, and inventory.</p>
                            <div className="form-check ml-1 mt-2">
                                <input className="form-check-input shadow-none" type="radio" name="moveStore" id="yes" />
                                <label className="form-check-label" htmlFor="yes">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check ml-1 mt-2">
                                <input className="form-check-input shadow-none" type="radio" name="moveStore" id="likeToStart" />
                                <label className="form-check-label" htmlFor="likeToStart">
                                    No, I'd like to start fresh
                                </label>
                            </div>
                            <div className="form-check ml-1 mt-2">
                                <input className="form-check-input shadow-none" type="radio" name="moveStore" id="not" />
                                <label className="form-check-label" htmlFor="not">
                                    I'm not sure yet
                                </label>
                            </div>
                        </div>
                        <div className='border-top pt-3'>
                            <h6>Where will your business be located?</h6>
                            <p className='text-sm text-secondary'>We'll use your location to set up your default shipping rates, recommended apps, and more.</p>
                            <select className="form-select shadow-none">
                                <option>Select country</option>
                                <option>India</option>
                                <option>Nepal</option>
                                <option>Nepal</option>
                                <option>Nepal</option>
                                <option>Nepal</option>
                            </select>
                        </div>
                
                        <div className='text-right py-4 border-top'>
                            <button className='btn btnColorCustome btnColorOnHover' onClick={HandleSubmit}>Create</button>
                        </div>
                        <div className='text-center'>
                            Learn more about <a href='#'>starting a new store<i className="fa fa-external-link"></i></a>
                        </div>
                    </div>
                </div>
            </div> 

        </React.Fragment>

    )
}

export default Storelogin;