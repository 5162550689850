import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import ShippingDeliveryModal from '../SettingCompModal/ShippingDeliveryModal';


const GeneralProfile = () => {
    let navigate = useNavigate();
  return (
    <React.Fragment>
        <ShippingDeliveryModal/>
        <div className='pl-md-3 mt-3 mt-md-0'>

                    {/* for going back btn */} 
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>General profile</span>
            </div> 
            <div className='border-top my-4'></div>

                    {/* Products */}
            <div className='card'>
                <div className='px-3 pt-3'>
                    <h6>Products</h6>
                    <p className='lh-1 text-secondary text-sm'>0 products</p>
                    <p>New products are added to this profile.</p>
                </div>
                <div className='bg-light rounded-bottom border-top p-3'>
                     To charge different rates for only certain products, create a new profile in <span><Link to='/setting/shipping'>shipping settings</Link></span>.
                </div>
            </div>
   
            <div className='card'>
                       {/* shipping from */}
                <div className='p-3'>
                    <h6>Shipping from</h6>
                    <div className="py-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div>
                                <i className="fa fa-map-marker fs-4 border rounded bg-white px-3 py-2"></i>
                            </div>
                            <div className="px-2"> 
                                <span className="text-dark">Shop location</span><br />
                                <span className="text-secondary">india</span>
                            </div>
                        </div>
                        <div>
                           <a href="#" data-bs-toggle="modal" data-bs-target="#shopLocationModal">Manage</a>
                        </div>
                    </div>
                </div>

                <div className='border-top p-3'>
                    <div className='d-flex justify-content-between'>
                        <h6>Shipping to</h6>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#shippingZoneModal">Create shipping zone</a>
                    </div>
                    
                        {/* shipping to -> domestic */}
                    <div className="py-2 d-flex justify-content-between align-items-center mt-3">
                        <div className="d-flex align-items-center">
                            <div>
                                <img src="/images/tricolor.svg" alt="india-logo" style={{width:"2rem"}} />
                            </div>
                            <div className="px-2"> 
                                <span className="text-dark">Domestic</span><br />
                                <span className="text-secondary">india</span>
                            </div>
                        </div>

                          {/* edit zone dropdown */}
                        <ul className='mb-0 pr-3'>
                            <li className="nav-item dropdown">
                                <a className="nav-link text-secondary" data-toggle="dropdown" href="#">
                                    <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <a href="#" className="dropdown-item text-secondary">
                                    Edit zone
                                </a>
                                <a href="#" className="dropdown-item text-danger">Detele
                                </a>
                                </div>
                            </li>
                        </ul>
                    </div>

                        {/* shipping to -> domestic table */}
                    <div className='mt-2 table-responsive'>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col" className='p-2'>
                                        <button className='rounded px-2 btn-outline-light border text-dark d-flex align-items-center'>
                                            <span className='text-nowrap'>Rate name</span>
                                            <span className='d-flex flex-column pl-2'>
                                               <i className="fa fa-caret-up"></i>
                                               <i className="fa fa-caret-down"></i>
                                            </span>
                                        </button>
                                    </th>
                                    <th scope="col" className='fw-normal'>Condition</th>
                                    <th scope="col" className='fw-normal'>Price</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" className='fw-normal'>Standard</th>
                                    <td>__</td>
                                    <td>Free</td>
                                    <td>
                                         {/* edit zone dropdown */}
                                        <ul className='mb-0 text-right'>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link text-secondary" data-toggle="dropdown" href="#">
                                                    <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-sm">
                                                    <a href="#" className="dropdown-item text-secondary">
                                                        Edit zone
                                                    </a>
                                                    <a href="#" className="dropdown-item text-danger">Detele
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='pb-2 pb-md-0'>
                            <button className='btn-sm btn-outline-light border text-dark text-bold' data-bs-toggle="modal" data-bs-target="#AddRateModal">Add rate</button>
                        </div>  
                    </div>

                        {/* shipping to -> rest of world */}
                    <hr />
                    <div className="mt-4 pt-1 pb-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div>
                                <i className="fa fa-globe fs-4 border rounded bg-white px-3 py-2"></i>
                            </div>
                            <div className="px-2"> 
                                <span className="text-dark">Rest of world</span><br />
                                <span className="text-secondary">Rest of world</span>
                            </div>
                        </div>

                            {/* edit zone dropdown */}
                        <ul className='mb-0 pr-3'>
                            <li className="nav-item dropdown">
                                <a className="nav-link text-secondary" data-toggle="dropdown" href="#">
                                    <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <a href="#" className="dropdown-item text-secondary">
                                    Edit zone
                                </a>
                                <a href="#" className="dropdown-item text-danger">Detele
                                </a>
                                </div>
                            </li>
                        </ul>
                    </div>

                        {/* shipping to -> Rest of world table */}
                    <div className='mt-2 table-responsive'>
                        <table className="table table-hover">
                            <thead> 
                                <tr>
                                    <th scope="col" className='p-2'>
                                        <button className='rounded px-2 btn-outline-light border text-dark d-flex align-items-center'>
                                            <span className='text-nowrap'>Rate name</span>
                                            <span className='d-flex flex-column pl-2'>
                                               <i className="fa fa-caret-up"></i>
                                               <i className="fa fa-caret-down"></i>
                                            </span>
                                        </button>
                                    </th>
                                    <th scope="col" className='fw-normal'>Condition</th>
                                    <th scope="col" className='fw-normal'>Price</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" className='fw-normal'>Standard</th>
                                    <td>__</td>
                                    <td>₹1,700.00 INR</td>
                                    <td>
                                         {/* edit zone dropdown */}
                                        <ul className='mb-0 text-right'>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link text-secondary" data-toggle="dropdown" href="#">
                                                    <i className="fa fa-ellipsis-h fs-4" aria-hidden="true"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-sm">
                                                    <a href="#" className="dropdown-item text-secondary">
                                                        Edit zone
                                                    </a>
                                                    <a href="#" className="dropdown-item text-danger">Detele
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='pb-2 pb-md-0'>
                            <button className='btn-sm btn-outline-light border text-dark text-bold' data-bs-toggle="modal" data-bs-target="#AddRateModal">Add rate</button>
                        </div>
                    </div>

                </div>
            </div>

            <div className='border-top my-3'></div>
            <div className='d-flex justify-content-between'>
                <button className='btn btn-outline-light border text-dark'>Discard</button>
                <button className=' btn btnColorCustome btnColorOnHover'>Save</button>
            </div>

        </div>

    </React.Fragment>
  )
}

export default GeneralProfile