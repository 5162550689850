
import React from "react";
import { useState } from "react";
import { useNavigate , Link } from 'react-router-dom'

const ThirdPartyProvider = ()=>{
    let navigate = useNavigate();
    
    const[cardli,setcardli]=useState([
        {
            cimg: "/images/visa.svg",
        },
        {
            cimg: "/images/mastercard.svg",
        },
        {
            cimg: "/images/amex.svg",
        },
        {
            cimg: "/images/discover.svg",
        },
        {
            cimg: "/images/visa.svg",
        }
    ])

    return (
        <React.Fragment>
        <div className='pl-md-3'>

                {/* for going back btn */}         
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt="back_logo" src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Third-party payment providers</span>
            </div> 

            <div className='border-top my-4'></div>
    
              {/* Third-party payment providers option list */}
            <div className="thirdPartyPay list-group">

                  {/* filter search-input*/}
                <div className="list-group-item">
                    <div className="row">
                        <div className="col-md-8 my-1">
                            <div className="input-group">
                                <span className="input-group-append">
                                    <div className="input-group-text bg-white rounded-start "><i className="fa fa-search text-secondary" aria-hidden="true" />
                                    </div>
                                </span>
                                <input className="form-control border-left-0 rounded-end border" type="search" placeholder='Filter third-party payment provider' />
                            </div>
                        </div>

                        <div className="btn-group col-md-4 my-1 d-grid">
                            <button type="button" className="btn border btn-outline-light text-dark dropdown-toggle" data-toggle="dropdown">Payment methods <span className="caret"></span></button>
                            <ul className="dropdown-menu filterPayMethodscrollable-menu" role="menu">

                                <li>
                                    <div className="dropdown-item">
                                        <div className="form-check">
                                            <input className="form-check-input shadow-none" type="checkbox" id="amex" />
                                            <label className="form-check-label" htmlFor="amex">
                                                American Express
                                            </label>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="dropdown-item">
                                        <div className="form-check">
                                            <input className="form-check-input shadow-none" type="checkbox" id="applepay" />
                                            <label className="form-check-label" htmlFor="applepay">
                                                Apple Pay
                                            </label>
                                        </div>
                                    </div>
                                </li>
                       
                            </ul>
                        </div>
                    </div>
                </div>

                  {/* payment card list-link */}
                <Link to='/setting/payment/Third_party_provider/payment_method' className="list-group-item list-group-item-action">
                    <div className="row">
                        <div className="col-md-6 pt-1">
                           <span>(for testing) Bogus Gateway</span>
                        </div>
                        <div className="col-md-6 pt-2">
                            <ul className="pl-0 d-flex justify-content-start gap-2">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Bogus(for testing)">
                                    <img src="/images/bogus.svg" alt="bogus" className="border rounded payCardSize"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Link>


                <Link to="#" className="list-group-item list-group-item-action">
                    <div className="row">
                        <div className="col-md-6 pt-1">
                           <span>2Checkout</span>
                        </div>
                        <div className="col-md-6 pt-2">
                            <ul className="pl-0 d-flex justify-content-start gap-2">

                                {
                                    cardli.map((imgk,index)=>{
                                        if(index<=3){
                                            return (   
                                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Visa">
                                                <img src={imgk.cimg} alt="visa" className="border rounded payCardSize "/>
                                            </li>
                                       )

                                        }
                                        else{return(
                                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Apple pay, Diners club,">
                                          <a href="#" className="text-sm text-nowrap">and 3 more</a>
                                       </li>
                                       )

                                        }  
                                  
                                    }
                                      
                                    )
                                }

                            </ul>
                        </div>
                    </div>
                </Link>

                <Link to="#" className="list-group-item list-group-item-action">
                    <div className="row">
                        <div className="col-md-6 pt-1">
                           <span>Airpay</span>
                        </div>
                        <div className="col-md-6 pt-2">
                            <ul className="pl-0 d-flex justify-content-start gap-2">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Visa">
                                    <img src="/images/visa.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Mastercard">
                                    <img src="/images/mastercard.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Ma American Express">
                                    <img src="/images/amex.svg" alt="amex" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Discover">
                                    <img src="/images/discover.svg" alt="discover" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Apple pay, Diners club,">
                                    <a href="#" className="text-sm text-nowrap">and 3 more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Link>

                <Link to="#" className="list-group-item list-group-item-action">
                    <div className="row">
                        <div className="col-md-6 pt-1">
                           <span>Stripe</span>
                        </div>
                        <div className="col-md-6 pt-2">
                            <ul className="pl-0 d-flex justify-content-start gap-2">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Visa">
                                    <img src="/images/visa.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Mastercard">
                                    <img src="/images/mastercard.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Ma American Express">
                                    <img src="/images/amex.svg" alt="amex" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Discover">
                                    <img src="/images/discover.svg" alt="discover" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Apple pay, Diners club,">
                                    <a href="#" className="text-sm text-nowrap">and 3 more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Link>

                <Link to="#" className="list-group-item list-group-item-action">
                    <div className="row">
                        <div className="col-md-6 pt-1">
                           <span>Adyen</span><br />
                           <span className="text-secondary">Unavailable in your country</span>
                        </div>
                        <div className="col-md-6 pt-2">
                            <ul className="pl-0 d-flex justify-content-start gap-2">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Visa">
                                    <img src="/images/visa.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Mastercard">
                                    <img src="/images/mastercard.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Ma American Express">
                                    <img src="/images/amex.svg" alt="amex" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Discover">
                                    <img src="/images/discover.svg" alt="discover" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Apple pay, Diners club,">
                                    <a href="#" className="text-sm text-nowrap">and 3 more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Link>

                <Link to="#" className="list-group-item list-group-item-action">
                    <div className="row">
                        <div className="col-md-6 pt-1">
                           <span>Autorize.net</span><br />
                           <span className="text-secondary">Unavailable in your country</span>
                        </div>
                        <div className="col-md-6 pt-2">
                            <ul className="pl-0 d-flex justify-content-start gap-2">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Visa">
                                    <img src="/images/visa.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Mastercard">
                                    <img src="/images/mastercard.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Ma American Express">
                                    <img src="/images/amex.svg" alt="amex" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Discover">
                                    <img src="/images/discover.svg" alt="discover" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Apple pay, Diners club,">
                                    <a href="#" className="text-sm text-nowrap">and 3 more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Link>

                <Link to="#" className="list-group-item list-group-item-action">
                    <div className="row">
                        <div className="col-md-6 pt-1">
                           <span>Bambora</span><br />
                           <span className="text-secondary">Unavailable in your country</span>
                        </div>
                        <div className="col-md-6 pt-2">
                            <ul className="pl-0 d-flex justify-content-start gap-2">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Visa">
                                    <img src="/images/visa.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Mastercard">
                                    <img src="/images/mastercard.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Ma American Express">
                                    <img src="/images/amex.svg" alt="amex" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Discover">
                                    <img src="/images/discover.svg" alt="discover" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Apple pay, Diners club,">
                                    <a href="#" className="text-sm text-nowrap">and 3 more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Link>

                <Link to="#" className="list-group-item list-group-item-action">
                    <div className="row">
                        <div className="col-md-6 pt-1">
                           <span>Cayan</span><br />
                           <span className="text-secondary">Unavailable in your country</span>
                        </div>
                        <div className="col-md-6 pt-2">
                            <ul className="pl-0 d-flex justify-content-start gap-2">
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Visa">
                                    <img src="/images/visa.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Mastercard">
                                    <img src="/images/mastercard.svg" alt="visa" className="border rounded payCardSize "/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Ma American Express">
                                    <img src="/images/amex.svg" alt="amex" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Discover">
                                    <img src="/images/discover.svg" alt="discover" className="border rounded payCardSize"/>
                                </li>
                                <li data-bs-toggle="tooltip" data-bs-placement="top" title="Apple pay, Diners club,">
                                    <a href="#" className="text-sm text-nowrap">and 3 more</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Link>

            </div>

            <div className="text-center pt-4">
                <p>Learn more about <a href="#">third-party payment providers</a>.</p>
            </div>

        </div>

    </React.Fragment>

    );
}
export default ThirdPartyProvider;
