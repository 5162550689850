
import React from 'react'
import { useNavigate } from 'react-router-dom'
import EmailSettingModal from '../SettingCompModal/EmailSettingModal'

function EmailSetting() {
    const navigate = useNavigate()
  return (
    
    <React.Fragment>
        <EmailSettingModal/>
        <div className='pl-md-3 mt-3 mt-md-0'>

            {/* for going back btn */}
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back-logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Customize email templates</span>
            </div>
            <div className='border-top my-4'></div>
            <div className='row'>
                <div className='col-md-8'>
                    <div className='card p-3'>

                        {/* Order confirmation */}
                        <div className='d-flex justify-content-between'>
                            <h6>Order confirmation</h6>
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-outline-light border text-dark">
                                    <i className="fa fa-angle-left fs-5"></i>
                                </button>
                                <button type="button" className="btn btn-outline-light border text-dark">
                                    <i className="fa fa-angle-right fs-5"></i>
                                </button>
                            </div>
                        </div>

                        <div className='border rounded p-3 mt-3'>
                            <div className='fs-3'>store20231</div>
                            <div className='text-secondary mt-2'>ORDER #9999</div>
                            <div className='fs-4 mt-2'>Thank you for your purchase!</div>
                            <p className='mt-2 text-secondary'>We're getting your order ready to be shipped. We will notify you when it has been sent.</p>
                            <div className='text-center p-3 bg-success rounded'>
                                View your order
                            </div>
                            <p className='text-center mt-3'>or <span className='text-info'>Visit our store</span></p>
                            <div className='fs-4 mt-2'>Order summary</div>
                            <div className='d-flex justify-content-between mt-3'>
                                <h6 className='pr-1'>Aviator sunglasses x 1</h6>
                                <h6>Rs. 89.99</h6>
                            </div>

                            <div className='d-flex justify-content-between mt-2 border-top py-2'>
                                <h6 className='pr-1'>Mid-century lounger x 1</h6>
                                <div>
                                    <span className='text-secondary text-decoration-line-through text-sm'>Rs. 159.99</span><br />
                                    <span className='text-bold text-sm'>Rs.154.99</span>
                                </div>
                            </div>
                            <div className='text-secondary'><i className="fa fa-percent mr-1"/>DISCOUNT (-Rs. 5.00)</div>
                            <div className='mt-4 border-top py-3'>
                                <div className='d-flex justify-content-between'>
                                    <p className='text-secondary'>Subtotal</p>
                                    <p className='text-bold'>Rs. 244.98</p>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p className='text-secondary'>Shipping</p>
                                    <p className='text-bold'>Rs. 10.00</p>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p className='text-secondary'>Taxes</p>
                                    <p className='text-bold'>Rs. 0.00</p>
                                </div>
                            </div>
                            <div className='border-top py-3'>
                                <div className='d-flex justify-content-between'>
                                    <p className='text-secondary lh-1'>Total</p>
                                    <p className='text-bold fs-4 lh-1'>Rs. 67.00</p>
                                </div>
                                <div className='text-right text-secondary'>You saved Rs. 5.00</div>
                            </div>
                            <div className='border-top pt-3'>
                                <div className='fs-5'>Customer information</div>
                                <h6 className='mt-3'>Shipping address</h6>
                                <div className='text-secondary'>
                                    <span>Steve Shipper</span> <br />
                                    <span>Shipping Company</span> <br />
                                    <span>123 Shipping Street</span> <br />
                                    <span>Shippington KY 40003</span> <br />
                                    <span>United States</span>
                                </div>

                                <h6 className='mt-3'>Billing address</h6>
                                <div className='text-secondary'>
                                    <span>Steve Shipper</span> <br />
                                    <span>Shipping Company</span> <br />
                                    <span>123 Shipping Street</span> <br />
                                    <span>Shippington KY 40003</span> <br />
                                    <span>United States</span>
                                </div>

                                <h6 className='mt-3'>Shipping method</h6>
                                <div className='text-secondary'>
                                    <span>Generic Shipping</span>
                                </div>
                            </div> 
                            <div className='border-top mt-3 pt-3'>
                                <div>If you have any questions, reply to this email or contact us at</div>
                                <div className='text-info mt-1 text-sm'>upendra.cityinnovates@gmail.com</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='card p-3'>
                        <h6>Logo</h6>
                        <button className='btn btn-outline-light text-dark border mt-3' data-bs-toggle="modal" data-bs-target="#uploadImageModal">Add image</button>
                        <div>
                            <div className='mt-3'>
                                <div className='d-flex justify-content-between mb-1'>
                                    <div>Logo size</div>
                                    <div className='text-secondary'>0</div>
                                </div>
                                <input type="range" className='w-100' min={1} max={100} defaultValue={20} />
                            </div>
                        </div>
                    </div>
                    <div className='card p-3'>
                        <h6>Colors</h6>
                        <div className='mt-2'>
                            <input type="color" className='border-0'/>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </React.Fragment>
  )
}

export default EmailSetting