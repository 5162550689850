
import React from 'react'
import { Link } from 'react-router-dom'

const MarketModal = () =>{
    return(
        <React.Fragment>
             {/* -- add recommended market -- */}
            <div className="modal fade" id="exampleModal" tabIndex={-1}    aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add a recommended market</h5>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <p className='text-secondary'>Select a market to start managing localized settings like pricing, currencies, or languages and domains.</p>
                            <div className='d-flex justify-content-between align-items-start border-top py-3'>
                                <div className='d-flex align-items-start'>
                                    <img src="/images/tricolor.svg" alt="country-logo" style={{width:"3rem"}} />
                                    <div className='px-2'>
                                        <span className='fw-bold'>United States</span><br />
                                        <span className='text-sm text-secondary'>Makes up 52% of international sales volume to stores in India</span>
                                    </div>
                                </div>
                                <div>
                                    <button className='btn btn-sm btnColorCustome btnColorOnHover'>Add</button>
                                </div>
                            </div>

                            <div className='d-flex justify-content-between align-items-start border-top py-3'>
                                <div className='d-flex align-items-start'>
                                    <img src="/images/tricolor.svg" alt="country-logo" style={{width:"3rem"}} />
                                    <div className='px-2'>
                                        <span className='fw-bold'>United States</span><br />
                                        <span className='text-sm text-secondary'>Makes up 52% of international sales volume to stores in India</span>
                                    </div>
                                </div>
                                <div>
                                    <button className='btn btn-sm btnColorCustome btnColorOnHover'>Add</button>
                                </div>
                            </div>

                            <div className='d-flex justify-content-between align-items-start border-top py-3'>
                                <div className='d-flex align-items-start'>
                                    <img src="/images/tricolor.svg" alt="country-logo" style={{width:"3rem"}} />
                                    <div className='px-2'>
                                        <span className='fw-bold'>United States</span><br />
                                        <span className='text-sm text-secondary'>Makes up 52% of international sales volume to stores in India</span>
                                    </div>
                                </div>
                                <div>
                                    <button className='btn btn-sm btnColorCustome btnColorOnHover'>Add</button>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer justify-content-start">
                            <p className='text-secondary'>Or select countries/regions to <Link to="/setting/markets/add_new_market">create your own market</Link></p>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default MarketModal