import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';

export default function PlanInfo() {
    useEffect(() => {
        const scriptFile = document.createElement("script");
        scriptFile.src = "/Custom.js"; //your js file path 
        //scriptFile .async = true; 
        document.body.appendChild(scriptFile);
    });
    const [value, setValue] = useState("");




    function setphno(e) {
        setValue(e.target.value)
    }

    const navigate = useNavigate();



    return (
        <div>
            <div className='content-wrapper topSpace'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-md-11 col-12 mb-4">
                                <div className='mt-3'>
                                    <img className='img-fluid' src="images/previous.png" onClick={() => navigate(-1)} />
                                    <span className='fw-bold ml-3'>Confirm Basic Shopify plan</span><br />
                                </div></div>
                        </div>
                        {/* alert message */}
                        <div className='row justify-content-center'>
                            <div className='col-md-11 col-12'>
                                {/* ------------------------------------- */}
                                <div class="alert alert-primary d-flex mt-3" role="alert">
                                    <i class="fa fa-exclamation-circle mt-1" aria-hidden="true"></i>
                                    <div className='ml-3'>
                                        <span className='fw-bolder'>Enable international transactions</span>
                                        <br />
                                        You may need to contact your bank and ask them to enable international transactions to make sure your payment goes through.
                                        <br />
                                        <div className='mt-6'><a href='#' className='text-dark'>Learn More</a></div>
                                    </div>
                                </div>
                                {/* ------------------------------------- */}
                                <div class="alert alert-primary d-flex mt-3" role="alert">
                                    <i class="fa fa-exclamation-circle mt-1" aria-hidden="true"></i>
                                    <div className='ml-3'>
                                        <span >
                                            We won’t charge you until your free trial ends on October 24, 2022.</span>
                                    </div>
                                </div>
                                {/* ------------------------------------- */}
                            </div>
                        </div>
                        <div className='row mx-md-5'>
                            <div className='col-md-8 col-12 pl-md-0 mb-3'>
                                <div className="contetn-bill-cyc p-3 bg-white rounded shadow-sm h-100">
                                    <p className='fw-bolder'>Billing cycle</p>
                                    <div className='d-noneaas'>
                                        <p>Choose how often you’d like to be billed. You can cancel anytime.</p>
                                        <div class="plans">
                                            <label class="plan threeyear" for="threeyear">
                                                <input type="radio" name="plan" id="threeyear" />
                                                <div class="plan-content">

                                                    <div className="row">
                                                        <div className="col-9"><p>3 Years</p>
                                                            <p className='fw-normal'>₹1,124.25 x 36 months</p></div>
                                                        <div className="col-3 d-flex justify-content-center ">
                                                            <p className='fw-normal text-center mt-4 text-success'>Save ₹13,491.00</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </label>

                                            <label class="plan twoyear" for="twoyear">
                                                <input type="radio" id="twoyear" name="plan" />
                                                <div class="plan-content">
                                                    <div className="row">
                                                        <div className="col-9"><p>2 Years</p>
                                                            <p className='fw-normal'>₹1,124.25 x 36 months</p></div>
                                                        <div className="col-3 d-flex justify-content-center ">
                                                            <p className='fw-normal text-center mt-4 text-success'>Save ₹13,491.00</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>

                                            <label class="plan yearly" for="yearly">
                                                <input type="radio" id="yearly" name="plan" />
                                                <div class="plan-content">
                                                    <div className="row">
                                                        <div className="col-9"><p>Yearly <span className='bg-info  px-2 fw-normal circle rounded-pill'>Recommended</span></p>
                                                            <p className='fw-normal'>₹1,124.25 x 36 months</p></div>
                                                        <div className="col-3 d-flex justify-content-center ">
                                                            <p className='fw-normal text-center mt-4 text-success'>Save ₹13,491.00</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>

                                            <label class="plan yearly" for="Monthly">
                                                <input type="radio" id="Monthly" name="plan" />
                                                <div class="plan-content">
                                                    <div className="row">
                                                        <div className="col-9"><p>Monthly</p>
                                                            <p className='fw-normal'>₹1,499.00 every month</p></div>
                                                        <div className="col-3 d-flex justify-content-center ">
                                                            <p className='fw-normal text-center mt-4 text-success'></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group ">
                                            <button class="btn  fcw bcg  float-right mt-3 mb-3 choosebillingcyc"
                                                type="submit" disabled>
                                                Choose billing cycle
                                            </button>

                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='col-md-4 col-12 pr-md-0 mb-3 plansummery d-none ' >
                                <div className="content-plan-info p-3 bg-white  shadow-sm border-bottom">
                                    <p className='fs-5'>Basic Shopify plan</p>
                                    <p className='text-muted'>₹40,473.00 + tax every 3 years</p>
                                    <p className='mt-4' >You will be charged <span className='fw-bolder'>₹40,473.00</span> + tax on <span className='fw-bolder'>Oct 24, 2022, </span>when your trial ends.</p>
                                </div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item border-0 border-bottom ">
                                        <h2 class="accordion-header border-bottom" id="headingOne">
                                            <button class="accordion-button shadow-none forplin" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                PLAN DETAILS
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <ul>
                                                    <li>2 staff members</li>
                                                    <li>Good Shopify shipping discounts</li>
                                                    <li>Shopify POS for markets and events</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-plan-info p-3 bg-white  shadow-sm border-bottom">
                                    <p className=''>Subtotal <span className='float-end'>₹40,473.00
                                    </span></p>
                                </div>
                                <div className="content-plan-info p-3 bg-white  shadow-sm border-bottom">
                                    <p className='fw-bolder'>Billed now <span className='float-end fw-normal'>₹0.00
                                    </span></p>
                                    <button type="button" class="btn fcw bcg  w-100" disabled>Success</button>
                                    <p className='mt-5 text-muted'>You need to confirm your billing cycle, add a business address, and add a payment method before you can start this plan.</p>
                                </div>

                            </div>
                        </div>

                        <div className='row mx-md-5'>
                            <div className='col-md-8 col-12 pl-md-0 mb-3'>
                                <div className="contetn-bill-cyc p-3 bg-white rounded shadow-sm">
                                    <p className='fw-bolder'>Business address <span><button className='float-end btn btn-light border' data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Add</button></span></p>
                                    <div class="collapse" id="collapseExample">
                                        <div class="card card-body shadow-none">
                                            <p>Used on customer order confirmations and your Shopify bill.</p>
                                            <form className='card shadow-none'>
                                                <div className="row form-group">
                                                    <div className='col-md-6'>
                                                        <label htmlFor="fname" className='fw-normal'>First Name</label>
                                                        <input type="text" className="form-control" id="fname" placeholder="Enter first name" />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label htmlFor="lname" className='fw-normal'>Last Name</label>
                                                        <input type="text" className="form-control" id="lname" placeholder="Enter last name" />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="reg" className='fw-normal'>Country/region</label>
                                                    <select className="form-control" id="reg">
                                                        <option>India</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="address" className='fw-normal'>Address</label>
                                                    <input type="text" className="form-control" id="address" placeholder='Address' />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="email" className='fw-normal'>Apartment, suite, etc.</label>
                                                    <input type="emial" className="form-control" id="email" placeholder='Apartment, suite, etc.' />

                                                </div>

                                                <div className="row form-group">
                                                    <div className='col-md-4'>
                                                        <label htmlFor="city" className='fw-normal'>City</label>
                                                        <input type="text" className="form-control" id="city" placeholder='city' />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label htmlFor="state" className='fw-normal'>State</label>
                                                        <input type="text" className="form-control" id="state" placeholder='State' />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label htmlFor="pincode" className='fw-normal'>PIN code</label>
                                                        <input type="text" className="form-control" id="pincode" placeholder='PIN code' />
                                                    </div>
                                                </div>

                                                <div className="row form-group">
                                                    <div className='col-9'>
                                                        <label htmlFor="phone" className='fw-normal'>Phone</label>
                                                        <input type="text" className="form-control" id="phone" />
                                                    </div>
                                                    <div className='col-3'>
                                                        <label htmlFor="c-code" className='fw-normal'>code</label>
                                                        <PhoneInput
                                                            placeholder="Enter phone number"
                                                            value={value}
                                                            className="form-control border-0"
                                                            onChange={setValue} />
                                                    </div>
                                                </div>
                                                <span><button className='float-end btn bcg border m-3 fcw'>Save Address</button><button className='float-end btn btn-light border m-3' data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Cancel</button></span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mx-md-5'>
                            <div className='col-md-8 col-12 pl-md-0 mb-3'>
                                <div className="contetn-bill-cyc p-3 bg-white rounded shadow-sm">
                                    <p className='fw-bolder'>Payment method <span><button className='float-end btn btn-light border' data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2">Add</button></span></p>
                                    <div class="collapse" id="collapseExample2">
                                        <div class="card card-body shadow-none">
                                            <p className='mb-4'>Choose how you’d like to pay for Shopify.</p>
                                            <p className='mb-4'>Changes to your payment method will be effective immediately. All future charges will be charged to this payment method.</p>
                                            <p className='mb-4'>Shopify accepts Visa, Mastercard and Amex credit or debit cards as payment for your subscription. You may need to contact your bank and ask them to enable international transactions.</p>
                                            <div class="plans">
                                                <label class="plan creditcard " for="creditcard" data-bs-toggle="collapse" data-bs-target="#collapseExample3" aria-expanded="false" aria-controls="collapseExample3">
                                                    <input type="radio" name="plan" id="creditcard" />
                                                    <div class="plan-content py-3">

                                                        Credit card

                                                    </div>

                                                </label> <div class="collapse border" id="collapseExample3">
                                                    <div class="card card-body shadow-none">
                                                        <p className='fw-normal'>CREDIT CARD INFORMATION</p>
                                                        <form className='card shadow-none'>
                                                            <div className="form-group">
                                                                <label htmlFor="address" className='fw-normal'>Card number</label>
                                                                <input type="text" className="form-control" id="address" placeholder='' />
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="fname" className='fw-normal'>Expires

                                                                    </label>
                                                                    <input type="text" className="form-control" id="fname" placeholder="MM/YY" />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="lname" className='fw-normal'>CVV  <i class="fa fa-info-circle " aria-hidden="true"></i></label>
                                                                    <input type="text" className="form-control" id="lname" placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="address" className='fw-normal' for="adrescopy">
                                                                    <input type="checkbox" id="adrescopy" />
                                                                    &nbsp; Billing address is the same as business address
                                                                </label>
                                                            </div>


                                                            <div className="form-group">
                                                                <label htmlFor="reg" className='fw-normal'>Country/region</label>
                                                                <select className="form-control" id="reg">
                                                                    <option>India</option>
                                                                </select>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="address" className='fw-normal'>Address</label>
                                                                <input type="text" className="form-control" id="address" placeholder='Address' />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="email" className='fw-normal'>Apartment, suite, etc.</label>
                                                                <input type="emial" className="form-control" id="email" placeholder='Apartment, suite, etc.' />

                                                            </div>

                                                            <div className="row form-group">
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="city" className='fw-normal'>City</label>
                                                                    <input type="text" className="form-control" id="city" placeholder='city' />
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="state" className='fw-normal'>State</label>
                                                                    <input type="text" className="form-control" id="state" placeholder='State' />
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label htmlFor="pincode" className='fw-normal'>PIN code</label>
                                                                    <input type="text" className="form-control" id="pincode" placeholder='PIN code' />
                                                                </div>
                                                            </div>

                                                            <div className="row form-group">
                                                                <div className='col-9'>
                                                                    <label htmlFor="phone" className='fw-normal'>Phone</label>
                                                                    <input type="text" className="form-control" id="phone" />
                                                                </div>
                                                                <div className='col-3'>
                                                                    <label htmlFor="c-code" className='fw-normal'>code</label>
                                                                    <PhoneInput
                                                                        placeholder="Enter phone number"
                                                                        value={value}
                                                                        className="form-control border-0"
                                                                        onChange={setValue} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="address" className='fw-normal' for="consent" data-bs-toggle="collapse" data-bs-target="#alertcollapse" aria-expanded="false" aria-controls="alertcollapse">
                                                                    <input type="checkbox" id="consent" />
                                                                    &nbsp;
                                                                    I consent to having my card saved securely for future payments
                                                                </label>
                                                            </div>
                                                            <div class="collapse" id="alertcollapse">
                                                                <div class="card card-body shadow-none">
                                                                    <div class="alert alert-primary d-flex mt-3" role="alert">
                                                                        <i class="fa fa-exclamation-circle mt-1" aria-hidden="true"></i>
                                                                        <div className='ml-3'>
                                                                            <span >You will be redirected to Razorpay to finish authentication and may be requested for a one-time mandate for ₹15,000. This will allow Shopify to automatically charge future bills below the mandate amount.</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span><button className='float-end btn bcg border m-3 fcw'>Save Address</button><button className='float-end btn btn-light border m-3' data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Cancel</button></span>
                                                        </form>
                                                    </div>
                                                </div>

                                                <label class="plan upi mt-4" for="upi">
                                                    <input type="radio" id="upi" name="plan" />
                                                    <div class="plan-content py-3">
                                                        UPI
                                                    </div>
                                                </label>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}
