
import React from 'react'
import { useNavigate } from 'react-router-dom'

const AddMarket = ()=>{
    
    let navigate = useNavigate()

    return(
        <React.Fragment>
            <div className='pl-md-3 mt-3 mt-md-0'>
                {/* for going back btn */}
                <div className='d-flex'>
                    <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                    <span className='ml-2 fw-normal fs-5'>Add a market</span>
                </div>
                <div className='border-top my-4'></div>
                <div className='card p-3'>
                    <label htmlFor="" className='fw-normal'>Market name</label>
                    <input type="text" className='form-control border' placeholder='e.g. Bangladesh'/>
                    <p className='text-secondary text-sm mt-1'>Customers won't see this.</p>
                    <button className='btn py-3 btn-outline-light border text-primary'>
                    <i className="fa fa-plus-circle mr-2"></i>Add countries/regions</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddMarket