
import React from 'react'

const DomainModal = () => {
  return (
    <React.Fragment>
        
                {/* Change to new domain */}
        <div className="modal fade" id="ChangeDomainModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Change to a new myshopify.com domain</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <p className='text-sm'>
                        You can only change this domain name once. Your original myshopify.com domain will still be visible in your admin. There is no cost to make this change.
                        </p>
                        <div>
                            <input type="text" className='form-control'/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-light border text-dark" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button>
                    </div>
                </div>
            </div>
        </div>

    </React.Fragment>
  )
}

export default DomainModal