
import React from "react";
import { useNavigate, Link } from "react-router-dom";

const ShopLocation = () => {
    let navigate = useNavigate()
    return (
        <React.Fragment>
            <div className='pl-md-3 mt-3 mt-md-0'>
                {/* for going back btn */}
                <div className='d-flex'>
                    <img className='img-fluid d-none d-sm-block' alt="back_logo" src="/images/previous.png" onClick={() => { navigate(-1) }} />
                    <span className='ml-2 fw-normal fs-5'>Shop location <span className="spanBg text-dark text-sm rounded-pill px-2 ml-2">default</span></span>
                </div>
                <div className='border-top my-4'></div>

                {/* Details */}
                <div className="card p-3">
                    <h6>Details</h6>
                    <p className="text-sm text-secondary">Give this location a short name to make it easy to identify. You'll see this name in areas like orders and products.</p>
                    <p className="text-sm text-secondary"><Link to='/products/Inventory'>View inventory</Link> at this location.</p>
                </div>

                {/* Location name */}
                <div className="card p-3">
                    <label htmlFor="" className="fw-normal">Location name</label>
                    <input type="text" className="form-control border" />
                    <div className="form-check ml-1 mt-3">
                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Fulfill online orders from this location
                        </label>
                        <p className="text-sm text-secondary">Inventory at this location is available for sale online.</p>
                    </div>
                    <div className="alert alert-primary d-flex" role="alert">
                        <div className="mr-2">
                            <i className="fa fa-info-circle text-info fs-5"></i>
                        </div>
                        <div className="text-sm">
                            This is your default location. To change whether you fulfill online orders from this location, select another default location first.
                        </div>
                    </div>
                </div>

                {/* Address */}
                <div className="card p-3">
                    <h6>Address</h6>
                    <div className="alert alert-primary alert-dismissible fade show text-sm d-flex" role="alert">
                        <div className="mr-2">
                            <i className="fa fa-info-circle text-info fs-5"></i>
                        </div>
                        This address couldn't be verified. You may be unable to buy shipping labels or set up certain delivery methods. Review the format or try re-entering your address.
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="alert" aria-label="Close" />
                    </div>
                    <div>
                        <label htmlFor="" className="fw-normal">Country/region</label>
                        <select className="form-select shadow-none" aria-label="Default select example">
                            <option>India</option>
                            <option>India</option>
                            <option>India</option>
                        </select>
                    </div>
                    <div className="mt-3">
                        <label htmlFor="" className="fw-normal">Address</label>
                        <input type="text"  className="form-control" placeholder="Address"/>
                    </div>

                    <div className="mt-3">
                        <label htmlFor="" className="fw-normal">Apartment, suite, etc.</label>
                        <input type="text"  className="form-control" placeholder="Apartment, suite, etc."/>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 mt-3">
                            <label htmlFor="" className="fw-normal">City</label>
                            <input type="text"  className="form-control" placeholder="City"/>
                        </div>
                        <div className="col-sm-4 mt-3">
                            <label htmlFor="" className="fw-normal">State</label>
                            <select className="form-select shadow-none" aria-label="Default select example">
                                <option>Bihar</option>
                                <option>Bihar</option>
                                <option>Bihar</option>
                            </select>
                        </div>
                        <div className="col-sm-4 mt-3">
                            <label htmlFor="" className="fw-normal">PIN code</label>
                            <input type="text"  className="form-control" placeholder="PIN code"/>
                        </div>
                    </div>
                    <div className="mt-3">
                        <label htmlFor="" className="fw-normal">Phone</label>
                        <div className="input-group">
                           <input type="text" className="form-control rounded-end " placeholder=""/>
                           <button class="btn btn-outline-light border rounded ml-2 py-0" type="button">
                             <span> <img src="/images/tricolor.svg" alt="india_logo" style={{width:"2rem"}}/></span>
                             <span><i className="fa fa-caret-down ml-2 text-dark"></i></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="text-right">
                    <button className="btn btnColorCustome btnColorOnHover">Save</button>
                </div>
                
            </div>
        </React.Fragment>
    );
}

export default ShopLocation