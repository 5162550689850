

import React from 'react'
import { useNavigate } from 'react-router-dom'
import TaxesDutiesModal from '../SettingCompModal/TaxesDutiesModal'

const RestOfWorldTaxes = () => {
    let navigate = useNavigate()
  return (
    <React.Fragment>
        <TaxesDutiesModal/>
        <div className='pl-md-3 mt-3 mt-md-0'>

            {/* for going back btn */}
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex'>
                    <img className='img-fluid d-none d-sm-block' src="/images/previous.png" alt='back_log' onClick={() => { navigate(-1) }} />
                    <span className='ml-2 fw-normal fs-5'>Rest of world</span>
                </div>
                <div>
                    <button className='btn btn-sm btnColorCustome btnColorOnHover'>Save</button>
                </div>
            </div>

            <div className='border-top my-4'></div>
                 {/* Base taxes */}
            <div className='card p-3'>
                <h6>Base taxes</h6>
                <p className='text-sm text-secondary'>Use base taxes if you have a tax obligation in Rest of world. These tax rates will be used unless overrides are specified.</p>
                <div>
                    <button className='btn btnColorCustome btnColorOnHover' data-bs-toggle="modal" data-bs-target="#resetDefaultTaxModal">Reset to default tax rates</button>
                </div>
            </div>
             
                 {/* Country tax */}
            <div className='card p-3'>
                <label htmlFor="">Country tax</label>
                <div className="input-group">
                    <input type="text" className="form-control border border-right-0" />
                    <span className='input-group-text bg-white'>%</span>
                    <span className="input-group-text d-flex flex-column p-0">
                        <i className="fa fa-caret-up iconUpDownOnOnHover px-2 mb-1"></i>
                        <i className="fa fa-caret-down iconUpDownOnOnHover px-2"></i>
                    </span>
                </div>
            </div>

                 {/* Tax overrides */}
            <div className='card p-3'>
                <h6>Tax overrides</h6>
                <p className='text-sm text-secondary'>Customize region-based tax rates for specific product collections or shipping rates when shipping to designated areas.</p>
                <div>
                    <button className='btn btnColorCustome btnColorOnHover' data-bs-toggle="modal" data-bs-target="#addOverrideTaxModal">Add a tax override</button>
                </div>
            </div>
            
            <div className='card p-3'>
                You have no overrides.
            </div>
            <div className='text-center'>Learn more about <a href="#">taxes</a>.</div>

        </div>
    </React.Fragment>
  )
}

export default RestOfWorldTaxes