
import React from 'react'
import { useNavigate } from 'react-router-dom'

const AddPaymentMethods = () => {
    let navigate = useNavigate();

  return (
    <React.Fragment>
        <div className='px-md-5'>
            {/* for going back btn */}         
            <div className='d-flex'>
                <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
                <span className='ml-2 fw-normal fs-5'>Add payment methods</span>
            </div>   
            <div className='border-top my-4'></div>
            <div className='card'>
                <div className='navTabStyle pt-3'>
                    <ul className="nav nav-pills px-2">
                        <li className='px-3'>
                            <a data-toggle="pill" className='text-secondary' href="#menu1">Search by payment methods</a>
                        </li>
                        <li className='px-3'>
                            <a data-toggle="pill" className='text-secondary' href="#menu2">Search by provider</a>
                        </li>
                    </ul>
                    <div className="tab-content p-4">
                        <div id="menu1" className="tab-pane fade show active">
                            <div className="input-group">
                                <span className="input-group-append">
                                    <div className="input-group-text bg-white rounded-start"><i className="fa fa-search text-secondary" /></div>
                                </span>
                                <input className="form-control border-left-0 border" type="search" placeholder='Search by payments methods' />
                            </div>
                        </div>
                        <div id="menu2" className="tab-pane fade">
                            <div className="input-group">
                                <span className="input-group-append">
                                    <div className="input-group-text bg-white rounded-start"><i className="fa fa-search text-secondary" /></div>
                                </span>
                                <input className="form-control border-left-0 border" type="search" placeholder='Search by provider' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </React.Fragment>
  )
}

export default AddPaymentMethods