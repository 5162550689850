

import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const ConnectExistingDomain = () => {
    let navigate = useNavigate()
  return (
    <React.Fragment>
      <div className='pl-md-3 mt-3 mt-md-0'>

        {/* for going back btn */}
        <div className='d-flex'>
            <img className='img-fluid d-none d-sm-block' alt='back_logo' src="/images/previous.png" onClick={() => { navigate(-1) }} />
            <span className='ml-2 fw-normal fs-5'>Connect existing domain</span>
        </div>
        <div className='border-top my-4'></div>
          
            {/* Domain */}
        <div className='card p-3'>
          <h6>Domain</h6>
          <div className='mt-2'>
            <input type="text" className='form-control border' placeholder='Example: example.com'/>
          </div>
          <div className='mt-2 text-secondary'>Enter the domain you want to connect.</div>

          <div className='text-right mt-3'>
              <button className='btn btnColorCustome btnColorOnHover'>Next</button>
          </div> 
        </div>

         <div className='text-center text-secondary'>
                  {/* Domain transfer link */}
            <p>Or, if your domain meets the requirements, you can <Link to='/setting/domains/transfer_domain'>transfer it to ecomBuilder</Link>.
            </p>
                  {/* Learn more about domains */}
            <p className='mt-4'>Learn more about <a href="#">domains</a></p>
         </div>


      </div>
    </React.Fragment>
  )
}

export default ConnectExistingDomain