import React from 'react'

function EmailSettingModal() {
  return (
    <React.Fragment>
         
         {/* upload images modal */}
            <div className="modal fade" id="uploadImageModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Select image</h5>
                            <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='text-center'>
                                <img src="/images/uploadImageLogo.svg" alt="uploadImage" />
                                <h4>No images yet</h4>
                                <div className='text-secondary text-sm'>Upload images to make a selection. You'll also be able to reuse the images in other areas of EcomBuilder.</div>
                                <div className='mt-3'>
                                    <label htmlFor="files" className="btn btnColorCustome btnColorOnHover">Upload image</label>
                                    <input id="files" className='d-none' type="file" />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-light text-dark border" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btnColorCustome btnColorOnHover">Save</button>
                        </div>
                    </div>
                </div>
            </div>

    </React.Fragment>
  )
}

export default EmailSettingModal