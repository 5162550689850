
import React from 'react'
import { Link } from 'react-router-dom'

const SettingTaxes = () => {
  return (
     <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <h5>Taxes and duties</h5>
            <div className='border-top my-4'></div>

            {/* Manage sales tax collection */}
            <div className='card'>
                <div className='p-3'>
                    <h6>Manage sales tax collection <a href="#" className='text-sm'> <i className="fa fa-info-circle px-1"></i>Learn more</a></h6>
                    <p className='text-sm text-secondary'>If you haven't already, create a <a href="#">shipping zone</a> in the region(s) you're liable in. Then, find the region in this list and select it to manage its tax settings. If you're unsure about where you're liable, check with a tax professional.</p>
                </div>

                <div className="d-table">
                    <div className="d-table-row bg-light">
                        <div className="d-table-cell border-top px-3 py-2 text-secondary">Country/region</div>
                        <div className="d-table-cell border-top px-3 py-2 text-secondary">Collecting</div>
                    </div>
                    <Link to='/setting/taxes/IN' className="d-table-row text-dark tableRowHover">
                        <div className="d-table-cell border-top p-3"> <img src="/images/tricolor.svg" alt="india_logo" style={{width:"2rem", marginRight:"10px"}}/>India</div>
                        <div className="d-table-cell border-top p-3">Taxes</div>
                    </Link>
                    <Link to='/setting/taxes/rest_of_world' className="d-table-row text-dark tableRowHover">
                        <div className="d-table-cell border-top p-3 text-nowrap"><i className="fa fa-globe fs-5 mr-2 text-secondary"></i>Rest of world</div>
                        <div className="d-table-cell border-top p-3">__</div>
                    </Link>
                </div>
            </div>

            {/* Duties and import taxes */}
            <div className='card'>
                <h6 className='p-3'>Duties and import taxes</h6>
                <div className='d-flex p-3 border-top'> 
                    <div className=''>
                        <h6>Help international customers avoid unexpected fees</h6>
                        <p className='text-sm'>Upgrade to Advanced Ecom builder to collect duties and import taxes at checkout for countries/regions you ship to.</p>
                    <a href='/plans' className='btn btnColorCustome btnColorOnHover mt-2'>Compare plans</a>
                    </div>
                    <div className='d-sm-block d-none'>
                       <img src="/images/taxes-logo.svg" alt="taxes-logo" />
                    </div>
                </div>
                <div className='px-3 mb-3 text-secondary text-sm'>
                    The transaction fee is 1.5%, or 0.85% for Ecom builder Payments. <a href="#">About transaction fees</a>.
                </div>
            </div>
   
            {/* Decide how tax is charged */}
            <div className='card'>
                <div className='px-3 pt-3'>
                    <h6>Decide how tax is charged</h6>
                    <p className='text-secondary text-sm'>Manage how taxes are charged and shown in your store. For a summary of the taxes you've collected, <a href="#">view your report</a> .</p>
                </div>
                <div className='p-3 border-top'>
                    <div className="form-check ml-1">
                        <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Include tax in prices
                        </label>
                        <p className='text-sm text-secondary'>Product prices will include tax. Taxes on shipping rates will be included in the shipping price. <a href="#">Learn more about when to use this setting</a>.</p>
                    </div>

                    <div className='border-top'>
                        <div className="form-check ml-1 pt-3">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Include or exclude tax based on your customer's country
                            </label>
                            <p className='text-sm text-secondary'><a href="#">Go to Markets preferences</a> to turn this setting on.</p>
                        </div>
                    </div>
                    <div className='border-top'>
                        <div className="form-check ml-1 pt-3">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                            Charge tax on shipping rates
                            </label>
                            <p className='text-sm text-secondary'>Include shipping rates in the tax calculation. This is automatically calculated for Canada, European Union, and United States.</p>
                        </div>
                    </div>
                    <div className='border-top'>
                        <div className="form-check ml-1 pt-3">
                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                            Charge VAT on digital goods
                            </label>
                            <p className='text-sm text-secondary'>This will create a collection that you can add digital products to. VAT will be applied to products in the collection at checkout (for European customers). <a href="#">Learn more</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center py-2'>
                Learn more about <a href="#">taxes</a>
            </div>
        </div>
     </React.Fragment>
  )
}

export default SettingTaxes