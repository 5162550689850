import React from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
const DashbordHeader = () => {
  useEffect(() => {
    const scriptFile = document.createElement("script");
    scriptFile.src = "/Custom.js"; //your js file path 
    //scriptFile .async = true; 
    document.body.appendChild(scriptFile);
  });
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("storeIs");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  return (
    <div>

      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light fixed-top">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="db-header-left nav-item">
            <a className="nav-link" data-widget="pushmenu" href="/" role="button">
              <i className="fa fa-bars" />
            </a>
          </li>
        </ul>

        {/* SEARCH FORM */}
        <form className="form-inline ml-3 w-50">
          <div className="input-group input-group-sm w-100">
            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
            <div className="input-group-append">
              <button className="btn btn-navbar" type="submit">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>

        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">

          {/* Help */}
          <li className="db-header-help nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="/">
              <i className="fa fa-question-circle-o" />
            </a>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <a href="/" className="dropdown-item">Support</a>
              <a href="/" className="dropdown-item">FAQs</a>
              <a href="/" className="dropdown-item">Blogs</a>
              <a href="/" className="dropdown-item">Additional Services</a>
              <a href="/" className="dropdown-item">Financial Services</a>
            </div>
          </li>

          {/* user */}
          <li className="db-header-user nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="/">
              <i className="fa fa-user-circle-o" />
            </a>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <a href="/" className="dropdown-item">My Profile</a>
              <a href="/storelogin" target="_blank" className="dropdown-item">Stores</a>
              <a href="/" className="dropdown-item">Billing Account</a>
              <a href="/" className="dropdown-item">Invoices & receipts</a>
              <a href="/" className="dropdown-item">Privacy Policy</a>
              <a href="/" className="dropdown-item">Terms and Service</a>
              <a href="" className="dropdown-item" onClick={handleLogout}> Logout</a>
            </div>
          </li>
        </ul>
      </nav>
    </div>

  )
}

export default DashbordHeader