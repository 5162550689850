
import React from 'react'

const SettingBrand = () => {
  return (
    <React.Fragment>
        <div className='pl-md-3 mt-3 mt-md-0'>
            <h5>Brand</h5>
            <div className='border-top my-4'></div>
            <div className='row'>

                    {/* content in col-8 */}
              <div className='col-md-8'>
                    {/* Logo */}
                <div className='card'>
                  <h5 className='p-3'>Logos</h5>

                    {/* for default logo */}
                  <div className='border-top p-3'>
                    <h6>Default</h6>
                    <p className='text-secondary text-sm'>Used for most common logo applications</p>
                    <div className='cursorType d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div>
                          <i className="fa fa-picture-o fs-3 bg-secondary rounded p-2" aria-hidden="true"></i>
                        </div>
                        <div className='px-2'>
                          <span className='text-dark'>Add a default logo</span><br />
                          <span className='text-secondary text-sm'>HEIC, PNG, or JPG. Recommended width: 512 pixels minimum.</span>
                        </div>
                      </div>
                      <div>
                        <i className="fa fa-plus-circle fs-4 text-primary" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>

                    {/* for square logo */}
                  <div className='border-top p-3'>
                    <h6>Square</h6>
                    <p className='text-secondary text-sm'>Used by some social media channels. May be cropped into a circle</p>
                    <div className='cursorType d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div>
                          <i className="fa fa-picture-o fs-3 bg-secondary rounded p-2" aria-hidden="true"></i>
                        </div>
                        <div className='px-2'>
                          <span className='text-dark'>Add a square logo</span><br />
                          <span className='text-secondary text-sm'>HEIC, PNG, or JPG. Recommended: 512×512 pixels minimum.</span>
                        </div>
                      </div>
                      <div>
                        <i className="fa fa-plus-circle fs-4 text-primary" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>

                      {/* for creating hatchful-logo */}
                  <div className='border-top p-3'>
                    <img className='mr-1' src="/images/hatchful-logo.svg" alt="hatchful-logo" />
                    <a href="#">No logo? Create one with Hatchful</a>
                  </div>

                </div>

                    {/* Colors */}
                <div className='card'>
                  <h5 className='p-3'>Colors</h5>

                    {/* for Primary color*/}
                  <div className='border-top p-3'>
                    <h6>Primary</h6>
                    <p className='text-secondary text-sm'>The brand colors that appear on your store, social media, and more</p>
                    <div className='cursorType d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div>
                          <i className="fa fa-paint-brush fs-3 bg-secondary rounded p-2" aria-hidden="true"></i>
                        </div>
                        <div className='px-2'>
                          <span className='text-dark'>Add a primary color</span>
                        </div>
                      </div>
                      <div>
                        <i className="fa fa-plus-circle fs-4 text-primary" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>

                  {/* for Secondary color */}
                  <div className='border-top p-3'>
                    <h6>Secondary</h6>
                    <p className='text-secondary text-sm'>Supporting colors used for accents and additional detail</p>
                    <div className='cursorType d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div>
                          <i className="fa fa-paint-brush fs-3 bg-secondary rounded p-2" aria-hidden="true"></i>
                        </div>
                        <div className='px-2'>
                          <span className='text-dark'>Add a secondary color</span>
                        </div>
                      </div>
                      <div>
                        <i className="fa fa-plus-circle fs-4 text-primary" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>

                   {/* Cover Image */}
                <div className='card'>
                  <h5 className='p-3'>Cover Image</h5>
                  <div className='border-top p-3'>
                    <p className='text-secondary text-sm'>Key image that shows off your brand in profile pages and apps</p>
                    <div className='cursorType d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div>
                          <i className="fa fa-picture-o fs-3 bg-secondary rounded p-2" aria-hidden="true"></i>
                        </div>
                        <div className='px-2'>
                          <span className='text-dark'>Add a cover image</span><br />
                          <span className='text-secondary text-sm'>HEIC, PNG, or JPG. Recommended: 1920x1080 pixels minimum.</span>
                        </div>
                      </div>
                      <div>
                        <i className="fa fa-plus-circle fs-4 text-primary" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>

                   {/* Slogan */}
                <div className='card'>
                  <h5 className='p-3'>Slogan</h5>
                  <div className='border-top p-3'>
                    <p className='text-secondary text-sm'>Brand statement or tagline often used along with your logo</p>
                    <div className='cursorType d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div>
                          <i className="fa fa-file-text fs-3 bg-secondary rounded p-2" aria-hidden="true"></i>
                        </div>
                        <div className='px-2'>
                          <span className='text-dark'>Add a slogan</span>
                        </div>
                      </div>
                      <div>
                        <i className="fa fa-plus-circle fs-4 text-primary" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>

                   {/* Short description */}
                <div className='card'>
                  <h5 className='p-3'>Short description</h5>
                  <div className='border-top p-3'>
                    <p className='text-secondary text-sm'>Description of your business often used in bios and listings</p>
                    <div className='cursorType d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div>
                          <i className="fa fa-file-text fs-3 bg-secondary rounded p-2" aria-hidden="true"></i>
                        </div>
                        <div className='px-2'>
                          <span className='text-dark'>Add a short description</span>
                        </div>
                      </div>
                      <div>
                        <i className="fa fa-plus-circle fs-4 text-primary" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>

                   {/* Social links */}
                <div className='card'>
                  <h5 className='p-3'>Social links</h5>
                  <div className='border-top p-3'>
                    <p className='text-secondary text-sm'>Social links for your business, often used in the theme footer</p>
                    
                    <div className="btn-group">
                      <button className="btn btn-outline-light border text-dark dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                        Add social link
                      </button>
                      <ul className="dropdown-menu scrollable-menu"         aria-labelledby="dropdownMenuClickableInside">
                        <li><a className="dropdown-item" href="#">
                          <i className="fa fa-facebook-square mr-1"></i>Faceook</a>
                        </li>
                        <li><a className="dropdown-item" href="#">
                          <i className="fa fa-twitter-square mr-1"></i>Twitter</a>
                        </li>
                        <li><a className="dropdown-item" href="#">
                          <i className="fa fa-pinterest-square mr-1"></i>Pinterest</a>
                        </li>
                        <li><a className="dropdown-item" href="#">
                          <i className="fa fa-instagram mr-1"></i>Instagram</a>
                        </li>
                        <li><a className="dropdown-item" href="#">
                          <i className="fa fa-tumblr-square mr-1"></i>Tumber</a>
                        </li>
                        <li><a className="dropdown-item" href="#">
                          <i className="fa fa-snapchat-square mr-1"></i>Snapchat</a>
                        </li>
                        <li><a className="dropdown-item" href="#">
                          <i className="fa fa-youtube-square mr-1"></i>YouTube</a>
                        </li>
                        <li><a className="dropdown-item" href="#">
                          <i className="fa fa-vimeo-square mr-1"></i>Vimeo</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>

                  {/* --- content in col-4 --- */}
              <div className='col-md-4'>
                <div className='card p-3'>
                  <h5>What should you add ?</h5>
                  <p>ESSENTIAL</p>
                  <p className='text-secondary text-sm'>Common brand assets used across apps and channels</p>
                  <p><i className="fa fa-picture-o mr-2"></i>Default Logo</p>
                  <p><i className="fa fa-picture-o mr-2"></i>Square Logo</p>
                  <p><i className="fa fa-paint-brush mr-2"></i>Colors</p>
                  <div className='border-top mb-3'></div>
                  <p>ADDITIONAL</p>
                  <p className='text-secondary text-sm'>Used by some apps and social media channels</p>
                  <p><i className="fa fa-picture-o mr-2"></i>Cover Image</p>
                  <p><i className="fa fa-file-text-o mr-2"></i>Slogan</p>
                  <p><i className="fa fa-file-text-o mr-2"></i>Short Description</p>
                  <p><i className="fa fa-external-link mr-2"></i>Social Links</p>
                </div>
              </div>

            </div>

            <div className='text-center'>
              <p className='text-sm'>Learn more about <a href="#">managing your brand settings</a></p>
            </div>
        </div>

    </React.Fragment>
  )
}

export default SettingBrand