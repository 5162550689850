
import React from 'react';
import { Link } from 'react-router-dom';

const Customer = () => {

    return (
        <React.Fragment>
            <div className='content-wrapper topSpace'>
                <div className="container-fluid">
                        <div className="container px-4">
                        <div className="row fw-normal fs-5 pb-2">
                            Customer
                        </div>
                        <div className="row rounded " style={{ background: 'white' }}>
                            <div className="text-center p-md-5">
                                <img alt="customer-logo" className="img-fluid" src="/images/customer.svg" />
                                <p className="fs-5">Everything customers-related in a single place</p>
                                <p className="">When you've added customers, you'll be able to update their details, get a summary of their order history, create segments to send personalized communications that drive sales and more.</p>

                                <button type="button" className="btn btnColorCustome btnColorOnHover m-2" data-bs-toggle="modal" data-bs-target="#importCustomer">Import customers</button>

                                <Link to="/customer/add-customer">
                                    <button type="button" className="btn btnColorCustome btnColorOnHover m-2">Add customers</button>
                                </Link>
                            </div>
                        </div>
                        <p className='text-center py-3'>Learn more about <a href='#'>customer</a></p>

                        {/* import customer modal */}
                        <div className="modal fade" id="importCustomer" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-secondary" id="exampleModalLabel">Import Customer from .csv</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>

                                    <div className="modal-body text-sm">
                                        <p> <a href="#">Download a sample CSV</a> to see an example of the required format</p>
                                        <p>Make sure that customers set to ”Accepts Email Marketing” and ”Accepts SMS Marketing” have given you permission. <a href="#">Read more</a></p>
                                        <div className='container text-center py-4'>
                                            <label htmlFor="files" className="btn bg-light py-4" style={{ width: '12rem' }}>
                                                <img src="/images/csv-icon.svg" alt='csv file logo'></img>
                                            </label>
                                            <input id="files" style={{ display: 'none' }} type="file" />
                                        </div>

                                        <div className="form-check pb-2">
                                            <input className="form-check-input shadow-none" type="checkbox" defaultValue id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Overwrite existing customers that have the same email or phone
                                            </label>
                                        </div>

                                        <p><a href='#'>Click here</a> to understand more about importing products</p>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" disabled>Import customers</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        
        </React.Fragment>
    )
}

export default Customer