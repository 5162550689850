import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateTransfer = () => {
    let navigate = useNavigate();
    return (
        <React.Fragment>
            <div className='content-wrapper topSpace'>
                <div className="container-fluid">
                    <div className='container'>

                        {/* for going back btn */}
                        <div className="row justify-content-center">
                            <div className="col-md-11 pt-2">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex'>
                                        <img className='img-fluid' data-bs-toggle="modal" data-bs-target="#previousBtn" src="/images/previous.png" onClick={() => { navigate(-1) }} alt='back-logo' />
                                        <span className='ml-2 fw-normal fs-5'>Create inventory transfer</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-center pt-3'>
                            <div className='col-md-11'>
                                <div className='row'>
                                    {/* -- ORIGIN -- */}
                                    <div className='col-md-6'>
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-normal'>ORIGIN</p>
                                                <div className="origin-dropdown">
                                                    <button className="btn btn-lg dropdown-toggle p-0 m-0 text-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Select origin
                                                    </button>
                                                        <ul className="dropdown-menu dropdown-lg">
                                                            <li className='px-3'>
                                                                <input className="form-control" type="search" id="pricing" placeholder='Search'/>
                                                            </li>
                                                            <li className="pl-3 pt-2">SUPPLIERS</li>
                                                            <li className="dropdown-divider"></li>
                                                            <li className="text-secondary pl-3">No supplier found</li>
                                                            <li className="dropdown-divider"></li>
                                                            <li><a className="pl-3" href="#">Create new supplier</a></li>
                                                        </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* -- DESTINATION -- */}
                                    <div className='col-md-6'>
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-normal'>DESTINATION</p>
                                                <div className="origin-dropdown">
                                                    <button className="btn btn-lg dropdown-toggle p-0 m-0 text-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Shop location
                                                    </button>
                                                        <ul className="dropdown-menu dropdown-lg">
                                                            <li className='px-3'>
                                                                <input className="form-control" type="search" id="pricing" placeholder='Search'/>
                                                            </li>
                                                            <li className="dropdown-divider"></li>
                                                            <li className="text-secondary pl-3">No destination found</li>
                                                        </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* -- add products -- */}
                        <div className='row justify-content-center py-3'>
                            <div className='col-md-11'>
                                <div className='card p-3'>
                                    <div className="">
                                        <label htmlFor="pricing" className="form-label fw-normal">Add products</label>
                                        <div className="input-group">
                                            <span className="input-group-append">
                                                <div className="input-group-text bg-white rounded-start "><i className="fa fa-search text-secondary" aria-hidden="true" /></div>
                                            </span>
                                            <input className="form-control border-left-0 rounded-end border" type="search" id="pricing" placeholder='Search Products' />

                                            <button class="btn border rounded ml-3" type="button" id="button-addon2" data-bs-toggle="modal" data-bs-target="#browseModal">Browse</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* Browse Modal */}
                        <div>
                            <div className="modal fade" id="browseModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Add products</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <div className="modal-body">
                                            {/* --Search-- */}
                                            <div className="input-group">
                                                <span className="input-group-append">
                                                    <div className="input-group-text bg-white rounded-start ">
                                                        <i className="fa fa-search text-secondary" aria-hidden="true" />
                                                    </div>
                                                </span>
                                                <input className="form-control border-left-0 rounded-end border" type="search" id="pricing" placeholder='Search Products' />
                                            </div>

                                            {/* Products list */}
                                            <div className='createTransfer mt-3'>
                                                <ul className="list-group">

                                                    <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                                        <span>All Products</span>
                                                        <i className="fa fa-chevron-right text-secondary text-sm" aria-hidden="true"></i>
                                                    </li>

                                                    <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                                        <span>Popular Products</span>
                                                        <i className="fa fa-chevron-right text-secondary text-sm" aria-hidden="true"></i>
                                                    </li>

                                                    <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                                        <span>Collections</span>
                                                        <i className="fa fa-chevron-right text-secondary text-sm" aria-hidden="true"></i>
                                                    </li>

                                                    <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                                        <span>Products types</span>
                                                        <i className="fa fa-chevron-right text-secondary text-sm" aria-hidden="true"></i>
                                                    </li>

                                                    <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                                        <span>Tags</span>
                                                        <i className="fa fa-chevron-right text-secondary text-sm" aria-hidden="true"></i>
                                                    </li>

                                                    <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                                        <span>Vendors</span>
                                                        <i className="fa fa-chevron-right text-secondary text-sm" aria-hidden="true"></i>
                                                    </li>

                                                </ul>
                                            </div>

                                        </div>
                                        <div className="modal-footer justify-content-between">
                                            <button type="button" className="btn btn-light" disabled>0 variants selected</button>
                                            <button type="button" className="btn btn-light" disabled>Add to transfer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* .... Tag-Manage Modal .... */}
                        <div className="modal fade" id="manageScrollable" tabIndex={-1} role="dialog" aria-labelledby="ModalScrollableTitle" aria-hidden="false">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <p className="modal-title fs-5" id="ModalScrollableTitle">Manage tags</p>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">x</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='myMenageModal-Min-H'>
                                            <div className='row'>
                                                {/* search box */}
                                                <div className='col'>
                                                    <div className="input-group">
                                                        <span className="input-group-append">
                                                            <div className="input-group-text bg-white rounded-start"><i className="fa fa-search text-secondary" /></div>
                                                        </span>
                                                        <input className="form-control border-left-0 border" type="search" placeholder='Search to find or create tags' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='addBox'>
                                                <a href="#">
                                                    <span>
                                                        <i className="fa fa-plus-circle pr-2 pt-3"     aria-hidden="true">
                                                        </i>
                                                    </span>Add
                                                </a>
                                            </div>
                                            <p className='text-secondary text-sm text-center pt-3'>
                                            No tags yet</p>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" className="btn btnColorCustome btnColorOnHover" data-dismiss="modal">Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-md-11'>
                                <div className='row'>
                                    {/* -- Shipment details -- */}
                                    <div className='col-md-6'>
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-bold'>Shipment details</p>
                                                <div className="">
                                                    <label htmlFor="eArrival" className="form-label fw-normal">Estimated arrival</label>
                                                    <input className="form-control border" type="date" id="eArrival" placeholder='Search Products' />
                                                </div>
                                                <div className="pt-3">
                                                    <label htmlFor="Tnumber" className="form-label fw-normal">Tracking number</label>
                                                    <input className="form-control border" type="text" id="Tnumber" />
                                                </div>
                                                <div className="pt-3">
                                                    <label htmlFor="sCarrier" className="form-label fw-normal">Shipping carrier</label>
                                                    <input className="form-control border" type="text" id="sCarrier" />
                                                </div>
                                                <div className="pt-3">
                                                    <label htmlFor="tURL" className="form-label fw-normal">Tracking URL</label>
                                                    <input className="form-control border" type="text" id="tURL" placeholder='Enter a valid tracking page link, including https://' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* -- Additional details -- */}
                                    <div className='col-md-6'>
                                        <div className='card p-3'>
                                            <div className="">
                                                <p className='fw-bold'>Additional details</p>
                                                <div className="">
                                                    <label htmlFor="rNumber" className="form-label fw-normal">Reference number</label>
                                                    <input className="form-control border" type="text" id="rNumber" />
                                                </div>
                                                <div className='pt-3'>
                                                    <label htmlFor="" className='fw-normal d-flex justify-content-between'>
                                                        <span>Tags</span>
                                                        <a href='#' data-toggle="modal" data-target="#manageScrollable">Manage</a>
                                                    </label>
                                                    <input type="text" className='form-control' placeholder='Find or create tags' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default CreateTransfer