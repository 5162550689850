import React from 'react'

function Themes() {
    return (
        <div> <div className='content-wrapper topSpace'>
            <div className='container-fluid'>
                <div className='container'>
                    <div className="row">

                        <div className="col-md-9 mt-3">
                            <p className='fw-bolder fs-4'>Themes</p>

                        </div>
                        <div className="col-md-3 mt-3 float-end">
                            <p className='float-end fw-bolder' style={{ fontSize: "15px" }}> <i class="fa fa-eye" aria-hidden="true"></i> View Online Store</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className='card p-3'>
                            <p className='mb-0'>Online store conversion rate <span className='float-end'><a href='#'>View Report</a></span></p>
                            <p className='mt-0 fs-2 fw-bolder'>0%</p>


                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>
    )
}

export default Themes