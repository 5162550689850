
import React from 'react'

function LanguageModal() {
    return(
        <React.Fragment>
            {/* Add Language */}
            <div className="modal fade" id="addLangModal" tabIndex={-1}   aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add language</h5>
                            <div className="modal-body">
                                <select className="form-select shadow-none">
                                    <option>Select...</option>
                                    <option>English</option>
                                    <option>Hindi</option>
                                </select>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-light text-dark border" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btnColorCustome btnColorOnHover">Add</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default LanguageModal